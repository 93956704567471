/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import parse from 'html-react-parser';

import { replaceURLs, getSlashedDateLocalTime } from '@Utils/common';
import { userInfoState, showDisclaimerState } from '@Utils/atoms';
import { getSetting } from '@Utils/userSettings';
import Checkbox from '@Components/NeosomaUI/Form/CheckBox';
import { Flex, ConfirmModal } from '@Components/NeosomaUI';

export default function DisclaimerModal() {
  const userInfo = useRecoilValue(userInfoState);
  const {
    id,
    login_disclaimer = '',
    last_login_ip = '',
    last_successful_login = ''
  } = userInfo || {};

  const hideLoginDisclaimer = getSetting(id, 'hideLoginDisclaimer');
  const [checked, setChecked] = useState(hideLoginDisclaimer);

  const [showDisclaimer, setShowDisclaimer] =
    useRecoilState(showDisclaimerState);

  const handleChange = (event) => {
    setShowDisclaimer(event.target.checked ? 'set' : 'unset');
    setChecked(event.target.checked);
  };

  const onCancel = () => {
    setShowDisclaimer(showDisclaimerState.disclaimerOptions.dismiss);
  };
  const onConfirm = (e) => {
    setShowDisclaimer(showDisclaimerState.disclaimerOptions.dismiss);
  };

  useEffect(() => {
    setChecked(hideLoginDisclaimer);
  }, [hideLoginDisclaimer]);

  return (
    <ConfirmModal
      isShowing={showDisclaimer}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="NOTICE"
      message={
        <Flex direction="column" gap="10px">
          <div>{parse(replaceURLs(login_disclaimer))}</div>
          <div>{`User Last Logged in from ${last_login_ip} on ${getSlashedDateLocalTime(
            last_successful_login
          )}`}</div>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            id="loginDisclaimer"
            name="loginDisclaimer"
            label="Do not show disclaimer at login"
          />
        </Flex>
      }
      confirmText="Confirm"
      cancelText=""
      obscureBackground
      hideHeaderCloseButton
      style={{ width: '490px' }}
    />
  );
}
