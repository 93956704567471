import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.platinum};

  & h2 {
    font-size: 4rem;
    font-weight: 100;
    color: ${({ theme }) => theme.palette.shuttlegray};
  }
`;

function Page404() {
  return (
    <Container>
      <h2>404 Not found</h2>
    </Container>
  );
}

export default Page404;
