import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const Margin = styled('div', {
  shouldForwardProp(prop) {
    return !['top', 'bottom'].includes(prop);
  }
})`
  ${({ top }) =>
    top &&
    css`
      margin-top: ${top};
    `};

  ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom};
    `};
`;

Margin.defaultProps = {
  top: '0',
  bottom: '0'
};

Margin.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string
};

export default Margin;
