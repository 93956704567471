import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { currentPatientListState } from '@Utils/atoms';
import Feature from '@Utils/FeatureFlags';
import setNextScanDate from '@Utils/api/setNextScanDate';
import {
  DropDown,
  DropDownSubMenu,
  DropdownItem
} from '@Components/NeosomaUI/DropDown';

// *---------------------------*
// |  COMPONENT :: ActionMenu  |
// *---------------------------*
// eslint-disable-next-line react/prop-types
export function ActionMenu({ children, ...props }) {
  return (
    <DropDown hideOnSelect buttonText="Actions" {...props}>
      {children}
    </DropDown>
  );
}

export function ActionsMenuList({
  selectedPatientList = [],
  onComplete,
  onAddTreatment,
  hideAddTreatment,
  doPin,
  filterType
}) {
  const patientList = useRecoilValue(currentPatientListState);

  const handleSnooze = (e) => {
    const maxSimultainiusFetchCalls = 5;
    e.stopPropagation();
    const snoozeDate = (() => {
      const snoozeAmount = parseInt(e.target.innerText.split(' ')[0], 10) * 7;
      const today = new Date();
      const snooze = new Date(today);
      snooze.setDate(snooze.getDate() + snoozeAmount);
      return snooze;
    })();

    // recursivly snooze until all chunks are done.
    const doSnooze = (snoozeChunkList) => {
      const islastChunk = snoozeChunkList.length === 1;
      const thisChunk = snoozeChunkList.pop();
      const promises = thisChunk.map((id) => setNextScanDate(id, snoozeDate));

      if (islastChunk) {
        // end of chunck list.
        Promise.all(promises).then(() => onComplete && onComplete(true));
      } else {
        Promise.all(promises).then(() => {
          doSnooze(snoozeChunkList);
        });
      }
    };

    // snooze in batches of 6
    if (selectedPatientList.length > maxSimultainiusFetchCalls) {
      const chunkList = [];
      for (
        let i = 0;
        i < selectedPatientList.length;
        i += maxSimultainiusFetchCalls
      ) {
        const chunk = selectedPatientList.slice(
          i,
          i + maxSimultainiusFetchCalls
        );
        chunkList.push(chunk);
      }
      doSnooze(chunkList);
    } else {
      doSnooze([selectedPatientList]);
    }
  };

  const snoozeLabel = `Snooze Patient${
    selectedPatientList.length > 1 ? 's' : ''
  } ${selectedPatientList.length > 1 ? `(${selectedPatientList.length})` : ''}`;
  const isSinglePatient = selectedPatientList.length === 1;
  const singlePatient =
    patientList?.records ||
    [].find((r) => r.id === selectedPatientList[0]) ||
    {};
  const isPinned = isSinglePatient && !!singlePatient?.pinned;

  return (
    <Feature name="non-mvp-feature">
      <DropDownSubMenu itemName={snoozeLabel} menuLabel="Snooze for...">
        <DropdownItem onClick={handleSnooze}>
          <button type="button">4 Weeks</button>
        </DropdownItem>
        <DropdownItem onClick={handleSnooze}>
          <button type="button">6 Weeks</button>
        </DropdownItem>
        <DropdownItem onClick={handleSnooze}>
          <button type="button">8 Weeks</button>
        </DropdownItem>
        <DropdownItem>
          <button type="button">Custom Date</button>
        </DropdownItem>
      </DropDownSubMenu>

      {!hideAddTreatment && (
        <DropdownItem
          onClick={onAddTreatment}
          disabled={selectedPatientList.length > 1}
        >
          <button type="button">Add Treatment</button>
        </DropdownItem>
      )}
      <DropdownItem>
        <button type="button">{`Continue Treatment Plan ${
          selectedPatientList.length > 1
            ? `(${selectedPatientList.length})`
            : ''
        }`}</button>
      </DropdownItem>
      {isSinglePatient && isPinned && (
        <DropdownItem onClick={doPin}>
          <button type="button">Unpin Patient</button>
        </DropdownItem>
      )}
      {isSinglePatient && !isPinned && (
        <DropdownItem onClick={doPin}>
          <button type="button">Pin Patient</button>
        </DropdownItem>
      )}
    </Feature>
  );
}

ActionsMenuList.propTypes = {
  selectedPatientList: PropTypes.arrayOf(PropTypes.string),
  onComplete: PropTypes.func,
  onAddTreatment: PropTypes.func,
  hideAddTreatment: PropTypes.bool,
  doPin: PropTypes.func,
  filterType: PropTypes.string
};

ActionsMenuList.defaultProps = {
  selectedPatientList: [],
  onComplete: () => {},
  onAddTreatment: () => {},
  hideAddTreatment: false,
  doPin: () => {},
  filterType: ''
};

export default ActionMenu;
