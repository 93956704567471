import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import styled from '@emotion/styled';

import { ENV } from '@Utils/envTools';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { TbFileUpload } from 'react-icons/tb';
import { selectedPatientState } from '@Utils/atoms';
import { SelectMenu, Flex } from '@Components/NeosomaUI';
import { FormInput } from '@Components/NeosomaUI/Form/Input';
import { defaultTheme as UItheme } from '@Components/NeosomaUI/theme';
import postAttachment from '@Utils/api/postAttachment';
import getAuthCookies from '@Utils/api/getAuthCookies';
import uploadAttachment from '@Utils/api/uploadAttachment';
import { UNPINNED, bringSanityToMimeType } from '@Utils/generalConstants';
import { DOCUMENT_TYPES } from './helpers';

const acceptedFileTypes =
  'image/*,.pdf,.eps,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt,.csv';
const SizeTooLarge = 'exceeds size limit or 20mb';

const AddAttachmentWrapper = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-bottom: 10px;
`;

const Quadrants = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  gap: 20px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.mineshaft};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 8px;
  & #attach-type {
    font-weight: 400;
  }
  input[type='file'] {
    border: 1px dashed ${({ theme }) => theme.palette.lightsilver};
    border-radius: 4px;
    height: 150px;
    background-color: ${({ theme }) => theme.palette.lotion};
    color: transparent;
  }
  input::file-selector-button {
    width: 100%;
    height: 100%;
    color: transparent;
    opacity: 0;
  }
  .example {
    font-weight: 300;
  }
`;

const FileInputLabel = styled(Flex)`
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: 49px;
  font-weight: 400;
  align-items: center;
  pointer-events: none;
  gap: 2px;
  & strong {
    margin-left: 6px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  & svg {
    color: ${({ theme }) => theme.palette.azure};
  }
  .attached-filename {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.mineshaft};
  }
`;

const formInputStyles = {
  singleValue: (base, state) => ({
    ...base,
    color: `${UItheme.palette.mineshaft}`
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled
      ? UItheme.palette.lotion
      : base.backgroundColor
  })
};

export default function AddAttachment({
  documentType,
  setDocumentType,
  usePrimaryClicked,
  disableButtons,
  disablePrimary,
  dismissModal
}) {
  const [fileDescription, setFileDescription] = useState('');
  const [invalidFile, setInvalidFile] = useState(false);
  const [fileAttached, setFileAttached] = useState(undefined);
  const [selectedType, setSelectedType] = useState(
    documentType === 'all' || documentType === 'pinned'
      ? DOCUMENT_TYPES.Other
      : documentType
  );
  const [disabled, setDisabled] = useState(undefined);
  const fileInput = useRef(null);
  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const currentPatient = selectedPatient[selectedPatient.currentPatient];

  const handleSubmit = useCallback(
    async (e) => {
      if (!fileInput.current.files) return;
      getAuthCookies();
      const file = fileInput.current.files[0];
      // Disable inputs
      setDisabled(true);
      disableButtons('showSpinner');

      // Delete old file

      // Inifialize new file
      const data = await postAttachment(
        currentPatient.id,
        file.name,
        fileDescription,
        selectedType,
        file.type,
        UNPINNED
        // eslint-disable-next-line no-console
      ).catch((err) => console.error(err));

      // TODO: Handle error
      if (data === undefined) {
        disableButtons(undefined);
        setDisabled(undefined);
        return;
      }
      const newAttachment = {
        id: data.attachment_id,
        patient_id: currentPatient.id,
        hospital_id: ENV.CLIENT_ID,
        document_name: fileDescription,
        document_type: selectedType,
        mime_type: bringSanityToMimeType(file.type),
        pinned: UNPINNED,
        s3_file_name: file.name,
        create_user_id: data.create_user_id,
        update_user_id: data.update_user_id,
        date_created: new Date().toISOString(),
        date_updated: new Date().toISOString(),
        deleted: 0
      };

      const newAttachments = [
        ...(
          selectedPatient[selectedPatient.currentPatient].attachments || []
        ).filter((att) => att.id !== newAttachment.id),
        newAttachment
      ];

      // Upload file.
      if (data.uploadURL) {
        await uploadAttachment(data.uploadURL, file).catch((err) =>
          // eslint-disable-next-line no-console
          console.error(err)
        );
        setTimeout(() => {
          setSelectedPatient({
            ...selectedPatient,
            [selectedPatient.currentPatient]: {
              ...selectedPatient[selectedPatient.currentPatient],
              attachments: newAttachments
            }
          });
        }, 500);
      }

      // Reset state
      disableButtons(undefined);
      setDisabled(undefined);

      if (documentType === 'all' || documentType === 'pinned') {
        setDocumentType('all');
      } else {
        setDocumentType(newAttachment.document_type);
      }

      dismissModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileInput, fileDescription, selectedType]
  );
  const options = Object.keys(DOCUMENT_TYPES).reduce((arr, key) => {
    const nextArr = [...arr, { label: key, value: DOCUMENT_TYPES[key] }];
    return nextArr;
  }, []);

  const handleAttachmentChange = (e) => {
    setInvalidFile(false);
    // If no file attached, reset state
    if (!e.target.files.length) {
      if (fileAttached) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(fileAttached);
        e.target.files = dataTransfer.files;
      }
      return;
    }
    const thisFile = e.target.files[0];
    // CHECK if file is valid
    const whitelist = acceptedFileTypes.split(',');
    const fileExtension = thisFile.name.split('.').pop();
    const whiteListAllowsImages = whitelist.includes('image/*');

    let validFile = false;
    if (whiteListAllowsImages && thisFile.type.includes('image')) {
      validFile = true;
    }
    if (whitelist.includes(`.${fileExtension}`)) {
      validFile = true;
    }

    if (thisFile.size / 1024 / 1024 > 20) {
      validFile = false;
    }

    if (!validFile) {
      setFileDescription('');
      setFileAttached('');
      if (thisFile.size / 1024 / 1024 > 20) {
        setInvalidFile(`${thisFile.name} ${SizeTooLarge}`);
        return;
      }
      setInvalidFile(true);
      return;
    }

    let newDescription = thisFile.name;
    // remove file extension
    newDescription = newDescription.replace(/\.[^/.]+$/, '');
    newDescription = newDescription.replace(/_/g, ' ');
    newDescription = newDescription.replace(/-/g, ' ');
    newDescription = newDescription.replace(/\s+/g, ' ');
    newDescription = newDescription.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
    setFileDescription(newDescription);
    setFileAttached(thisFile);
  };

  usePrimaryClicked((...args) => {
    if (!disabled && fileAttached) {
      handleSubmit(args);
    }
  });

  useEffect(() => {
    disablePrimary(!fileAttached);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileAttached]);
  return (
    <AddAttachmentWrapper direction="column" gap="12px">
      <Quadrants>
        <Label htmlFor="myfile">
          Select a file:
          <input
            ref={fileInput}
            type="file"
            id="myfile"
            name="myfile"
            onChange={handleAttachmentChange}
            disabled={disabled}
            accept={acceptedFileTypes}
            onEvent={handleAttachmentChange}
          />
          <FileInputLabel direction="column">
            {fileAttached?.name ? (
              <TbFileUpload size="64px" />
            ) : (
              <FaCloudUploadAlt size="64px" />
            )}
            <div
              style={{ marginBottom: '16px' }}
              className={fileAttached?.name && 'attached-filename'}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                fileAttached?.name
                  ? fileAttached.name
                  : // eslint-disable-next-line no-nested-ternary
                  invalidFile
                  ? `${invalidFile}`.includes(SizeTooLarge)
                    ? invalidFile
                    : 'File type is invalid'
                  : 'No file selected'
              }
            </div>
            <Flex>
              Drag and Drop file here or <strong> Choose file</strong>
            </Flex>
          </FileInputLabel>
          <Flex style={{ justifyContent: 'space-between' }}>
            <div style={{ fontWeight: '300' }}>
              We accept most image and document types
            </div>
            <div style={{ fontWeight: '300' }}>Maximum size: 20MB</div>
          </Flex>
        </Label>
      </Quadrants>
      <Quadrants>
        <Label htmlFor="description">
          Description
          <FormInput
            id="description"
            type="text"
            autoComplete="off"
            value={fileDescription}
            onChange={(e) => setFileDescription(e.target.value)}
            disabled={disabled}
          />
        </Label>
        <Label htmlFor="attach-type">
          <span>
            Type{' '}
            <span className="example">{`( ${Object.values(DOCUMENT_TYPES).join(
              ', '
            )} )`}</span>
          </span>
          <SelectMenu
            id="attach-type"
            styles={formInputStyles}
            defaultValue={options.find((d) => d.value === selectedType)}
            options={options}
            onChange={(opt) => {
              setSelectedType(opt.value);
            }}
            placeholder="--"
            disabled={disabled}
          />
        </Label>
      </Quadrants>
    </AddAttachmentWrapper>
  );
}

const NOP = () => {
  /* NOP */
};

AddAttachment.propTypes = {
  documentType: PropTypes.string,
  setDocumentType: PropTypes.func,
  usePrimaryClicked: PropTypes.func,
  disableButtons: PropTypes.func,
  disablePrimary: PropTypes.func,
  dismissModal: PropTypes.func
};

AddAttachment.defaultProps = {
  documentType: DOCUMENT_TYPES.Other,
  setDocumentType: NOP,
  usePrimaryClicked: NOP,
  disablePrimary: NOP,
  disableButtons: NOP,
  dismissModal: NOP
};

AddAttachment.acceptedFileTypes = acceptedFileTypes;
