import Modal, {
  ModalFooter,
  ModalInner as ModalInnerStyle,
  ModalHeader
} from './Modal';
import useModal from './UseModal';
import useModalButtonClick from './useModalButtonClicked';

export default Modal;
export {
  ModalFooter,
  ModalHeader,
  ModalInnerStyle,
  useModal,
  useModalButtonClick
};
