import getBrowserFingerprint from 'get-browser-fingerprint';
import { Buffer } from 'buffer';
import doFetch from '@Utils/api/doFetch';

import { getOAuthToken, getUserEmail } from '@Utils/auth/userInfoUtils';
import { ENV, VERSION } from '@Utils/envTools';

const { POST } = doFetch.methods;

const authenticateUser = async ({ username = '', password = '' }) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const hash = async (string) => {
    const utf8 = new TextEncoder().encode(string);
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');

      return hashHex;
    });
  };

  const passHashed = await hash(
    `${password}${username.toLowerCase()}salt.neosomainc.com`
  );

  // https://www.npmjs.com/package/get-browser-fingerprint
  const fingerprint = getBrowserFingerprint({
    hardwareOnly: true,
    enableWebgl: true
  });

  // TODO: what to do when api is down?
  // const clientIdResponse = (await doFetch(`${uri}/client_id`)) || {
  //  clientId: CLIENT_ID,
  //  status: 'success'
  // };

  // if (clientIdResponse.status === 'success') {

  const domain = ENV.CONTENT_HOST;

  let body = {
    email: username,
    password: passHashed,
    app_version: VERSION,
    domain,
    machine_id: fingerprint.toString()
  };

  if (oauth) {
    body = {
      email: await getUserEmail(),
      app_version: VERSION,
      machine_id: fingerprint.toString()
    };
  }

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${Buffer.from(`${hospitalId}.:`).toString('base64')}`;

  return doFetch(`${uri}/authenticate?client_id=${hospitalId}`, {
    method: POST,
    body: JSON.stringify(body),
    headers: {
      Authorization: authorization,
      'Content-Type': 'application/json'
    },
    returnErrorResponse: true,
    doNotRedirectOnError: true
  })
    .then(async (res) => {
      if (res && typeof res === 'object' && Object.keys(res).length === 0) {
        throw new Error(
          JSON.stringify({
            statusCode: 400,
            resData: {
              code: 'NeosomaError',
              message: 'Invalid username or password'
            }
          })
        );
      }
      return res;
    })
    .catch(async (err) => {
      if (err instanceof Error && JSON.parse(err.message)) {
        const errorData = JSON.parse(err.message);
        if (errorData.resData?.code === 'NeosomaError') {
          throw new Error(
            JSON.stringify({
              statusCode: 400,
              resData: { message: errorData.resData.message }
            })
          );
        }
      }
      // rethrow
      throw new Error(
        JSON.stringify({
          statusCode: 400,
          resData: {
            message: 'An unexpected error occurred. Please try again.'
          }
        })
      );
    });
  // }
};
export default authenticateUser;
