import { atom, selector } from 'recoil';

const patientListCacheAtom = atom({
  key: 'patientListCacheState',
  default: { cache: {}, totalItems: {} }
});

export const patientListCache = selector({
  key: 'patientListCache',
  get: ({ get }) => {
    get('patientListCacheState');
  },
  set: ({ get, set, reset }, newValue) => {
    set('patientListCacheState', newValue);
  }
});

patientListCacheAtom.disclaimerOptions = {
  forceShow: 'forceShow',
  show: 'show',
  dismiss: 'dismiss',
  setDoNotShow: 'set',
  unsetDoNoShow: 'unset'
};

export default patientListCacheAtom;
