/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import getAuthCookies from '@Utils/api/getAuthCookies';
import postStudyExportRequest from './postStudyExportRequest';

const { HEAD } = doFetch.methods;

const pingExport = async (s3_file_url) => {
  // Let's make sure we have all the cookies we need
  await getAuthCookies();

  return fetch(`${ENV.CONTENT_URL}/${s3_file_url}?ts=${Date.now()}`, {
    method: HEAD,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      const headers = [...response.headers].reduce(
        (obj, [key, value]) => ({ ...obj, [key]: value }),
        {}
      );
      if (response.status === 404) {
        // Some cases the file is not found at first
        return 'Initializing: 0';
      }
      if (!response.ok) {
        return postStudyExportRequest.errorMessage;
      }
      return headers['x-amz-meta-neo-status'];
    })
    .catch(() => postStudyExportRequest.errorMessage);
};

const downloadExport = async (s3_file_url) => {
  // Let's make sure we have all the cookies we need
  await getAuthCookies();

  return fetch(`${ENV.CONTENT_URL}/${s3_file_url}?ts=${Date.now()}`, {
    headers: {
      'Content-Type': 'application/zip'
    }
  })
    .then((response) => response.blob())
    .then((blob) => blob)
    .catch(() => new Error('Unexpected Download Error'));
};

export { pingExport, downloadExport };
