import styled from '@emotion/styled';
import { number, func } from 'prop-types';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Flex, DropDown, DropdownItem } from '@Components/NeosomaUI';

export const DEFAULT_ITEMS_PER_PAGE = 20;
export const DEFAULT_NUMBER_OF_PAGES_IN_NAV = 6;
const PAGE_OPTIONS = [10, 20, 30, 40];

const PaginatorContainer = styled(Flex)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.lightsilver};
  gap: 35px;
  padding: 22px 14px;
  justify-content: space-between;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.dovegray};
  white-space: pre;
`;

const ResultsCounter = styled(Flex)`
  font-weight: 600;
  line-height: inherit;
  align-items: center;
`;

const PerPageContainer = styled(Flex)`
  color: ${({ theme }) => theme.palette.mineshaft};
  align-items: center;
`;

const PerPageDropDown = styled(DropDown)`
  color: ${({ theme }) => theme.palette.mineshaft};
  background: ${({ theme }) => theme.palette.antiflashwhite};

  border-color: ${({ theme }) => theme.palette.lightsilver};
  border-radius: 2px;
`;

const PageBtn = styled.button`
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  text-decoration-line: none;
  color: ${({ theme }) => theme.palette.dovegray};

  &:disabled {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.azure};
    text-decoration-line: underline;
  }

  &:hover:not([disabled]) {
    text-decoration-line: underline;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ArrowBtn = styled(PageBtn)`
  & svg {
    font-size: 15px;
  }
  &:disabled {
    color: ${({ theme }) => theme.palette.dustygray};
  }
`;

const getFirstPageNumber = (currentPage) => {
  if (currentPage < DEFAULT_NUMBER_OF_PAGES_IN_NAV - 2) {
    return 0;
  }
  return currentPage - DEFAULT_NUMBER_OF_PAGES_IN_NAV / 2;
};

const getLastPageNumber = (currentPage) => {
  if (currentPage >= DEFAULT_NUMBER_OF_PAGES_IN_NAV - 2) {
    return currentPage + DEFAULT_NUMBER_OF_PAGES_IN_NAV / 2;
  }
  return DEFAULT_NUMBER_OF_PAGES_IN_NAV;
};

function Paginator({
  count,
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
  totalItems
}) {
  const firstVisiblePageNumber = getFirstPageNumber(currentPage);
  const lastVisiblePageNumber = getLastPageNumber(currentPage);
  const firstPageNumber = 0;
  const lastPageNumber = Math.ceil(totalItems / rowsPerPage) - 1;

  const pages = [...Array(lastPageNumber + 1).keys()].slice(
    firstVisiblePageNumber,
    lastVisiblePageNumber + 1
  );

  const isFirstPageInList = pages[0] === 0 || pages[0] === undefined;
  const isLastPageInList =
    pages.length === 0 ? true : pages[pages.length - 1] === lastPageNumber;

  const hasMoreRight =
    pages.length === 0 ? false : currentPage < totalItems / rowsPerPage - 1;
  const hasMoreLeft = currentPage !== 0 && !(pages.length === 0);
  return (
    <PaginatorContainer>
      <Flex gap="40px" alignItems="center">
        <ResultsCounter>{count} Results</ResultsCounter>
        <PerPageContainer direction="row">
          Rows per page:{' '}
          <PerPageDropDown buttonText={rowsPerPage} variant="slim" hideOnSelect>
            {PAGE_OPTIONS.map((itemsPerPage) => (
              <DropdownItem
                key={`items-per-page-${itemsPerPage}`}
                onClick={() => setRowsPerPage(itemsPerPage)}
              >
                <button type="button">{itemsPerPage}</button>
              </DropdownItem>
            ))}
          </PerPageDropDown>
        </PerPageContainer>
      </Flex>
      <Flex alignItems="center">
        <ArrowBtn
          type="button"
          disabled={!hasMoreLeft}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <FiChevronLeft />
        </ArrowBtn>
        {!isFirstPageInList && (
          <>
            <PageBtn
              key={`page-number-${firstPageNumber + 1}`}
              disabled={false}
              type="button"
              onClick={() => setCurrentPage(firstPageNumber)}
            >
              {firstPageNumber + 1}
            </PageBtn>
            <span>...</span>
          </>
        )}
        {(pages.length ? pages : [0]).map((pageNum) => {
          const isActive = currentPage === pageNum;
          return (
            <PageBtn
              key={`page-number-${pageNum}`}
              disabled={isActive}
              type="button"
              onClick={() => setCurrentPage(pageNum)}
            >
              {pageNum + 1}
            </PageBtn>
          );
        })}
        {!isLastPageInList && (
          <>
            <span>...</span>
            <PageBtn
              key={`page-number-${lastPageNumber + 1}`}
              disabled={false}
              type="button"
              onClick={() => setCurrentPage(lastPageNumber)}
            >
              {lastPageNumber + 1}
            </PageBtn>
          </>
        )}
        <ArrowBtn
          type="button"
          disabled={!hasMoreRight}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <FiChevronRight />
        </ArrowBtn>
      </Flex>
    </PaginatorContainer>
  );
}

Paginator.defaultProps = {
  count: undefined,
  rowsPerPage: DEFAULT_ITEMS_PER_PAGE,
  setRowsPerPage: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  totalItems: undefined
};

Paginator.propTypes = {
  count: number,
  rowsPerPage: number,
  setRowsPerPage: func,
  currentPage: number,
  setCurrentPage: func,
  totalItems: number
};

export default Paginator;
