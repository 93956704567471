import { atom, selector } from 'recoil';

const unsavedAtom = atom({
  key: 'unsavedStateAtom',
  default: { unsaved: false, blocker: null }
});

const unsavedState = selector({
  key: 'unsavedState',
  get: ({ get }) => {
    const currentState = get(unsavedAtom);
    return currentState;
  },
  set: ({ get, set, reset }, newValue) => {
    const currentState = get(unsavedAtom);
    if (typeof newValue === 'object' && Object.keys(newValue).length === 0) {
      reset(unsavedAtom);
      return;
    }
    if (typeof newValue === 'boolean') {
      set(unsavedAtom, { ...currentState, unsaved: newValue });
    }
    if (typeof newValue === 'object' && newValue?.type === 'blocker') {
      set(unsavedAtom, { ...currentState, blocker: newValue.blocker });
    }
  }
});

export default unsavedState;
