import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FaUsers, FaPlus, FaMinus } from 'react-icons/fa';
import { BiPlusMedical } from 'react-icons/bi';
import { GiMedicalDrip } from 'react-icons/gi';
import { Transition } from 'react-transition-group';
import { ReactSVG } from 'react-svg';

import { Flex, Typography } from '@Components/NeosomaUI';

import { TUMOR_EVENTS } from '@Utils/generalConstants';
import { defaultTheme } from '@Components/NeosomaUI/theme';
import imagesIcon from '../../assets/timeline-images-icon.svg';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0
};

const transitionStyles = {
  entering: { opacity: 1, height: 'auto' },
  entered: { opacity: 1, height: 'auto' },
  exiting: { opacity: 0, height: 'auto' },
  exited: { opacity: 0, height: 0 }
};

const TimeLineItem = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.lightsilver};
  padding: 7px;
  padding-right: 13px;
  position: relative;
  width: 100%;
  cursor: ${({ hasNotes }) => (hasNotes ? 'pointer' : 'default')};
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    top: calc(50% - 9px);
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: ${({ theme }) => theme.palette.lightsilver};
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -18px;
    top: calc(50% - 8px);
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-right-color: ${({ theme }) => theme.palette.white};
  }
`;

const EventIconBase = styled(Flex)`
  width: 34px;
  height: 34px;
`;

const ReviewEventIcon = styled(EventIconBase)`
  background-color: ${({ theme }) => theme.palette.zircon};
  color: ${({ theme }) => theme.palette.azure};
`;

const TestingEventIcon = styled(EventIconBase)`
  background-color: ${({ theme }) => theme.palette.azalea};
  color: ${({ theme }) => theme.palette.thunderbird};
`;

const TreatmentEventIcon = styled(EventIconBase)`
  background-color: ${({ theme }) => theme.palette.gin};
  color: ${({ theme }) => theme.palette.sanfelix};
`;

function ImagesIcon() {
  return (
    <div style={{ height: '34px', width: '34px' }}>
      <ReactSVG src={imagesIcon} />
    </div>
  );
}

function ReviewIcon() {
  return (
    <ReviewEventIcon alignItems="center" justifyContent="center">
      <FaUsers size="23px" />
    </ReviewEventIcon>
  );
}

function TestingIcon() {
  return (
    <TestingEventIcon alignItems="center" justifyContent="center">
      <BiPlusMedical size="23px" />
    </TestingEventIcon>
  );
}

function TreatmentIcon() {
  return (
    <TreatmentEventIcon alignItems="center" justifyContent="center">
      <GiMedicalDrip size="23px" />
    </TreatmentEventIcon>
  );
}

function EventIcon({ type }) {
  switch (type) {
    case TUMOR_EVENTS.IMAGES:
      return <ImagesIcon />;
    case TUMOR_EVENTS.REVIEW:
      return <ReviewIcon />;
    case TUMOR_EVENTS.TESTING:
      return <TestingIcon />;
    case TUMOR_EVENTS.TREATMENT:
      return <TreatmentIcon />;
    case TUMOR_EVENTS.TUMOR_BOARD:
      return <ReviewIcon />;
    default:
      return 'unknown event';
  }
}

EventIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(TUMOR_EVENTS)).isRequired
};

export default function PatientTimelineEvent({ timelineEvent, onClick }) {
  const { event, eventType, date, notes } = timelineEvent;
  const [showNote, setShowNote] = useState(false);
  return (
    <TimeLineItem
      onClick={(e) => {
        setShowNote(!showNote);
        onClick(e, eventType, timelineEvent);
      }}
      hasNotes={notes}
    >
      <Flex alignItems="center" gap="8px" style={{ position: 'relative' }}>
        <EventIcon type={eventType} />
        <Flex direction="column">
          <Typography variant="p" bold>
            {event}
          </Typography>
          <Typography variant="small">{date}</Typography>
        </Flex>
        {notes &&
          (showNote ? (
            <FaMinus
              style={{ position: 'absolute', top: '2px', right: '-3px' }}
              color={defaultTheme.palette.dustygray}
              size="10px"
            />
          ) : (
            <FaPlus
              style={{ position: 'absolute', top: '2px', right: '-3px' }}
              color={defaultTheme.palette.dustygray}
              size="10px"
            />
          ))}
      </Flex>
      <Transition in={showNote} timeout={duration}>
        {(state) => (
          <Typography
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              whiteSpace: 'pre-wrap',
              overflow: 'auto',
              marginTop: '4px',
              paddingTop: '4px'
            }}
          >
            {notes}
          </Typography>
        )}
      </Transition>
    </TimeLineItem>
  );
}

PatientTimelineEvent.propTypes = {
  timelineEvent: PropTypes.shape({
    date: PropTypes.string,
    event: PropTypes.string,
    eventType: PropTypes.string,
    notes: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func
};

PatientTimelineEvent.defaultProps = {
  onClick: () => {
    /* NO OP */
  }
};
