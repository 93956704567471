import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Error } from '@Components/NeosomaUI';

const EditNoteContainer = styled.div`
  height: fit-content;
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.textarea`
  height: 38px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 2px 8px;
  flex: 1;
  resize: none;
`;

function PatientSummaryEditNote({ editedText, setEditedText, errorMessage }) {
  const inputRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
      const val = inputRef.current.value; // store the value of the element
      inputRef.current.value = ''; // clear the value of the element
      inputRef.current.value = val; // set that value back.
    }, 50);
  }, []);

  const handleChange = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      return;
    }
    setEditedText(e.target.value);
  };

  return (
    <EditNoteContainer className="patient-summary-notes-edit-notes">
      {errorMessage && <Error errorMessage={errorMessage} />}
      <Input
        ref={inputRef}
        value={editedText}
        onChange={handleChange}
        onKeyDown={handleChange}
      />
    </EditNoteContainer>
  );
}

PatientSummaryEditNote.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  note: PropTypes.shape({
    id: PropTypes.string,
    hospital_id: PropTypes.string,
    patient_id: PropTypes.string,
    notes: PropTypes.string,
    date_created: PropTypes.string,
    user_id: PropTypes.string,
    date_updated: PropTypes.string,
    update_user_id: PropTypes.string,
    deleted: PropTypes.number
  }),
  editedText: PropTypes.string,
  setEditedText: PropTypes.func,
  errorMessage: PropTypes.string
};

PatientSummaryEditNote.defaultProps = {
  note: {},
  editedText: '',
  setEditedText: () => {
    /* No Op */
  },
  errorMessage: ''
};

export default PatientSummaryEditNote;
