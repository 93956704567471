/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable camelcase */
import { userInfoState, authRefreshState } from '@Utils/atoms';
import {
  getGetAccessTokenSilently,
  getGetIdTokenClaims
} from '@Utils/auth/authAccessor';
import { promiseGetRecoil, promiseSetRecoil } from 'recoil-outside';
import { ENV } from '@Utils/envTools';

const getUserInfoItem = async (itemKey) => {
  const userInfo = (await promiseGetRecoil(userInfoState)) || {};
  return userInfo[itemKey];
};

const getUserInfoItemFromStorage = (itemKey) => {
  const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
  return userInfo[itemKey];
};

export const getApiToken = () => getUserInfoItem('apiToken');

export const getOAuthToken = async () => {
  let authRefresh = (await promiseGetRecoil(authRefreshState)) || false;
  const start = Date.now();
  while (authRefresh && authRefresh.refreshing) {
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1/2 second
    authRefresh = await promiseGetRecoil(authRefreshState);
    // Timeout after 3 seconds
    if (Date.now() - start > 3000) {
      authRefresh = false;
    }
  }
  const auth0Settings = ENV.OAUTH2;
  const isAuth0 = auth0Settings.organization !== undefined;
  const token_expires_at = (await getUserInfoItem('token_expires_at')) || 0;

  if (isAuth0 && token_expires_at < Date.now()) {
    await promiseSetRecoil(authRefreshState, true);
    const getAccessTokenSilently = getGetAccessTokenSilently();
    const getIdTokenClaims = getGetIdTokenClaims();
    const thisToken = await getAccessTokenSilently().catch((error) => {
      // Nothing to do here - thisToken will be empty
    });
    const idToken = await getIdTokenClaims();
    // If token is empty return null
    if (!idToken) return null;

    const today = new Date();
    const passwordExp = idToken.neosoma?.password_expiration || today;
    if (today > new Date(passwordExp)) {
      // This will send the user back to the login page with an "unauthorized" message
      return '';
    }

    const userInfo = (await promiseGetRecoil(userInfoState)) || {};

    const token_bearer = thisToken || userInfo.token_bearer;
    const thisUserInfo = {
      ...userInfo,
      token_bearer,
      token_expires_at: (idToken.exp || 0) * 1000
    };

    await promiseSetRecoil(userInfoState, thisUserInfo);
    await promiseSetRecoil(authRefreshState, false);
  }

  return getUserInfoItem('token_bearer');
};

export const getUserEmail = () => getUserInfoItem('username');

export const getHospitalId = () => getUserInfoItem('hospital_id');

export const setCookies = (cookies = {}, userInfo = {}) => {
  // Set cookies - note that we need a special path for "avatars"
  let domain = ENV.CONTENT_HOST;
  const { hospital_id, home_hospital_id } = userInfo;
  const ids = {};
  ids[home_hospital_id] = 2;
  ids[hospital_id] = 1;

  Object.keys(ids).forEach((id) => {
    const cookiePath = `path=/${id}/${ids[id] === 1 ? '' : '_avatars/'}`;
    const subdomain = domain.replace(/^.+?\./, '');
    const cookieType = ids[id] === 1 ? 'patient' : 'avatar';
    const cookiePairs = [
      ['CloudFront-Policy', cookies[`${cookieType}_policy`]],
      ['CloudFront-Key-Pair-Id', cookies[`${cookieType}_key_pair_id`]],
      ['CloudFront-Signature', cookies[`${cookieType}_signature`]]
    ];

    const maxAge = ` Max-Age=${4.9 * 60}`; // 4 minutes 54 seconds

    if (domain !== window.location.host) {
      // Strip the first part of the content domain away (for dev)
      domain = subdomain;
    }
    for (let i = 0; i < cookiePairs.length; i += 1) {
      const [cookieName, cookieValue] = cookiePairs[i];
      // TODO: this cookie expiration is really only an issue between local testing and dev
      document.cookie = `${cookieName}=;domain=${subdomain};${cookiePath};Max-Age=-99999999;`;
      document.cookie = `${cookieName}=${cookieValue};domain=${domain};${cookiePath};${maxAge}`;
    }
    domain = ENV.CONTENT_HOST;
  });
};

const privGetter = (privilegeId) => {
  const privileges = getUserInfoItemFromStorage('privileges');
  if (!privileges) return false;
  return privileges.includes(privilegeId);
};

export const userPrivs = {
  // Application Privs
  get LOG_IN() {
    return privGetter('1');
  },
  get SWITCH_HOSPITALS() {
    return privGetter('2');
  },
  // Patient Privs
  get LIST_PATIENTS() {
    return privGetter('10');
  },
  get VIEW_PATIENT() {
    return privGetter('11');
  },
  get EDIT_PATIENT() {
    return privGetter('12');
  },
  get CREATE_PATIENT() {
    return privGetter('13');
  },
  get DELETE_PATIENT() {
    return privGetter('14');
  },
  // Study Privs
  get LIST_STUDIES() {
    return privGetter('30');
  },
  get VIEW_STUDIES() {
    return privGetter('31');
  },
  get EDIT_STUDY() {
    return privGetter('32');
  },
  get UPLOAD_STUDY() {
    return privGetter('33');
  },
  get DELETE_STUDY() {
    return privGetter('34');
  },
  get EXPORT_STUDY() {
    return privGetter('35');
  },
  get RATE_STUDY() {
    return privGetter('36');
  },
  // Attachments & Doc privs
  get LIST_PATIENT_ATTACHMENTS() {
    return privGetter('50');
  },
  get VIEW_PATIENT_ATTACHMENT() {
    return privGetter('51');
  },
  get EDIT_PATIENT_ATTACHMENT() {
    return privGetter('52');
  },
  get ADD_PATIENT_ATTACHMENT() {
    return privGetter('53');
  },
  get DELETE_PATIENT_ATTACHMENT() {
    return privGetter('54');
  },
  // Patient Treatments
  get LIST_PATIENT_NOTES() {
    return privGetter('60');
  },
  get VIEW_PATIENT_NOTES() {
    return privGetter('61');
  },
  get EDIT_PATIENT_NOTES() {
    return privGetter('62');
  },
  get CREATE_PATIENT_NOTES() {
    return privGetter('63');
  },
  get DELETE_PATIENT_NOTES() {
    return privGetter('64');
  },
  // Patient Treatments
  get LIST_PATIENT_TREATMENTS() {
    return privGetter('60');
  },
  get VIEW_PATIENT_TREATMENTS() {
    return privGetter('61');
  },
  get EDIT_PATIENT_TREATMENTS() {
    return privGetter('62');
  },
  get CREATE_PATIENT_TREATMENTS() {
    return privGetter('63');
  },
  get DELETE_PATIENT_TREATMENTS() {
    return privGetter('64');
  },
  // Patient Dispositions
  get LIST_PATIENT_DISPOSITIONS() {
    return privGetter('60');
  },
  get VIEW_PATIENT_DISPOSITIONS() {
    return privGetter('61');
  },
  get EDIT_PATIENT_DISPOSITIONS() {
    return privGetter('62');
  },
  get CREATE_PATIENT_DISPOSITIONS() {
    return privGetter('63');
  },
  get DELETE_PATIENT_DISPOSITIONS() {
    return privGetter('64');
  },
  // Patient Summary
  get VIEW_PATIENT_SUMMARY() {
    return privGetter('71');
  },
  get EDIT_PATIENT_SUMMARY() {
    return privGetter('72');
  },
  // Reporting Privs
  get VIEW_PATIENT_RANO_REPORT() {
    return privGetter('81');
  },
  // Super User: Cannot be assigned to a role (strictly a User Privilege),
  get SUPER_USER() {
    return privGetter('999');
  }
};
