import mimeDB from 'mime-db';
import { bringSanityToMimeType } from '@Utils/generalConstants';
import { isSafari } from '@Utils/common';

const neoMimeDB = Object.keys(mimeDB).reduce((objt, key) => {
  const newKey = bringSanityToMimeType(key);
  return {
    ...objt,
    [newKey]: mimeDB[key]
  };
}, {});

export const RENDERABLE_ATTACHMENT_TYPES = {
  APNG: 'apng',
  AVIF: 'avif',
  GIF: 'gif',
  JPEG: 'jpeg',
  PNG: 'png',
  SVG: 'svg',
  WEBP: 'webp',
  TIF: 'tif', // only safari
  BMP: 'bmp'
};

export const isRenderableImage = (mimeType) => {
  const extention = getExtentionFromMimeType(mimeType);
  if (
    extention === 'tif' &&
    Object.values(RENDERABLE_ATTACHMENT_TYPES).includes(extention)
  ) {
    return isSafari();
  }
  return Object.values(RENDERABLE_ATTACHMENT_TYPES).includes(extention);
};

export const getExtentionFromMimeType = (mimeType) =>
  neoMimeDB[mimeType]?.extensions[0];

export const mimeFromType = (mimeType) => neoMimeDB[mimeType];

export const DOCUMENT_TYPES = {
  Pathology: 'pathology',
  Radiology: 'radiology',
  Oncology: 'oncology',
  Surgery: 'surgery',
  Other: 'other'
};
