/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { userInfoState, authRefreshState } from '@Utils/atoms';
import { promiseSetRecoil, promiseGetRecoil } from 'recoil-outside';
import getString from '@Components/strings';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const PATCH = 'patch';
const HEAD = 'head';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Headers': '*'
};
const defaultOptions = {
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache' // *default, no-cache, reload, force-cache, only-if-cached
};

// @ returnHeaders: bool; returns an object with data and response headers.

const responseIsJson = (response) =>
  response.headers.get('content-type')?.includes('application/json');

const redirectToLoginCodes = {
  400: true,
  401: true,
  403: true
};

const doFetch = async (uri, options = {}, useToken = true) => {
  const {
    method = GET,
    headers = {},
    returnHeaders = false,
    returnErrorResponse = false,
    doNotRedirectOnError = false,
    ...theRest
  } = options;
  const thisOptions = {
    ...defaultOptions,
    ...theRest,
    method,
    headers: { ...defaultHeaders, ...headers }
  };
  let data;
  const logOut = async () => {
    if (doNotRedirectOnError) return;
    localStorage.removeItem('user');
    // Clearing user info state will cause protected routes to log user out.
    await promiseSetRecoil(userInfoState, {
      loginMessage: getString('sessionLogutMessage')
    });
  };

  // Check if the user is refreshing their token
  let authRefresh = (await promiseGetRecoil(authRefreshState)) || false;
  const start = Date.now();
  while (authRefresh && authRefresh.refreshing) {
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1/2 second
    authRefresh = await promiseGetRecoil(authRefreshState);
    // Timeout after 3 seconds
    if (Date.now() - start > 3000) {
      authRefresh = false;
    }
  }
  const userInfo = await promiseGetRecoil(userInfoState);

  // if the token has changed, update the Authorization header
  if (
    useToken &&
    userInfo.token_bearer &&
    thisOptions.headers.Authorization !== `Bearer ${userInfo.token_bearer}`
  ) {
    thisOptions.headers.Authorization = `Bearer ${userInfo.token_bearer}`;
  }
  // End of token refresh check

  const res = await fetch(uri, thisOptions)
    .then(
      async (response) => {
        const isJson = responseIsJson(response);

        const errorResponse = !response.ok;
        if (errorResponse) {
          // get error message from body or default to response status
          if (redirectToLoginCodes[response.status]) {
            // Go to login page on 401 or 403
            await logOut();
          }
          const resData = isJson ? await response.json() : {};

          if (returnErrorResponse) {
            return Promise.reject(
              new Error(
                JSON.stringify({ statusCode: response.status, resData })
              )
            );
          }
          return Promise.reject(new Error(response.status));
        }
        try {
          data = isJson ? await response.json() : null;
        } catch (e) {
          data = null;
        }

        const resHeaders = [...response.headers].reduce(
          (obj, [key, value]) => ({ ...obj, [key]: value }),
          {}
        );
        if (returnHeaders) return { data, resHeaders };
        return data;
      },
      (err) => {
        if (returnHeaders)
          return { data: { status: { type: 'error', err } }, resHeaders: {} };
        return { status: { type: 'error', err } };
      }
    )
    .catch(async (e) => {
      if (returnErrorResponse && doNotRedirectOnError) return Promise.reject(e);
      // Logout on unknow errors.
      await logOut();
      // TODO: handle errors log on backend throw error let caller handle errors?
      return returnErrorResponse ? Promise.reject(e) : null;
    });
  return res;
};

doFetch.methods = { GET, POST, PUT, PATCH, HEAD };

export default doFetch;
