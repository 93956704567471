function getSegmentationValues(study) {
  const zeroVol = { volume: 0 };
  return {
    id: study.id,
    date: new Date(study.study_date).getTime(),
    study_type: study.study_type,
    flair:
      (study.segmentations.find((g) => g.label === 'Edema') || zeroVol).volume /
      1000,
    necrosis:
      (study.segmentations.find((g) => g.label === 'Necrotic') || zeroVol)
        .volume / 1000,
    enhancing:
      (study.segmentations.find((g) => g.label === 'Enhancing') || zeroVol)
        .volume / 1000,
    whole:
      (study.segmentations.find((g) => g.label === 'Whole Tumor') || zeroVol)
        .volume / 1000
  };
}

function addPriorProprties(imageData) {
  // Insure our sort order and assign the prior data row to help ToolTip calcs
  const newImageData = imageData.sort((a, b) => a.date - b.date);
  // Now add in the prior rows (for all but the first row)
  for (let i = 1; i < newImageData.length; i += 1) {
    newImageData[i].prior = newImageData[i - 1];
  }
  if (newImageData.length === 0) {
    // Add a blank row if empty to prevent chart crashing
    newImageData.push({ id: '', date: 0 });
  }
  return newImageData;
}

export { getSegmentationValues, addPriorProprties };
