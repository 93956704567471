/* eslint-disable react/destructuring-assignment */
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import { defaultTheme, defaultTheme as theme } from './theme';
import Flex from './Flex';

const defaultOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

export const SELECT_STYLES = {
  control: (provided, state) => ({
    border: `1px solid ${theme.palette.geyser}`,
    backgroundColor: state.isDisabled
      ? `${theme.palette.selago}`
      : `${theme.palette.white}`,
    boxShadow: 'none',
    borderRadius: '2px',
    fontSize: '0.75rem',
    color: theme.palette.mineshaft,
    outline: 0,
    display: 'flex',
    '&:active': { border: `1px dotted ${theme.palette.azure}` },
    '&:hover': {
      border: `1px dotted ${theme.palette.azure}`,
      cursor: 'pointer'
    },
    '&:focus': { border: `1px dotted ${theme.palette.azure}` }
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: () => ({ display: 'none' }),
  option: (provided, state) => {
    const isGroupedOption = state.data && state.data.groupedItem;
    return {
      ...provided,
      height: '32px',
      fontSize: '0.75rem',
      paddingLeft: isGroupedOption ? '26px' : '14px',
      backgroundColor: state.isSelected
        ? theme.palette.antiflashwhite
        : 'transparent',
      color: theme.palette.mineshaft,
      '&:hover': {
        backgroundColor: theme.palette.antiflashwhite,
        cursor: 'pointer'
      },
      '&:focus': { backgroundColor: theme.palette.antiflashwhite }
    };
  },
  groupHeading: (provided) => ({
    ...provided,
    textTransform: 'none',
    fontSize: '0.75ream',
    color: theme.palette.mineshaft,
    fontWeight: 700
  }),
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '100%'
  })
};

function Option(props) {
  return (
    <components.Option {...props}>
      <Flex alignItems="center" gap="8px">
        {props.isSelected ? (
          <FiCheckSquare color={defaultTheme.palette.sanfelix} />
        ) : (
          <FiSquare />
        )}
        {props.children}
      </Flex>
    </components.Option>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool.isRequired
};

Option.defaultProps = {
  children: null
};

export default function SelectMenu({
  options,
  onChange,
  placeholder,
  defaultValue,
  disabled,
  styles,
  // eslint-disable-next-line react/prop-types
  externalRef,
  ...remainingProps
}) {
  return (
    <Select
      ref={externalRef}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      styles={{ ...SELECT_STYLES, ...styles }}
      defaultValue={defaultValue}
      isDisabled={disabled}
      components={{ Option }}
      hideSelectedOptions={false}
      {...remainingProps}
    />
  );
}

SelectMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object
};

SelectMenu.defaultProps = {
  options: defaultOptions,
  onChange: () => {},
  placeholder: 'Select',
  defaultValue: null,
  disabled: false,
  styles: {}
};
