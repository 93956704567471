import { DropDown, DropdownItem } from '../../components/NeosomaUI';

export default function PatientActionMenu() {
  return (
    <DropDown buttonText="Actions" variant="secondary" position="right">
      <DropdownItem>
        <button type="button">Send to Tumor Board</button>
      </DropdownItem>
      <DropdownItem>
        <button type="button">Snooze Patient</button>
      </DropdownItem>
      <DropdownItem>
        <button type="button">Add Treatment</button>
      </DropdownItem>
      <DropdownItem>
        <button type="button">Continue Treatment Pain</button>
      </DropdownItem>
    </DropDown>
  );
}
