/* eslint-disable camelcase */
import { userInfoState, cookieRefreshState } from '@Utils/atoms';
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import {
  getApiToken,
  getOAuthToken,
  setCookies
} from '@Utils/auth/userInfoUtils';
import { promiseGetRecoil, promiseSetRecoil } from 'recoil-outside';

const { GET } = doFetch.methods;
let timeStamp = { time: Date.now(), cookies: undefined };
timeStamp.time -= 1000 * 60 * 5;
let queue = [];

const getAuthCookies = async ({
  broadCastRefresh = true,
  forceRefresh = false
} = {}) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();
  const fiveMin = 1000 * 60 * 4.75; // give a 15 second buffer
  const domain = ENV.CONTENT_HOST;
  const userInfo = await promiseGetRecoil(userInfoState);
  const isCookieRefresh = await promiseGetRecoil(cookieRefreshState);

  const setCookieRefeshing = (bool) => {
    if (!broadCastRefresh) return Promise.resolve();
    return promiseSetRecoil(cookieRefreshState, {
      ...isCookieRefresh,
      refreshing: bool
    });
  };

  const setUserInfo = async (newUserInfo) => {
    await promiseSetRecoil(userInfoState, newUserInfo);
  };

  // if a request is already in process, wait for it to finish
  if (isCookieRefresh.refreshing) {
    return new Promise((resolve) => {
      queue.push(resolve);
    });
  }
  if (Date.now() - timeStamp.time < fiveMin && !forceRefresh) {
    setCookieRefeshing(true);
    return Promise.resolve(timeStamp.cookies).then(() => {
      setCookieRefeshing(false);
    });
  }

  if (sessionStorage.getItem('cookieAutoRefesh') === 'false') {
    sessionStorage.setItem('cookieAutoRefesh', 'true');
  }

  await setCookieRefeshing(true);

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(
    `${uri}/patient/get_auth_cookies?client_id=${hospitalId}&domain=${domain}`,
    {
      method: GET,
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      }
    }
  )
    .then((freshBakedCookies) => {
      timeStamp = { time: Date.now(), cookies: freshBakedCookies };
      setCookieRefeshing(false);
      // let everyone know that the refresh has completed
      queue.forEach((resolve) => resolve(freshBakedCookies));
      queue = [];
      setCookies(freshBakedCookies, userInfo);
      setUserInfo({ ...userInfo, cookies: freshBakedCookies });
      return Promise.resolve(freshBakedCookies);
    })
    .catch((error) => {
      // TODO: maybe handle this error better?
      setCookieRefeshing(false);
      // let everyone know that the refresh has completed.
      queue.forEach((resolve) => resolve(''));
      queue = [];
    });
};

export default getAuthCookies;
