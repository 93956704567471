/* eslint-disable react/prop-types */
import { Tabs, Tab } from '@Components/NeosomaUI';
import styled from '@emotion/styled';
import EmpiricalResultsGraph from './Graphs/EmpericalResultsGraph';
import Enhancing from './Graphs/Enhancing';
import Flair from './Graphs/Flair';
import Necrosis from './Graphs/Necrosis';
import WholeTumor from './Graphs/WholeTumor';

const GraphContainer = styled.div`
  height: 300px;
  width: 100%;
  margin-left: -30px;
`;

export default function EmpiricalResults({ data }) {
  return (
    <Tabs>
      <Tab
        title="Empirical Results"
        style={{ minWidth: '600px', marginRight: '0px' }}
      >
        <GraphContainer>
          <EmpiricalResultsGraph data={data} />
        </GraphContainer>
      </Tab>
      {data.length > 0 && data[0].date > 0 && (
        <Tab
          title="Enhancing"
          style={{ minWidth: '600px', marginRight: '0px' }}
        >
          <GraphContainer>
            <Enhancing data={data} />
          </GraphContainer>
        </Tab>
      )}
      {data.length > 0 && data[0].date > 0 && (
        <Tab title="FLAIR" style={{ minWidth: '600px', marginRight: '0px' }}>
          <GraphContainer>
            <Flair data={data} />
          </GraphContainer>
        </Tab>
      )}
      {data.length > 0 &&
        data[0].date > 0 &&
        data.slice(-1)[0].study_type !== 2 && (
          <Tab
            title="Necrosis / Cavity"
            style={{ minWidth: '600px', marginRight: '0px' }}
          >
            <GraphContainer>
              <Necrosis data={data} />
            </GraphContainer>
          </Tab>
        )}
      {data.length > 0 && data[0].date > 0 && (
        <Tab
          title="Whole Tumor"
          style={{ minWidth: '600px', marginRight: '0px' }}
        >
          <GraphContainer>
            <WholeTumor data={data} />
          </GraphContainer>
        </Tab>
      )}
    </Tabs>
  );
}
