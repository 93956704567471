/* eslint-disable no-underscore-dangle */

// Proxy allows the array to retun the object by the id property of the object
// example test = [{ id: 'foo' }, {id: 'bar'}]
// test[0]      // { id: 'foo' }
// test['0']    // { id: 'foo' }
// test['foo']  // { id: 'foo'}
const proxyConfigAllowIdSearch = {
  get: (arr, property) => {
    if (!isNaN(property) && !isNaN(parseFloat(property))) {
      return arr[property];
    }
    if (
      typeof property === 'string' &&
      arr.find((item) => item.id === property)
    ) {
      return arr.find((item) => item.id === property);
    }
    return Reflect.get(arr, property);
  }
};

const _patientListTabs = [
  { id: 'unreviewed', displayText: 'Unreviewed', filterId: 'unreviewed' },
  { id: 'reviewed', displayText: 'Reviewed', filterId: 'reviewed' },
  { id: 'pinned', displayText: 'Pinned', filterId: 'pinned' }
];

export const PATIENT_LIST_TABS = new Proxy(
  _patientListTabs,
  proxyConfigAllowIdSearch
);

const _patientPortalTabs = [
  { id: 'patientSummary', displayText: 'Patient Summary' },
  { id: 'imaging', displayText: 'Imaging' },
  { id: 'analysisSummary', displayText: 'Analysis Summary' },
  // { id: 'dispositions', displayText: 'Dispositions' }, // Removed for MVP NUI-391 DEC-2023
  { id: 'attachments', displayText: 'Attachments' }
];

export const PATIENT_PORTAL_TABS = new Proxy(
  _patientPortalTabs,
  proxyConfigAllowIdSearch
);
