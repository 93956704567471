import { WebAuth } from 'auth0-js';
import { useEffect } from 'react';

const cookieDomain = `.${(window.env.oauth2.domain || '')
  .split('.')
  .slice(-2)
  .join('.')}`;

// Note: See the comment on cookieDomain in src/index.js

function AuthFallback() {
  useEffect(() => {
    const webAuth = new WebAuth({
      domain: window.env.oauth2.domain,
      clientID: window.env.oauth2.client_id,
      cookieDomain
    });

    webAuth.crossOriginVerification();
  }, []);

  return (
    <div>
      {/* This page can be left blank, or you can add a loading spinner or similar if you like */}
    </div>
  );
}

export default AuthFallback;
