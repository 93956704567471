import { atom, selector } from 'recoil';
import { getSetting, setSetting } from '@Utils/userSettings';
import userInfoState from './auth/userInfoAtom';

const showDisclaimerAtom = atom({
  key: 'showDisclaimerState',
  default: undefined
});

export const showDisclaimer = selector({
  key: 'showDisclaimer',
  get: ({ get }) => {
    const showDisclaimerState = get(showDisclaimerAtom);
    if (showDisclaimerState === 'forceShow') return true;
    if (
      showDisclaimerState === true &&
      localStorage.getItem('showDisclaimer') !== 'false'
    ) {
      // get the user info from the userInfoState atom
      const userInfo = get(userInfoState);
      const { id } = userInfo || {};
      // if there is no id, return true (show the disclaimer), this should never happen
      if (!id) return false;
      // get the user settings from local storage
      // if there are user settings, return the showDisclaimer value
      const hideLoginDisclaimer = getSetting(id, 'hideLoginDisclaimer');
      return hideLoginDisclaimer === undefined ? true : !hideLoginDisclaimer;
    }
    return false;
  },
  set: ({ get, set, reset }, newValue) => {
    // If the newValue is an object, reset the showDisclaimerAtom
    // It shoudl only be an object when reset is called.
    if (typeof newValue === 'object') {
      reset(showDisclaimerAtom);
      return;
    }
    // get the user info from the userInfoState atom
    const userInfo = get(userInfoState);
    const { id } = userInfo || {};

    if (newValue === undefined) return;
    if (newValue === 'dismiss') {
      set(showDisclaimerAtom, false);
      localStorage.setItem('showDisclaimer', false);
      return;
    }
    if (newValue === 'show') {
      if (localStorage.getItem('showDisclaimer') === 'false') return;
      set(showDisclaimerAtom, true);
      return;
    }
    if (newValue === 'forceShow') {
      set(showDisclaimerAtom, 'forceShow');
      return;
    }

    // if there is no id, return true (show the disclaimer), this should never happen
    if (!id) return;

    if (newValue === 'set' || newValue === 'unset') {
      setSetting(id, 'hideLoginDisclaimer', newValue === 'set');
    }
  }
});

showDisclaimer.disclaimerOptions = {
  forceShow: 'forceShow',
  show: 'show',
  dismiss: 'dismiss',
  setDoNotShow: 'set',
  unsetDoNoShow: 'unset'
};

export default showDisclaimer;
