import { atom } from 'recoil';

const rolodexState = atom({
  key: 'rolodex',
  default: sessionStorage.getItem('rolodex')
    ? JSON.parse(sessionStorage.getItem('rolodex'))
    : undefined
});

export default rolodexState;
