import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import screenLoaderImg from '../../../assets/Screen-loader.svg';

const Loader = styled.div`
  padding: 0;
  column-gap: 8px;
  width: ${({ singelLoader }) => (singelLoader ? '24px' : '75px')};
  border-radius: 6px;
  height: 20px;

  & img.bounce {
    animation-name: bouncer;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  & img.bounce:nth-of-type(2) {
    animation-delay: 100ms;
    margin-left: 6px;
  }
  & img.bounce:nth-of-type(3) {
    animation-delay: 200ms;
    margin-left: 6px;
  }
  @keyframes bouncer {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(4px);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-2px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-0.5px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
`;
const LoaderImg = styled.img`
  height: 12px;
`;

function InlineSpinner({ singelLoader, ...rest }) {
  return (
    <Loader singelLoader={singelLoader} {...rest}>
      <LoaderImg src={screenLoaderImg} className="bounce" />
      {!singelLoader && <LoaderImg src={screenLoaderImg} className="bounce" />}
      {!singelLoader && <LoaderImg src={screenLoaderImg} className="bounce" />}
    </Loader>
  );
}

InlineSpinner.defaultProps = {
  singelLoader: false
};

InlineSpinner.propTypes = {
  singelLoader: PropTypes.bool
};

export default InlineSpinner;
