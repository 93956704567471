/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { POST } = doFetch.methods;

const postStudyExportRequest = async ({
  patientId,
  studyId,
  platformId,
  exportType,
  seriesTypeIdx,
  compress
}) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(`${uri}/patient/study/export?client_id=${hospitalId}`, {
    method: POST,
    headers: {
      Authorization: authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      patient_id: patientId,
      study_id: studyId,
      platform_id: platformId,
      export_type: exportType,
      series_idx: seriesTypeIdx,
      use_compression: compress
    }),
    doNotRedirectOnError: true
  }).catch((e) => postStudyExportRequest.errorMessage);
};

postStudyExportRequest.errorMessage = 'Unexpected Error - see log: 0';

export default postStudyExportRequest;
