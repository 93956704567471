import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { selectedPatientState } from '@Utils/atoms';
import { Modal, useModal } from '@Components/NeosomaUI';
import AddTreatment, { MODES } from '../../patients/Components/AddTreatment';

export default function AddTreatmentModal({ visable, setVisable }) {
  const {
    isShowing: addTreatmentIsVisible,
    toggle: toggleAddTreatment,
    setIsShowing
  } = useModal();
  const selectedPatient = useRecoilValue(selectedPatientState) || {};
  const patientData = selectedPatient[selectedPatient?.currentPatient] || {};

  useEffect(() => {
    setIsShowing(visable);
  }, [setIsShowing, visable]);

  const handleDismiss = () => {
    setVisable(false);
    toggleAddTreatment();
  };

  return (
    <Modal
      isShowing={addTreatmentIsVisible}
      hide={handleDismiss}
      title="Add Treatment"
      buttonText="Save"
      hideFooter // Needed for the form submit to work
    >
      <AddTreatment
        mode={MODES.ADD}
        patientId={patientData.id}
        dismissModal={handleDismiss}
      />
    </Modal>
  );
}

AddTreatmentModal.propTypes = {
  visable: PropTypes.bool,
  setVisable: PropTypes.func
};

AddTreatmentModal.defaultProps = {
  visable: false,
  setVisable: () => {
    /* No Op */
  }
};
