import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { forwardRef, useRef } from 'react';
import Flex from '../Flex';
import { FormLabel } from './Input';

const StyledFormLabel = styled(FormLabel)`
  font-weight: 400;
  font-size: 0.825rem;
`;

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.palette.geyser};
  background-color: ${({ theme }) => theme.palette.white};
  min-height: 38px;
  color: ${({ theme }) => theme.palette.mineshaft};
  padding: 8px 7px;
  border-radius: 2px;

  &:disabled {
    background-color: ${({ theme }) => theme.palette.selago};
    color: ${({ theme }) => theme.palette.submarine};
  }
`;

const CheckBox = forwardRef(
  ({ id, name, label, style, children, ...props }, ref) => {
    const guid = useRef(
      // This "pseudorandom number" is fine here
      Date.now().toString(36) + Math.random().toString(36).substring(2, 5) // NOSONAR
    );
    return (
      <Flex alignItems="center" gap="8px" style={style}>
        <StyledFormLabel htmlFor={id || guid} label={label}>
          <StyledInput
            id={id || guid}
            ref={ref}
            name={name}
            type="checkbox"
            aria-label={label}
            {...props}
          />
        </StyledFormLabel>
        {children}
      </Flex>
    );
  }
);

CheckBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

CheckBox.defaultProps = {
  id: undefined,
  name: 'my-checkbox',
  label: 'click me',
  children: undefined,
  style: {}
};

export default CheckBox;
