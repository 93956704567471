/* eslint-disable camelcase */
import { useEffect, useRef, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useRecoilState } from 'recoil';

import { Flex, SelectMenu, ConfirmModal } from '@Components/NeosomaUI';
import {
  FormInput,
  FormLabel,
  FormTextarea
} from '@Components/NeosomaUI/Form/Input';
import { ModalFooter } from '@Components/NeosomaUI/Modal';
import { TUMOR_TREATMENT_TYPES, TIME_FRAMES } from '@Utils/generalConstants';

import { selectedPatientState, currentPatientListState } from '@Utils/atoms';
import postPatientTreatment from '@Utils/api/postPatientTreatment';
import updatePatientTreatment from '@Utils/api/updatePatientTreatment';

const FormRow = styled(Flex)`
  width: 100%;
  margin-bottom: 26px;
  & .react-datepicker-wrapper {
    width: 100%;
    height: 38px;
    & input {
      width: 100%;
      padding: 7px 8px;
      border: 1px solid ${({ theme }) => theme.palette.geyser};
      border-radius: 2px;
    }
    & * {
      height: 100%;
    }
  }
`;

const Hr = styled.hr`
  margin-bottom: 17px;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.palette.antiflashwhite};
`;

const StyledFlex = styled(Flex)`
  & div[class$='-Control'],
  & div.react-datepicker-wrapper {
    border: ${({ theme, error }) =>
      error ? `1px solid ${theme.palette.thunderbird} !important` : ''};
    border-radius: 2px;
    & div[class$='-Control'] input {
      border: none;
    }
  }
`;

const FormElement = forwardRef(({ direction, gap, ...props }, ref) => (
  <StyledFlex ref={ref} {...props} direction={direction} gap={gap} fullwidth />
));

FormElement.propTypes = {
  direction: PropTypes.string,
  gap: PropTypes.string
};

FormElement.defaultProps = {
  direction: 'column',
  gap: '8px'
};

const options = Object.values(TUMOR_TREATMENT_TYPES).map((t) => {
  if (Array.isArray(t)) {
    const [label, thisOptions] = t;
    return {
      label,
      options: Object.values(thisOptions).map((o) => ({
        label: o,
        value: `${label} / ${o}`,
        name: o,
        groupedItem: true
      }))
    };
  }
  return {
    label: t,
    value: t,
    name: t
  };
});

options.findOption = (value) => {
  // since we have grouped options, we need to find the value in the groups.
  const selection = options.find((c) => {
    const isGrouped = Object.prototype.hasOwnProperty.call(c, 'options');
    if (isGrouped) {
      return !!c.options.find((o) => o.value === value);
    }
    return c.value === value;
  });
  const isGrouped =
    selection && Object.prototype.hasOwnProperty.call(selection, 'options');
  if (isGrouped) {
    return selection.options.find((c) => c.value === value) || {};
  }
  return selection || {};
};

export const MODES = {
  ADD: 'add',
  EDIT: 'edit'
};

// *-------------------------------*
// |  COMPONENT ::  AddTreatment   |
// *-------------------------------*
export default function AddTreatment({
  mode,
  dismissModal,
  patientId,
  treatmentId,
  confirmCancel
}) {
  const formRef = useRef(null);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const [patientList, setPatientList] = useRecoilState(currentPatientListState);
  const { latest_study_id } = selectedPatient[patientId];

  // *----------------------------*
  // |   Form Data Initialization |
  // *----------------------------*
  let defaultValues = { markAsReviewed: true, nextScan: '' };
  if (mode === MODES.EDIT) {
    const treatment =
      selectedPatient[patientId].treatments.find((t) => t.id === treatmentId) ||
      {};
    const { start_date, end_date } = treatment;
    const { next_scan_date } = selectedPatient[patientId];
    defaultValues = {
      ...defaultValues,
      ...{
        ...treatment,
        start_date: start_date ? new Date(start_date) : '',
        end_date: end_date ? new Date(end_date) : '',
        next_scan_date: next_scan_date ? new Date(next_scan_date) : '',
        nextScan: next_scan_date ? 'other' : ''
      },
      markAsReviewed: false
    };
  }
  const showMarkReviewed = !!latest_study_id;

  if (!showMarkReviewed) {
    delete defaultValues.markAsReviewed;
    delete defaultValues.next_scan_date;
    delete defaultValues.nextScan;
  }

  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty, errors }
  } = useForm({
    defaultValues,
    reValidateMode: ['onSubmit', 'onBlur', 'onchange']
  });

  const { markAsReviewed, nextScan } = watch();

  // *------------------*
  // |   Form Submit    |
  // *------------------*
  const onSubmit = handleSubmit(
    async ({ start_date, end_date, next_scan_date, ...data }) => {
      setIsCancelDisabled(true);
      setIsSaveDisabled(true);

      // Prepare the data for the API call.
      const nextData = {
        ...data,
        start_date:
          start_date instanceof Date
            ? `${start_date.toISOString().split('T')[0]}T00:00:00.000Z`
            : '',
        end_date:
          end_date instanceof Date
            ? `${end_date.toISOString().split('T')[0]}T00:00:00.000Z`
            : '',
        next_scan_date:
          next_scan_date instanceof Date
            ? `${next_scan_date.toISOString().split('T')[0]}T00:00:00.000Z`
            : undefined
      };

      // delete unnecessary data
      delete nextData.markAsReviewed;
      delete nextData.nextScan;

      if (!markAsReviewed) {
        delete nextData.next_scan_date;
        delete nextData.disposition_notes;
      }

      if (mode === MODES.ADD) {
        // call the API
        const { treatment_id } = await postPatientTreatment(patientId, {
          ...nextData,
          study_id: latest_study_id
        }).catch((error) => {
          // TODO: handle error
          // eslint-disable-next-line no-console
          console.error(error);
        });

        // update the patient local data.
        setSelectedPatient({
          ...selectedPatient,
          [patientId]: {
            ...selectedPatient[patientId],
            treatments: [
              ...(selectedPatient[patientId].treatments || []),
              {
                ...nextData,
                id: treatment_id
              }
            ]
          }
        });
        // Update the patient list local data.
        if (data.markAsReviewed) {
          setPatientList({
            ...patientList,
            records: patientList.records.filter((p) => p.id !== patientId)
          });
        }
      } else {
        // call the API
        updatePatientTreatment(patientId, {
          ...nextData,
          study_id: latest_study_id,
          treatment_id: treatmentId
        }).catch((error) => {
          // TODO: handle error
          // eslint-disable-next-line no-console
          console.error(error);
        });
        // update the patient local data.
        setSelectedPatient({
          ...selectedPatient,
          [patientId]: {
            ...selectedPatient[patientId],
            treatments: [
              ...(selectedPatient[patientId].treatments || [])
            ].reduce((arr, rec) => {
              if (rec.id === treatmentId) {
                return [...arr, { ...rec, ...nextData }];
              }
              return [...arr, rec];
            }, [])
          }
        });
        // Update the patient list local data.
        if (data.markAsReviewed) {
          setPatientList({
            ...patientList,
            records: patientList.records.filter((p) => p.id !== patientId)
          });
        }
      }
      dismissModal();
    }
  );

  const handleCancelClick = (e) => {
    e && e.preventDefault();
    if (confirmCancel) {
      if (isDirty) {
        setShowConfirmModal(true);
      } else {
        dismissModal();
      }
    }
    dismissModal();
  };

  const handleCancelClickFromConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleMarkAsReviewedChanged = (e) => {
    const { checked } = e.target;
    setValue('markAsReviewed', checked);

    // if used has unchecked the box, reset the values.
    if (!checked) {
      // reset the selected nextScan option
      setValue('nextScan', undefined);
      // reset the selected next_scan_date if other was chosen.
      setValue('next_scan_date', undefined);
    }
  };

  const handleNextScanDateOptionChange = (opt) => {
    setValue('nextScan', opt.value);
    // rest the date if the user selects something other than "other".
    if (opt.value !== 'other') {
      // calculate the date based on the number of weeks in selection.
      const weeks = parseInt(opt.value.split('-')[0] || 0, 10);
      const date = new Date(Date.now() + weeks * 7 * 24 * 60 * 60 * 1000);
      // set the next_scan_date to the calculated date.
      setValue('next_scan_date', date, {
        shouldDirty: true
      });
      // hide the next scan date picker as other was not selected
    } else {
      // remove the calculated date from the next_scan_date date picker.
      setValue('next_scan_date', undefined);
    }
  };

  useEffect(() => {
    if (isDirty && isSaveDisabled) {
      setIsSaveDisabled(false);
    }
  }, [isDirty, isSaveDisabled]);
  if (!patientId) return null;
  return (
    <>
      <ConfirmModal
        isShowing={showConfirmModal}
        onCancel={handleCancelClickFromConfirmModal}
        onConfirm={dismissModal}
        buttonText="Save"
      />
      <form
        ref={formRef}
        id="add-edit-treatment-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div style={{ padding: '17px 21px' }}>
          <FormRow gap="26px" alignItems="center">
            <FormElement error={errors.therapy_type}>
              <FormLabel label="Treatment Type" name="therapy_type" required />
              <Controller
                control={control}
                name="therapy_type"
                rules={{
                  required: true
                }}
                render={({ ref, field: { value } }) => (
                  <SelectMenu
                    inputRef={ref}
                    options={options}
                    placeholder="--"
                    value={options.findOption(value)}
                    onChange={(val) =>
                      setValue('therapy_type', val.value, { shouldDirty: true })
                    }
                  />
                )}
              />
            </FormElement>
          </FormRow>
          <FormRow gap="26px" alignItems="center">
            <FormElement>
              <FormLabel label="Clinician" name="clinician" />
              <FormInput
                id="Clinician"
                name="clinician"
                autoComplete="off"
                {...register('clinician')}
              />
            </FormElement>
            <FormElement>
              <FormLabel label="Start Date" name="start_date" />
              <Controller
                control={control}
                name="start_date"
                render={({ field }) => (
                  <DatePicker
                    portalId="root-portal"
                    selected={field.value}
                    dateFormat="MM/dd/yyyy"
                    id="start_date"
                    autoComplete="off"
                    onChange={(date) =>
                      setValue('start_date', date, { shouldDirty: true })
                    }
                  />
                )}
              />
            </FormElement>
            <FormElement>
              <FormLabel label="End Date" name="end_date" />
              <Controller
                control={control}
                name="end_date"
                render={({ field }) => (
                  <DatePicker
                    portalId="root-portal"
                    selected={field.value}
                    dateFormat="MM/dd/yyyy"
                    id="end_date"
                    autoComplete="off"
                    onChange={(date) =>
                      setValue('end_date', date, { shouldDirty: true })
                    }
                  />
                )}
              />
            </FormElement>
          </FormRow>
          <FormRow gap="26px" alignItems="center">
            <FormElement>
              <FormLabel label="Note" name="notes" />
              <FormTextarea id="notes" name="notes" {...register('notes')} />
            </FormElement>
          </FormRow>
          {showMarkReviewed && (
            <>
              <Hr />
              <FormRow gap="8px" alignItems="center">
                <input
                  type="checkbox"
                  id="mark-as-reviewed"
                  name="markAsReviewed"
                  value={markAsReviewed}
                  onChange={handleMarkAsReviewedChanged}
                  {...register('markAsReviewed')}
                  autoComplete="off"
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="mark-as-reviewed">
                  Mark this patient as Reviewed
                </label>
              </FormRow>
              {markAsReviewed && (
                <>
                  <FormRow gap="26px" alignItems="center">
                    <FormElement error={errors.nextScan}>
                      <FormLabel
                        label="Next Est. Scan"
                        name="nextScan"
                        required
                      />
                      <Controller
                        control={control}
                        name="nextScan"
                        rules={{
                          required: !!markAsReviewed
                        }}
                        render={({ ref, field: { value, onChange } }) => (
                          <SelectMenu
                            inputRef={ref}
                            options={TIME_FRAMES}
                            onChange={(e) => {
                              onChange(e);
                              handleNextScanDateOptionChange(e);
                            }}
                            placeholder="--"
                            value={TIME_FRAMES.find((c) => c.value === value)}
                          />
                        )}
                      />
                    </FormElement>
                    {nextScan === 'other' && (
                      <FormElement error={errors.next_scan_date}>
                        <FormLabel
                          label="Next Scan Date"
                          name="next-scan-date"
                          required={nextScan === 'other'}
                        />
                        <Controller
                          control={control}
                          rules={{
                            required: !!markAsReviewed
                          }}
                          name="next_scan_date"
                          render={({ field }) => (
                            <DatePicker
                              portalId="root-portal"
                              selected={field.value}
                              dateFormat="MM/dd/yyyy"
                              id="next_scan_date"
                              autoComplete="off"
                              onChange={(date) =>
                                setValue('next_scan_date', date, {
                                  shouldDirty: true
                                })
                              }
                            />
                          )}
                        />
                      </FormElement>
                    )}
                  </FormRow>
                  <FormRow gap="26px" alignItems="center">
                    <FormElement>
                      <FormLabel
                        label="Add information about today's review:"
                        name="disposition_notes"
                      />
                      <FormTextarea
                        id="disposition-notes"
                        name="disposition_notes"
                        {...register('disposition_notes')}
                      />
                    </FormElement>
                  </FormRow>
                </>
              )}
            </>
          )}
        </div>
        <ModalFooter
          isCancelDisabled={isCancelDisabled}
          isPrimaryDisabled={isSaveDisabled}
          onCancleClick={handleCancelClick}
        />
      </form>
    </>
  );
}

AddTreatment.propTypes = {
  mode: PropTypes.string,
  patientId: PropTypes.string,
  dismissModal: PropTypes.func,
  treatmentId: PropTypes.string,
  confirmCancel: PropTypes.bool
};

AddTreatment.defaultProps = {
  mode: undefined,
  patientId: undefined,
  dismissModal: () => {},
  treatmentId: undefined,
  confirmCancel: false
};
