let getAccessTokenSilentlyGlobal = null;
let getIdTokenClaimsGlobal = null;

export const setGlobalAuth0Functions = (
  getAccessTokenSilently,
  getIdTokenClaims
) => {
  getAccessTokenSilentlyGlobal = getAccessTokenSilently;
  getIdTokenClaimsGlobal = getIdTokenClaims;
};

export const getGetAccessTokenSilently = () => getAccessTokenSilentlyGlobal;
export const getGetIdTokenClaims = () => getIdTokenClaimsGlobal;
