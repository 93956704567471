import styled from '@emotion/styled';
import { Flex, SelectMenu, Typography } from '@Components/NeosomaUI';

export const AnalysisSummaryContainer = styled.div`
  gap: 23px;
  display: flex;
  flex-direction: column;

  & div[data-test='analysis-summary-current-study-select-menu'] {
    display: block;
  }
  & div[data-test='analysis-summary-current-study-title'] {
    display: none;
  }
  & div[data-test='analysis-summary-prev-study-select-menu'] {
    display: block;
  }
  & div[data-test='analysis-summary-prev-study-title'] {
    display: none;
  }

  p[data-test='generated-date'] {
    display: none;
  }

  img[data-test='logo'] {
    display: none;
  }

  div[data-test='analysis-summary-empirical-results-graph-print'] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  .chart-responsive-container {
    width: 21cm !important;
  }

  @media print {
    margin-top: 0.25cm;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.palette.white};
    z-index: 10000000000000;
    box-shadow: 0 0 0 20000px white;
    gap: 20px;

    // Addjust the width of the print page which effects the scale on the page.
    // padding-left: 1.5304cm;
    // padding-right: 1.5304cm;

    padding: 0 1.2204cm !important;

    div[data-test='analysis-summary-empirical-results-graph'] {
      display: none;
    }

    div[data-test='analysis-summary-empirical-results-graph-print'] {
      display: flex;
      opacity: 1;
      position: relative;
    }
    & div[data-test='analysis-summary-title-container'] {
      margin-bottom: 2px;
    }
    & div[data-test='analysis-summary-current-study-select-menu'] {
      display: none;
    }
    & div[data-test='analysis-summary-current-study-title'] {
      display: flex;
    }
    & div[data-test='analysis-summary-prev-study-select-menu'] {
      display: none;
    }
    & div[data-test='analysis-summary-prev-study-title'] {
      display: flex;
    }

    & div[data-test='analysis-summary-empirical-results-graph-print'] {
      border: 1px solid ${({ theme }) => theme.palette.platinum};
      border-radius: 8px;
      padding: 6px;
      padding-bottom: 0%;
    }

    div[data-test='image-card-header'] {
      & small {
        display: none;
      }
    }
    div[data-test='analysis-summary-image-card-container'] {
      justify-content: space-between;
      margin: 0 32px;
      margin-top: 10px;
      height: fit-content;
      flex-wrap: nowrap;
      column-gap: 31px;
      & div {
        width: 100%;
        max-width: unset;
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
    div[data-test='analysis-summary-title'],
    div[data-test='analysis-summary-title-container'] {
      margin-bottom: 6px;
    }
    p[data-test='generated-date'] {
      display: block;
      font-size: 10px;
      color: ${({ theme }) => theme.palette.mineshaft};
    }
    img[data-test='logo'] {
      display: block;
      height: 56px;
    }
  }
`;

export const TabTitle = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
  padding-bottom: 8px;
  margin-bottom: 23px;
  @media print {
    button {
      display: none;
    }
  }
`;

export const PatientDataTitle = styled(Typography)`
  text-decoration: underline;
`;

export const StyledTypography = styled(Typography)`
  margin-top: 8px;
  @media print {
    margin-top: -2px;
  }
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: 215px;
  z-index: 25;
`;

export const GraphContainer = styled.div`
  height: 278px;
  border: 1px solid ${({ theme }) => theme.palette.platinum};
  border-radius: 8px;
  width: 100%;
`;

export const InnerGraph = styled.div`
  margin-left: -15px;
  width: 100%;
  height: 278px;
`;

export const ImageCardContainer = styled.div`
  display: flex;
  column-gap: 13px;
  margin-top: 24px;
  flex-wrap: wrap;
  row-gap: 13px;
`;
