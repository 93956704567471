import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRecoilState, useSetRecoilState } from 'recoil';
import getString from '@Components/strings';
import { ENV } from '@Utils/envTools';
import { getSetting } from '@Utils/userSettings';

import { userInfoState, idleTimeoutState } from '@Utils/atoms';
import authenticateUser from '@Utils/auth/authenticateHandler';
import { Spinner } from '@Components/TaskView/TaskListLoader';

import { NeosomaError } from '@Utils/common';

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.athensgray};
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpinner = styled(Spinner)``;

function getHashParams() {
  const hashParams = {};
  const hashString = window.location.hash.substring(1);
  const params = hashString.split('&');

  for (let i = 0; i < params.length; i += 1) {
    const param = params[i].split('=');
    const paramName = decodeURIComponent(param[0]);
    const paramValue = decodeURIComponent(param[1]);

    hashParams[paramName] = paramValue;
  }

  return hashParams;
}

function AuthCallback() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const setIdleTimeout = useSetRecoilState(idleTimeoutState);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  function checkRememberMe(userId) {
    const rememberMe =
      getSetting(userId, 'rememberMe') === undefined
        ? true
        : getSetting(userId, 'rememberMe');
    if (!rememberMe) {
      sessionStorage.setItem('memento', true);
      // If remember me is not checked, start idle timeout.
      setIdleTimeout('start');
    }
  }

  const validNeosomaMsgs = [
    'Invalid Client Configuration',
    'Missing required Neosoma credentials',
    'Unable to create user',
    'Unable to access Neosoma API',
    'Access denied from the current IP',
    'Missing required Auth0 credentials'
  ];

  useEffect(() => {
    const params = getHashParams();
    if (params.error) {
      console.error('Error Returned from Auth:', params);
      let message =
        'There was an Unexpected Error <br> If you continue to have issues, please contact Support';
      if (params.error === 'access_denied') {
        message = 'Access Denied - Invalid Username / Password';
        if (
          validNeosomaMsgs.some((msg) =>
            params.error_description.startsWith(msg)
          )
        ) {
          message = params.error_description;
        }
      } else if (params.error === 'invalid_request') {
        message = params.error_description;
      }
      // Invalidate any existing user session:
      localStorage.removeItem('user');
      // Send them back to the login page
      navigate('/login', {
        replace: true,
        state: {
          authMessage: message
        }
      });
      return;
    }

    getAccessTokenSilently()
      .then(async (thisToken) => {
        try {
          const tkn = JSON.parse(atob(thisToken.split('.')[1]));
          const idToken = await getIdTokenClaims();

          const today = new Date();
          const passwordExp = idToken?.neosoma?.password_expiration || today;
          if (today > new Date(passwordExp)) {
            throw new NeosomaError(getString('passwordExpiredMessage'));
          }

          let disclaimer = getString('defaultDisclaimer');
          // We can also grab the Disclaimer here:
          const customDisclaimer = idToken?.neosoma?.disclaimer || {};
          if (Object.keys(customDisclaimer).length) {
            disclaimer = Object.keys(customDisclaimer)
              .sort()
              .reduce((result, key) => result + customDisclaimer[key], '');
          }

          if (disclaimer) {
            disclaimer = disclaimer.replace(
              '{{HOSPITAL_NAME}}',
              ENV.DOMAIN_TITLE
            );
            // TODO: Do something with the disclaimer
          }

          const neosomaPayload = tkn.neosoma || {};
          // Build user object.
          const user = {
            id: neosomaPayload.user_id,
            hospital_id: neosomaPayload.client_id,
            username: neosomaPayload.name,
            first_name: neosomaPayload.first_name,
            last_name: neosomaPayload.last_name,
            home_hospital_id: neosomaPayload.home_id,
            permissions: neosomaPayload.permissions,
            token_bearer: thisToken,
            token_expires_at: (idToken.exp || 0) * 1000
          };

          // If the user doesn't have the proper permissions, send back an informational message
          if ((tkn.permissions?.length || 0) === 0) {
            throw new NeosomaError(
              'User is not configured properly <br> please notify Neosoma Support'
            );
          }

          if (!user.first_name && !user.last_name) {
            user.last_name = user.username || tkn.email;
          }
          // Update user object in local storage.
          const userfromStorage = JSON.parse(localStorage.getItem('user'));
          let thisUser = {};

          if (!userInfo) {
            thisUser = { ...userfromStorage, ...user };
          } else {
            thisUser = { ...userInfo, ...user };
          }

          // Set user object in recoil state.
          setUserInfo(thisUser);
          checkRememberMe(user.id);

          const neoUserData = await authenticateUser({});

          // TODO: Handle Success = "false"

          // Set user object in local storage.
          localStorage.setItem(
            'user',
            JSON.stringify({ ...thisUser, ...neoUserData })
          );
          // Set user object in recoil state.
          setUserInfo({ ...thisUser, ...neoUserData });

          setIsLoading(false);
          setTimeout(() => {
            let redirect = '/';
            if (sessionStorage.getItem('redirect')) {
              redirect = sessionStorage.getItem('redirect');
              sessionStorage.removeItem('redirect');
            }
            navigate(redirect, { replace: true });
          }, 50);
        } catch (error) {
          setIsLoading(false);
          // Invalidate any existing user session:
          localStorage.removeItem('user');
          // Send them back to the login page
          let authMessage =
            'There was an error establishing your current session <br> Please try again or contact Neosoma Support';
          if (error.type === 'NeosomaError') {
            authMessage = error.message;
          }
          navigate('/login', {
            replace: true,
            state: {
              authMessage
            }
          });
          // No need to handle error
        }

        // Handle access token as needed
      })
      .catch((error) => {
        // Handle error
        setIsLoading(false);
        // Invalidate any existing user session:
        localStorage.removeItem('user');
        // Set the error message
        console.error('Error During Authentication:', error);
        navigate('/login', {
          replace: true,
          state: {
            authMessage:
              'There was an error during authentication <br> Please try again'
          }
        });
      });
  }, [getAccessTokenSilently]);

  return (
    <Container>
      {isLoading ? (
        <StyledSpinner>
          <span className="loader" />
        </StyledSpinner>
      ) : (
        <div />
      )}
    </Container>
  );
}

export default AuthCallback;
