/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { showDisclaimerState, userInfoState } from '@Utils/atoms';
import { getSetting } from '@Utils/userSettings';
import Checkbox from '@Components/NeosomaUI/Form/CheckBox';
import { Button } from '@Components/NeosomaUI';

function InputLoginDisclaimer() {
  const userInfo = useRecoilValue(userInfoState);
  const hideLoginDisclaimer = getSetting(userInfo.id, 'hideLoginDisclaimer');
  const [checked, setChecked] = useState(hideLoginDisclaimer);
  const [showModal, setShowModal] = useRecoilState(showDisclaimerState);

  const handleChange = (event) => {
    const thisHideLoginDisclaimer = getSetting(
      userInfo.id,
      'hideLoginDisclaimer'
    );

    if (thisHideLoginDisclaimer !== event.target.checked) {
      setShowModal(event.target.checked ? 'set' : 'unset');
    }
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const thisUserSettings = JSON.parse(
      localStorage.getItem('userSettings') || '{}'
    );
    if (
      thisUserSettings[userInfo.id] &&
      thisUserSettings[userInfo.id].hideLoginDisclaimer !== checked
    ) {
      setChecked(thisUserSettings[userInfo.id].hideLoginDisclaimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        id="userSettingsLoginDisclaimer"
        name="userSettingsLoginDisclaimer"
        label="Do not show disclaimer at login"
        style={{ width: 'fit-content', gap: '10px' }}
      >
        <Button
          type="button"
          variant="link"
          style={{ width: 'fit-content' }}
          onClick={() => {
            setShowModal(showDisclaimerState.disclaimerOptions.forceShow);
          }}
        >
          View disclaimer
        </Button>
      </Checkbox>
    </div>
  );
}

export default InputLoginDisclaimer;
