import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import { IoMailUnreadOutline } from 'react-icons/io5';

import { ENV } from '@Utils/envTools';
import { showResetPasswordState, userInfoState } from '@Utils/atoms';
import changePassword from '@Utils/auth/changePassword';
import { Flex, ConfirmModal, Error } from '@Components/NeosomaUI';
import { FormInput, FormLabel } from '@Components/NeosomaUI/Form/Input';
import { ModalFooter } from '@Components/NeosomaUI/Modal';
import { setCookies } from '@Utils/auth/userInfoUtils';
import PasswordVerificationModal from './PasswordVerificationModal';
import { Spinner } from '../TaskView/TaskListLoader';

const Container = styled(Flex)`
  &[data-lastpass-icon-root] {
    display: none !important;
  }
`;

const ErrorMessage = styled(Flex)`
  color: ${({ theme }) => theme.palette.thunderbird};
`;

const ListContainer = styled(Flex)`
  padding-left: 33px;
`;

const FormContainer = styled(Flex)`
  color: ${({ theme }) => theme.palette.mineshaft};
  margin-top: 16px;

  & form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    & input {
      width: 40ch;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  width: 66px;
  height: 66px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.azure};
`;
const SpinnerContainer = styled.div`
  display: flex;
  height: 130px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

function Message() {
  const [pause, setPause] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setPause(false);
    }, 3000);
  }, []);
  return (
    <>
      {!pause && (
        <>
          <IconWrapper>
            <IconContainer id="slider">
              <IoMailUnreadOutline size="50" />
            </IconContainer>
          </IconWrapper>
          <p>
            <strong>
              An email was sent to your inbox with a link to change your
              password.
            </strong>
          </p>
          <br />
          <p>
            If you don’t receive this email in the next minute or so, please
            check your junk mail folder or contact support.
          </p>
        </>
      )}
      {pause && (
        <SpinnerContainer>
          <Spinner>
            <div className="loader" />
          </Spinner>
          <div style={{ marginTop: '100px' }}>Processing...</div>
        </SpinnerContainer>
      )}
    </>
  );
}

function Auth0PasswordReset() {
  const [showResetPassword, setShowResetPassword] = useRecoilState(
    showResetPasswordState
  );

  const dismiss = () => {
    setShowResetPassword(false);
  };

  return (
    <Container>
      <ConfirmModal
        isShowing={showResetPassword}
        onConfirm={dismiss}
        onCancel={dismiss}
        cancelText=""
        title="Change Password"
        message={<Message />}
        style={{ marginTop: '40px', width: '412px' }}
      />
    </Container>
  );
}

function PasswordReseter() {
  const navigate = useNavigate();
  const [recoilUserdata, setReoilUserdata] = useRecoilState(userInfoState);
  const [forcedChange, setForecedChange] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showResetPassword, setShowResetPassword] = useRecoilState(
    showResetPasswordState
  );
  const [apiError, setApiError] = useState(null);
  const [passwordChangeState, setPasswordChangeState] = useState('waiting');
  const [showProcessing, setShowProcessing] = useState(false);
  const formElm = useRef(null);
  const auth0Settings = ENV.OAUTH2;
  const isAuth0 = auth0Settings.organization !== undefined;

  const {
    register,
    getValues,
    clearErrors,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({ delayError: 250, mode: 'onChange' });

  const doAuthentication = useMutation(changePassword, {
    onSuccess: (data) => {
      const { token } = data;
      // generate api token
      let apiToken = null;
      if ('hospital_id' in userInfo && 'id' in userInfo) {
        apiToken = Buffer.from(
          `${userInfo.hospital_id}.${userInfo.id}:${token}`
        ).toString('base64');
      }
      const nextUserData = { ...userInfo, token, apiToken };
      // set global state
      setReoilUserdata(nextUserData);
      // set new cookies in the browser
      nextUserData.cookies && setCookies(nextUserData.cookies, nextUserData);

      setPasswordChangeState('success');
      setTimeout(() => {
        setShowProcessing(false);
        setShowResetPassword(false);
        reset();
        if (forcedChange) {
          navigate('/', { replace: true });
        }
      }, 1200);
    },
    onError: (error) => {
      if (typeof error.message === 'string') {
        const { resData } = JSON.parse(error.message);
        setApiError(resData.message);
      }
      setPasswordChangeState('failed');
      setTimeout(() => {
        setShowProcessing(false);
      }, 1200);
    }
  });

  const onSubmit = (formData) => {
    if (forcedChange) {
      // set the user info in recoil so that we can use it in the api call
      // We want to keep the user info empty so the user can not navagate away from the page authenticated
      setReoilUserdata({
        id: userInfo.id,
        username: userInfo.username,
        apiToken: userInfo.apiToken
      });
    }
    // show processing modal
    setShowProcessing(true);
    // remove any previous errors
    setApiError(null);
    // set the password change state to waiting for the processing modal.
    setPasswordChangeState('waiting');
    // call the api
    doAuthentication.mutate({
      newpass: formData.password.newpass,
      oldpass: formData.password.oldpass
    });
  };

  const onCancle = () => {
    reset();
    setUserInfo(null);
    setApiError(null);
    setShowResetPassword(false);
  };

  const validatePasswordRules = (value) => {
    setApiError(null);
    let matchCount = 0;
    const hasNumbers = /[0-9]+/;
    const hasUppers = /[A-Z]+/;
    const hasLowers = /[a-z]+/;
    const hasSymbols = /[-!$%^&*()_+|~=`{}[\]:"";'<>?,./]+/;

    matchCount += hasNumbers.test(value) ? 1 : 0;
    matchCount += hasUppers.test(value) ? 1 : 0;
    matchCount += hasLowers.test(value) ? 1 : 0;
    matchCount += hasSymbols.test(value) ? 1 : 0;

    if (matchCount < 3) {
      return 'New Password is not strong enough. See password requirements above.';
    }
    return true;
  };

  const valideateAgainstOldPassword = (fieldName) => {
    setApiError(null);
    const { newpass, oldpass } = getValues('password');
    if (newpass === oldpass) {
      return 'New Password cannot be the same as your old password.';
    }

    return true;
  };

  const validateAginstOtherPasswords = (fieldName) => {
    setApiError(null);
    const { newpass, confirmpass } = getValues('password');
    if (newpass !== confirmpass) {
      return 'New and Verify Passwords do not match!';
    }

    return true;
  };

  const validateSpaces = (value) => {
    setApiError(null);
    if (/\s/.test(value)) {
      return 'New Password cannot contain whitespace.';
    }
    return true;
  };

  const valideRequired = (value, feildName) => {
    if (!value) return `${feildName} is required.`;
    return true;
  };

  const inputRegistration = {
    maxLength: {
      value: 20,
      message: 'New Password must be less than 20 characters in length.'
    },
    minLength: {
      value: 8,
      message: 'New Password must be more than 8 characters in length.'
    }
  };

  const formErrors = Object.keys(errors?.password || {}).reduce(
    (arr, k) => {
      const e = errors.password[k];
      const [repeaterCheck, messages] = arr;
      if (!repeaterCheck.includes(e.message)) {
        return [[...repeaterCheck, e.message], { ...messages, [k]: e.message }];
      }
      return arr;
    },
    [[], {}]
  )[1];

  useEffect(() => {
    // if password has expired we do not store the user info in local storage so the user can't navigate away from the page authenticated
    // I pass the user info in the showResetPasswordState so that we can use it here.
    // in the case of a password reset from the user profile page we will have the user info in recoil and showRestPassword will be a bool.
    if (showResetPassword) {
      if (typeof showResetPassword === 'object' && userInfo === null) {
        setUserInfo(showResetPassword);
        setForecedChange(true);
      } else if (Object.keys(recoilUserdata || {}).length > 1) {
        setUserInfo(recoilUserdata || {});
      } else if (typeof showResetPassword === 'boolean') {
        setForecedChange(false);
        setUserInfo(recoilUserdata);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResetPassword]);

  if (isAuth0) {
    return <Auth0PasswordReset />;
  }

  return (
    <Container>
      <ConfirmModal
        hideFooter
        isShowing={showResetPassword}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCancle}
        title="Change Password"
        message={
          <>
            <div style={{ padding: '20px' }}>
              Your new password must be between 8 and 20 characters long and
              contain at least <strong>3</strong> of the following:
              <ListContainer>
                <ul>
                  <li>Uppercase letters</li>
                  <li>Lowercase letters</li>
                  <li>Numbers</li>
                  <li>Special characters</li>
                </ul>
              </ListContainer>
              <FormContainer grow="1" direction="column">
                {Object.keys(formErrors).length > 0 && (
                  <Flex direction="column" style={{ height: '60px' }}>
                    {
                      Object.keys(formErrors).map((key) => (
                        <ErrorMessage key={key}>{formErrors[key]}</ErrorMessage>
                      ))[0]
                    }
                  </Flex>
                )}
                {apiError && (
                  <Flex direction="column">
                    <Error
                      errorMessage={apiError}
                      showIcon
                      visible={!!apiError}
                    />
                  </Flex>
                )}
                <form onSubmit={handleSubmit(onSubmit)} ref={formElm}>
                  <Flex direction="column" grow="1" gap="18px">
                    <Flex direction="column" grow="1">
                      <FormLabel label="Old Password" name="oldpass" />
                      <FormInput
                        type="password"
                        id="old-pass"
                        minlength="8"
                        maxLength="20"
                        {...register('password.oldpass', {
                          ...inputRegistration,
                          validate: {
                            valideRequired: (value) =>
                              valideRequired(value, 'Old Password'),
                            valideateAgainstOldPassword: () =>
                              valideateAgainstOldPassword('password.oldpass')
                          }
                        })}
                        error={Object.keys(errors?.password || {}).includes(
                          'oldpass'
                        )}
                        onChange={() => clearErrors()}
                      />
                    </Flex>
                    <Flex direction="column" grow="1">
                      <FormLabel label="New Password" name="newpass" />
                      <FormInput
                        type="password"
                        id="new-pass"
                        minlength="8"
                        maxLength="20"
                        {...register('password.newpass', {
                          ...inputRegistration,
                          validate: {
                            valideRequired: (value) =>
                              valideRequired(value, 'New Password'),
                            valideateAgainstOldPassword: () =>
                              valideateAgainstOldPassword('password.newpass'),
                            validateAginstOtherPasswords: () =>
                              validateAginstOtherPasswords('password.newpass'),
                            validatePasswordRules,
                            validateSpaces
                          }
                        })}
                        error={Object.keys(errors?.password || {}).includes(
                          'newpass'
                        )}
                        onChange={() => clearErrors()}
                      />
                    </Flex>
                    <Flex direction="column" grow="1">
                      <FormLabel
                        label="Confirm New Password"
                        name="confirmpass"
                      />
                      <FormInput
                        type="password"
                        id="confirm-pass"
                        minlength="8"
                        maxLength="20"
                        {...register('password.confirmpass', {
                          ...inputRegistration,
                          validate: {
                            valideRequired: (value) =>
                              valideRequired(value, 'Confirm Password'),
                            doValidation: validatePasswordRules,
                            validateAginstOtherPasswords: () =>
                              validateAginstOtherPasswords(
                                'password.confirmpass'
                              )
                          }
                        })}
                        error={Object.keys(errors?.password || {}).includes(
                          'confirmpass'
                        )}
                        onChange={() => clearErrors()}
                      />
                    </Flex>
                  </Flex>
                </form>
              </FormContainer>
            </div>
            <ModalFooter
              confirmText="Change Password"
              cancelText=""
              onPrimayClick={() => {
                formElm.current.requestSubmit();
              }}
            />
          </>
        }
        style={{ marginTop: '40px', width: '512px' }}
      />
      <PasswordVerificationModal
        isShowing={showProcessing}
        setShowing={setShowProcessing}
        passwordChangeState={passwordChangeState}
      />
    </Container>
  );
}

export default PasswordReseter;
