import {
  Children,
  cloneElement,
  useState,
  useRef,
  useEffect,
  useCallback
} from 'react';
import useModalButtonClick from '@Components/NeosomaUI/Modal/useModalButtonClicked';
import InlineSpinner from '@Components/NeosomaUI/InlineSpinner/InlineSpinner';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';
import { Button } from '../Button';
import Flex from '../Flex';
import { addAlpha } from '../helpers';
import Typography from '../Typography';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme, obscureBackground }) =>
    obscureBackground ? '#0a182ff2' : theme.palette.blackpearl};
  opacity: ${({ obscureBackground }) => (obscureBackground ? 1 : 0.5)};
  backdrop-filter: ${({ obscureBackground }) =>
    obscureBackground ? 'blur(4px)' : 'blur(0px)'};
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

export const ModalInner = styled.div`
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 2px 2px 4px 0px
    ${({ theme }) => addAlpha(theme.palette.black, 0.25)};
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 560px;
`;

const ModalHeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.antiflashwhite};
  padding: 17px 21px;
`;

const ModalBody = styled.div`
  padding: ${({ hideFooter }) => (hideFooter ? '' : '17px 21px')};
`;

const ActionRow = styled(Flex)`
  background: ${({ theme }) => theme.palette.antiflashwhite};
  padding: 17px 21px;
  border-radius: 0 0 3px 3px;
`;

const CloseButton = styled.button`
  color: ${({ theme }) => theme.palette.shuttleGray};
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
`;

// *----------------------------*
// |  COMPONENT :: ModalFooter  |
// *----------------------------*

function ModalFooter({
  isCancelDisabled,
  onPrimayClick,
  onCancleClick,
  isPrimaryDisabled,
  confirmText = 'Save',
  cancelText = 'Cancel',
  spinnerText = 'Processing...',
  primaryNotSubmit
}) {
  return (
    <ActionRow gap="12px" justifyContent="flex-end">
      {cancelText && (
        <Button
          type="button"
          variant="primary-inverse"
          onClick={onCancleClick}
          disabled={isCancelDisabled}
        >
          {cancelText}
        </Button>
      )}
      <Button
        type={primaryNotSubmit ? 'button' : 'submit'}
        variant="primary"
        onClick={onPrimayClick}
        disabled={isPrimaryDisabled}
      >
        {isPrimaryDisabled === 'showSpinner' && (
          <InlineSpinner singelLoader style={{ marginRight: '8px' }} />
        )}
        {isPrimaryDisabled !== 'showSpinner' ? confirmText : spinnerText}
      </Button>
    </ActionRow>
  );
}

ModalFooter.propTypes = {
  onCancleClick: PropTypes.func,
  isCancelDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onPrimayClick: PropTypes.func,
  isPrimaryDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  spinnerText: PropTypes.string,
  primaryNotSubmit: PropTypes.bool
};

ModalFooter.defaultProps = {
  onCancleClick: () => {
    /* NoOp */
  },
  isCancelDisabled: false,
  onPrimayClick: () => {
    /* NoOp */
  },
  isPrimaryDisabled: false,
  confirmText: 'Save',
  cancelText: 'Cancel',
  spinnerText: 'Processing...',
  primaryNotSubmit: false
};

// *----------------------------*
// |  COMPONENT :: ModalHeader  |
// *----------------------------*
export function ModalHeader({
  title,
  onCancleClick,
  isCloseDisabled,
  hideHeaderCloseButton
}) {
  return (
    <ModalHeaderContainer justifyContent="space-between">
      <Typography text="azure" variant="h1" bold>
        {title}
      </Typography>
      {!hideHeaderCloseButton && (
        <CloseButton
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCancleClick}
          disabled={isCloseDisabled}
        >
          <FaTimes size="20" />
        </CloseButton>
      )}
    </ModalHeaderContainer>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.string,
  onCancleClick: PropTypes.func,
  isCloseDisabled: PropTypes.bool,
  hideHeaderCloseButton: PropTypes.bool
};

ModalHeader.defaultProps = {
  title: '',
  onCancleClick: () => {
    /* NoOp */
  },
  isCloseDisabled: false,
  hideHeaderCloseButton: false
};

// *----------------------*
// |  COMPONENT :: Modal  |
// *----------------------*
function Modal({
  isShowing,
  hide = () => {},
  title,
  children,
  onPrimaryClick,
  onCancelClick,
  buttonText,
  cancelText,
  spinnerText,
  hideFooter,
  obscureBackground,
  hideHeaderCloseButton,
  externalDisablePrimary,
  disablePrimary,
  disableButtons,
  style
}) {
  const isShowingModal = useRef(undefined);
  const [buttonClicked, setButtonClicked] = useState(undefined);
  const [isPrimaryDisabled, setIsPrimaryDisabled] = useState(undefined);
  const [isCancelDisabled, setIsCancelDisabled] = useState(undefined);
  const [isCloseDisabled, setIsCloseDisabled] = useState(undefined);

  const nextPrimaryClick = (e) => {
    setButtonClicked(buttonClicked ? buttonClicked + 1 : 1);
    if (onPrimaryClick) onPrimaryClick(e);
  };

  const handleHide = useCallback(() => {
    onCancelClick();
    hide();
    setButtonClicked(undefined);
  }, [hide, onCancelClick]);

  // For the modal the child componenents are passed the following props:
  // usePrimaryClicked: Allows the child component to respond to the primary button being clicked.
  // disablePrimary: Allows the child component to disable the primary button.
  // disableCancel: Allows the child component to disable the cancel button.
  // disableClose: Allows the child component to disable the close button.
  // disableButtons: Allows the child component to disable all buttons.
  // dismissModal: Allows the child component to dismiss the modal.
  const newChildren = Children.map(children, (child) =>
    cloneElement(child, {
      usePrimaryClicked: (fnc) => useModalButtonClick(fnc, buttonClicked),
      disablePrimary: (bool) => setIsPrimaryDisabled(bool),
      disableCancel: (bool) => setIsCancelDisabled(bool),
      disableClose: (bool) => setIsCloseDisabled(bool),
      disableButtons: (bool) => {
        setIsPrimaryDisabled(bool);
        setIsCancelDisabled(bool);
        setIsCloseDisabled(bool);
      },
      dismissModal: handleHide
    })
  );

  const modalEml = useRef(null);
  const isPrimaryDisabledRef = useRef(isPrimaryDisabled);
  useEffect(() => {
    const modal = modalEml.current;
    if (!modal) return () => {};
    const keyPressHandler = (e) => {
      if (e.key === 'Escape') {
        !(hideHeaderCloseButton || hideFooter) && cancelText && handleHide();
        !isCancelDisabled && !hideHeaderCloseButton && handleHide();
      }
      if (
        e.key === 'Enter' &&
        e.target.type !== 'textarea' &&
        !isPrimaryDisabledRef.current
      ) {
        setTimeout(() => nextPrimaryClick(e), 50);
      }
      if (
        e.key === 'Enter' &&
        e.shiftKey &&
        e.target.type === 'textarea' &&
        !isPrimaryDisabledRef.current
      ) {
        e.preventDefault();
        setTimeout(() => nextPrimaryClick(e), 50);
      }
    };

    if (isShowingModal.current === undefined && isShowing) {
      modal?.addEventListener('keyup', keyPressHandler);
      isShowingModal.current = true;
      modal?.focus();
    }

    return () => {
      modal?.removeEventListener('keyup', keyPressHandler);
      isShowingModal.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  useEffect(() => {
    setIsPrimaryDisabled(disablePrimary);
    isPrimaryDisabledRef.current = disablePrimary;
  }, [disablePrimary]);

  useEffect(() => {
    setIsPrimaryDisabled(externalDisablePrimary);
    isPrimaryDisabledRef.current = externalDisablePrimary;
  }, [externalDisablePrimary]);

  useEffect(() => {
    setIsPrimaryDisabled(disableButtons || disablePrimary);
    setIsCancelDisabled(disableButtons);
    setIsCloseDisabled(disableButtons);
  }, [disableButtons, disablePrimary]);

  return isShowing
    ? ReactDOM.createPortal(
        <div ref={modalEml} tabIndex={-1}>
          <ModalOverlay obscureBackground={obscureBackground} />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <ModalInner style={style}>
              <ModalHeader
                title={title}
                onCancleClick={handleHide}
                isCloseDisabled={isCloseDisabled}
                hideHeaderCloseButton={hideHeaderCloseButton}
              />
              <ModalBody hideFooter={hideFooter}>{newChildren}</ModalBody>
              {!hideFooter && (
                <ModalFooter
                  onCancleClick={handleHide}
                  onCancelClick={onCancelClick}
                  isCancelDisabled={isCancelDisabled}
                  onPrimayClick={nextPrimaryClick}
                  isPrimaryDisabled={isPrimaryDisabled}
                  confirmText={buttonText}
                  cancelText={cancelText}
                  spinnerText={spinnerText}
                />
              )}
            </ModalInner>
          </ModalWrapper>
        </div>,
        document.body
      )
    : null;
}
Modal.propTypes = {
  isShowing: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hide: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  onPrimaryClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  buttonText: PropTypes.string,
  cancelText: PropTypes.string,
  spinnerText: PropTypes.string,
  hideFooter: PropTypes.bool,
  obscureBackground: PropTypes.bool,
  hideHeaderCloseButton: PropTypes.bool,
  externalDisablePrimary: PropTypes.bool,
  disablePrimary: PropTypes.bool,
  disableButtons: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string)
};

Modal.defaultProps = {
  isShowing: false,
  hide: () => {
    /* NoOp */
  },
  title: null,
  children: null,
  onPrimaryClick: () => {
    /* NoOp */
  },
  onCancelClick: () => {
    /* NoOp */
  },
  buttonText: 'Save',
  cancelText: 'Cancel',
  spinnerText: 'Processing...',
  hideFooter: false,
  obscureBackground: false,
  hideHeaderCloseButton: false,
  externalDisablePrimary: false,
  disablePrimary: false,
  disableButtons: false,
  style: {}
};

export { ModalFooter };
export default Modal;
