/* eslint-disable consistent-return */
/* eslint-disable camelcase */
const setPasswordChangeEmail = async (username) => {
  const domain = window?.env?.oauth2?.domain;
  const client_id = window?.env?.oauth2?.client_id;
  if (!domain || !client_id) {
    Promise.reject(Error('Missing domain or client_id in window.env.oauth2'));
  }
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      client_id,
      email: username,
      connection: 'Username-Password-Authentication'
    })
  };

  try {
    const response = await fetch(
      `https://${domain}/dbconnections/change_password`,
      options
    );
    if (!response.ok) {
      return Promise.reject(response);
    }
    const data = await response.text();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default setPasswordChangeEmail;
