export const SORT_TYPE = {
  OVERDUE_SCHEDULED_SCAN_1: 1,
  OVERDUE_IMAGE_OR_OVERDUE_SCAN_2: 2,
  NEW_PATIENT_NO_SCANS_3: 3,
  LAST_STUDY_WAS_REVIEWED_4: 4,
  NEW_PATIENT_PENDING_SCANS_5: 5
};

export const {
  OVERDUE_SCHEDULED_SCAN_1,
  OVERDUE_IMAGE_OR_OVERDUE_SCAN_2,
  NEW_PATIENT_NO_SCANS_3,
  LAST_STUDY_WAS_REVIEWED_4,
  NEW_PATIENT_PENDING_SCANS_5
} = SORT_TYPE;

export const SORT_TYPE_DISPLAY_TEXT = {
  [OVERDUE_SCHEDULED_SCAN_1]: 'Overdue Scheduled Scan',
  [OVERDUE_IMAGE_OR_OVERDUE_SCAN_2]: 'Unreviewed Image',
  [NEW_PATIENT_NO_SCANS_3]: 'New Patient',
  [LAST_STUDY_WAS_REVIEWED_4]: 'Latest study was reviewed',
  [NEW_PATIENT_PENDING_SCANS_5]: 'New Patient'
};

export const PATIENT_LIST_FILTER_TYPES = {
  SORT_TYPE: 'sort_type',
  PINNED: 'pinned',
  NEXT_SCAN_DATE: 'next_scan_date'
};

export const PINNED = 1;
export const UNPINNED = 0;

export const TUMOR_TYPES = {
  GLIOMA: 'Glioma',
  METASTASIS: 'Metastasis'
};

export const TUMOR_TREATMENT_TYPES = {
  CHEMOTHERAPY: 'Chemotherapy',
  CLINICAL_TRIALS: 'Clinical Trials',
  SURGERY: [
    'Surgery',
    {
      CRANIOTOMY: 'Craniotomy',
      MRI_GUIDED_LASER_ABLATION: 'MRI-guided Laser Ablation',
      NEEDLE_BIOPSY: 'Needle Biopsy',
      NEROENDOSCOPY: 'Neuroendoscopy'
    }
  ],
  SURVELILANCE_SCAN: 'Surveillance Scan',
  RADIOTHERAPY: [
    'Radiotherapy',
    {
      FRACTIONATED_STEREOTACTIC: 'Fractionated Stereotactic',
      FRACTIONATED_RADIOSURGERY: 'Fractionated Radiosurgery',
      INTEROPERATIVE_RADIOTHERAPY: 'Intraoperative Radiotherapy'
    }
  ],
  TURMOE_TREATING_FIELDS: 'Tumor Treating Fields'
};

export const TIME_FRAMES = [
  {
    label: '2 Weeks',
    value: '2-weeks'
  },
  {
    label: '4 Weeks',
    value: '4-weeks'
  },
  {
    label: '6 Weeks',
    value: '6-weeks'
  },
  {
    label: '8 Weeks',
    value: '8-weeks'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const DISPOSITION_OPTIONS = [
  {
    label: 'Add Treatment',
    options: [
      {
        label: 'Chemotherapy',
        value: 'Chemotherapy',
        groupedItem: true
      },
      {
        label: 'Clinical Trial',
        value: 'Clinical Trial',
        groupedItem: true
      },
      {
        label: 'Surgery',
        value: 'Surgery',
        groupedItem: true
      },
      {
        label: 'Surveillance Scan',
        value: 'Surveillance Scan',
        groupedItem: true
      },
      {
        label: 'Radiotherapy',
        value: 'Radiotherapy',
        groupedItem: true
      },
      {
        label: 'Tumor Treating Field',
        value: 'Tumor Treating Field',
        groupedItem: true
      }
    ]
  },
  {
    label: 'Continue Treatment Plan',
    value: 'Continue Treatment Plan'
  },
  {
    label: 'Snooze Patient',
    value: 'Snooze Patient'
  }
];

export const DISPOSITION_EVENT_TYPES = [
  { label: 'New Scan', value: 'New Scan' },
  { label: 'Patient Visit', value: 'Patient Visit' },
  { label: 'Prior Scan', value: 'Prior Scan' }
];

export const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(
        `${encodeURIComponent(p?.trim())}=${encodeURIComponent(obj[p]?.trim())}`
      );
    }
  return str.join('&');
};

export const TUMOR_EVENTS = {
  IMAGES: 'Images',
  REVIEW: 'Review',
  TREATMENT: 'Treatment',
  TESTING: 'Testing',
  TUMOR_BOARD: 'Tumor Board'
};

export const TUMOR_LOCATIONS = {
  RFRONTAL: 'Frontal (Right)',
  LFRONTAL: 'Frontal (Left)',
  RPARIETAL: 'Parietal (Right)',
  LPARIETAL: 'Parietal (Left)',
  ROCCIPITAL: 'Occipital (Right)',
  LOCCIPITAL: 'Occipital (Left)',
  RTEMPORAL: 'Temporal (Right)',
  LTEMPORAL: 'Temporal (Left)',
  RCEREBELLUM: 'Cerebellum (Right)',
  LCEREBELLUM: 'Cerebellum (Left)',
  CEREBELLUM_MIDLINE: 'Cerebellum (Midline)',
  MEDULLA: 'Medulla',
  MIDBRAIN: 'Midbrain',
  PONS: 'Pons'
};

export const TUMOR_GRADES = {
  ONE: 'I',
  TWO: 'II',
  THREE: 'III',
  FOUR: 'IV'
};

export const TUMOR_PROFILES = {
  IDH: 'IDH-wildtype',
  TERT: 'TERT promoter',
  CHR: 'Chromosomes 7/10',
  EGFR: 'EGFR'
};

export const TUMOR_RESPONSE = {
  CR: 'CR - Complete Response',
  PR: 'PR - Partial Response',
  SD: 'SD - Stable Disease',
  PD: 'PD - Progressive Disease',
  MR: 'MR - Minor Response'
};

export const TUMOR_TYPES_OPTIONS = [
  { label: '--', value: '', name: '--' },
  ...Object.values(TUMOR_TYPES).map((t) => ({
    label: t,
    value: t,
    name: t
  }))
];

export const TUMOR_GRADES_OPTIONS = [
  { label: '--', value: '', name: '--' },
  ...Object.values(TUMOR_GRADES).map((t) => ({
    label: t,
    value: t,
    name: t
  }))
];

export const TUMOR_PROFILES_OPTIONS = [
  { label: '--', value: '', name: '--' },
  ...Object.values(TUMOR_PROFILES).map((t) => ({
    label: t,
    value: t,
    name: t
  }))
];

export const TUMOR_LOCATIONS_OPTIONS = Object.values(TUMOR_LOCATIONS).map(
  (t) => ({
    label: t,
    value: (() => {
      if (t.includes('Right')) {
        return `Right ${t.split(' ')[0]}`;
      }
      if (t.includes('Left')) {
        return `Left ${t.split(' ')[0]}`;
      }
      return t;
    })()
  })
);

export const TUMOR_RESPONSE_OPTIONS = Object.values(TUMOR_RESPONSE).map(
  (t) => ({
    label: t,
    value: (() => t.split(' - ')[1])()
  })
);

export const PASSWORD_EXIPRE_TIME = 60 * 60 * 24 * 90 * 1000; // 90 days

export const bringSanityToMimeType = (mimeType) => {
  let nextMimeType = (mimeType || '').replace(/vnd.*(\.\w+$)/, 'vnd$1');
  if (nextMimeType.length > 32) {
    nextMimeType = 'application/octet-stream';
  }
  return nextMimeType;
};

export const ONE_TIME_ERROR_CODES = {
  TERMINAL_REDIRECT_ERROR:
    'This is unexpected... We cannot redirect you to the proper login page. Please contact Neosoma Support.',
  UNEXPECTED_REDIRECT_ERROR:
    'Something unexpected happened during the redirect. Please contact support if you continue having trouble logging in.'
};
