import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import RecoilOutside from 'recoil-outside';
import { Auth0Provider } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { FeatureFlagsRoot } from '@Utils/FeatureFlags/';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';

import { ENV } from '@Utils/envTools';
import {
  defaultTheme,
  Provider as ThemeProvider
} from '@Components/NeosomaUI/theme';
import App from './components/App';

if (process.env.NODE_ENV === 'development') {
  // example from https://mswjs.io/docs/getting-started/integrate/browser
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  // worker.start();
  // eslint-disable-next-line no-underscore-dangle
  window.__mswStop = worker.stop;
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />)
);

const waitForEnv = () =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Timeout: ENV is still undefined after 500ms'));
    }, 500);
    const checkEnv = () => {
      if (
        // TODO: I don't think I like the prefix "redirect" for this...
        window.location.host.startsWith('redirect') ||
        (window.env !== undefined && ENV.CLIENT_ID !== undefined)
      ) {
        clearTimeout(timeoutId);
        resolve();
      } else {
        setTimeout(checkEnv, 10);
      }
    };

    checkEnv();
  });

// Note: this cookieDomain isn't actually used by the Auth0Provider, because
// the creation of the "new _auth0Js.default.WebAuth(...)" instance in the "p2_api.js" file
// doesn't pass the "cookieDomain" option. We're altering that file to hard code this same
// split function using a "sed" command during the prebuild step.
const cookieDomain = `.${(ENV.OAUTH2?.domain || '')
  .split('.')
  .slice(-2)
  .join('.')}`;

const renderApp = () => {
  root.render(
    <Auth0Provider
      domain={ENV.OAUTH2?.domain}
      clientId={ENV.OAUTH2?.client_id}
      cookieDomain={cookieDomain}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth/callback`,
        organization: ENV.OAUTH2?.organization,
        audience: ENV.OAUTH2?.audience
      }}
    >
      <Helmet
        defaultTitle={defaultTheme.metaTitle}
        titleTemplate={`${defaultTheme.metaTitle} :: %s`}
      />
      <RecoilRoot>
        <RecoilOutside />
        <ThemeProvider>
          <FeatureFlagsRoot>
            <RouterProvider router={router} />
          </FeatureFlagsRoot>
        </ThemeProvider>
      </RecoilRoot>
    </Auth0Provider>
  );
};

waitForEnv().then(renderApp).catch(renderApp);
