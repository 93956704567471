/* eslint-disable no-unused-vars */
import { useState, forwardRef, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  RiPushpinFill,
  RiPushpinLine,
  RiDeleteBin7Fill,
  RiDownloadFill
} from 'react-icons/ri';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Document, Thumbnail } from 'react-pdf';
import { saveAs } from 'file-saver';

import { ENV } from '@Utils/envTools';
import { PINNED, UNPINNED } from '@Utils/generalConstants';
import pinAttachment from '@Utils/api/pinAttachment';
import deleteAttachment from '@Utils/api/deleteAttachment';
import { Flex, Typography, ConfirmModal } from '@Components/NeosomaUI';
import ActionMenu from '@Components/NeosomaUI/ActionMenu';
import { selectedPatientState, cookieRefreshState } from '@Utils/atoms';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import CookieImg from '@Components/CookieImg';
import getAuthCookies from '@Utils/api/getAuthCookies';

import { defaultTheme } from '@Components/NeosomaUI/theme';
import { BsFileEarmarkX } from 'react-icons/bs';
import csvIcon from './doctype_csv.png';
import docIcon from './doctype_doc.png';
import docxIcon from './doctype_docx.png';
import pptIcon from './doctype_ppt.png';
import pptxIcon from './doctype_pptx.png';
import rtfIcon from './doctype_rtf.png';
import txtIcon from './doctype_txt.png';
import unknownIcon from './doctype_unknown.png';

import { getExtentionFromMimeType, isRenderableImage } from './helpers';

const iconMap = {
  csv: csvIcon,
  docx: docxIcon,
  doc: docIcon,
  txt: txtIcon,
  ppt: pptIcon,
  pptx: pptxIcon,
  rtf: rtfIcon,
  tif: unknownIcon
};

const AttachmentCard = styled.div`
  width: ${({ width }) => `${width}px`};
  background-color: ${({ theme }) => theme.palette.antiflashwhite};
  border-radius: 2px;
  display: flex;
  position: relative;
  flex-direction: column;
  @media (max-width: 1439px) {
    width: ${({ responsiveWidth }) => `${responsiveWidth}px`};
  }
  .hidden {
    position: fixed;
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  }

  .pdfAttachment {
    width: 100%;
    height: 100%;
    .react-pdf__message--error,
    .react-pdf__Thumbnail {
      width: 100%;
      height: 100%;
    }
    & .react-pdf__Thumbnail {
      cursor: default;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const AttacmentContentContainer = styled.div`
  display: flex;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palette.submarine};
`;

const Footer = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.white};
  padding: 8px 11px;
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top: 0;
`;

const NoWrapText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AttachmentType = styled.div`
  padding: 2px 8px;
  background-color: ${({ theme, type }) =>
    getExtentionFromMimeType(type) === 'pdf'
      ? theme.palette.neosomaOrange
      : theme.palette.azure};
  position: absolute;
  top: 10px;
`;

const Pinned = styled(RiPushpinFill)`
  position: absolute;
  top: 10px;
  color: ${({ theme }) => theme.palette.mineshaft};
  right: 10px;
  border: 1px solid ${({ theme }) => theme.palette.dovegray};
  background-color: ${({ theme }) => theme.palette.white};
`;

const FooterTitle = styled(Flex)`
  width: 90%;
  min-width: 55px;
`;

const Preparing = styled.div`
  position: absolute;
  right: 2px;
  bottom: 52px;
  width: fit-content;
  font-weight: 600;
  padding: 2px 4px;
  line-height: 1;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 3px;
  z-index: 1;
`;

const NonImageAttachmentContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  & > img {
    object-fit: contain !important;
    display: flex;
    flex: 1;
    max-height: 65%;
    margin-top: 19%;
    color: ${({ theme }) => theme.palette.azure};
  }
  & > div {
    color: ${({ theme }) => theme.palette.mineshaft};
    font-size: 14px;
    position: absolute;
    bottom: 39%;
    right: 17%;
    text-align: center;
    padding: 0 10px;
    line-height: 19px;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.palette.lightsilver};
    background-color: ${({ theme }) => theme.palette.lotion};
  }
`;

// *-----------------------------------*
// |  COMPONENT :: DropdownItemButton  |
// *-----------------------------------*
const DropdownItemButton = forwardRef(
  ({ onClick, dimissMenu, children, ...props }, ref) => {
    const handleClick = (e) => {
      dimissMenu();
      onClick(e);
    };
    return (
      <button type="button" {...props} onClick={handleClick} forwardedRef={ref}>
        {children}
      </button>
    );
  }
);

DropdownItemButton.defaultProps = {
  children: null,
  onClick: () => {
    /* No Op */
  },
  dimissMenu: () => {
    /* No Op */
  }
};

DropdownItemButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  dimissMenu: PropTypes.func
};

// *---------------------------*
// |  COMPONENT :: Attachment  |
// *---------------------------*
export default function Attachment({
  docId,
  docType,
  docName,
  date,
  mimeType,
  previewImage,
  title,
  isPinned,
  width,
  responsiveWidth,
  patientId,
  hospitalId
}) {
  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const cookieRefreshing = useRecoilValue(cookieRefreshState);
  const [preparingDownload, setPreparingDownload] = useState('');
  const [attachmentBroken, setAttachmentBroken] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const fileURL = `${hospitalId}/${patientId}/_uploads/${previewImage}`;

  const isRenderableFile = useCallback(
    () => isRenderableImage(mimeType),
    [mimeType]
  );

  const upPatientPinState = (newState) => {
    const attachments = selectedPatient[patientId].attachments.map((file) => {
      if (file.id === docId) {
        return {
          ...file,
          pinned: newState ? PINNED : UNPINNED
        };
      }
      return file;
    });
    const changedPin = { ...selectedPatient[patientId], attachments };
    setSelectedPatient({
      ...selectedPatient,
      currentPatient: patientId,
      [patientId]: changedPin
    });
  };

  // *----------*
  // | Handlers |
  // *----------*

  const handlePin = useMutation({
    mutationFn: (newPinState) => {
      upPatientPinState(newPinState);
      return pinAttachment(
        patientId,
        docId,
        docName,
        newPinState ? PINNED : UNPINNED
      );
    },
    onError: (error, newPinState) => {
      upPatientPinState(newPinState);
    }
  });

  const handleDownload = () => {
    // Refresh cookies before download.
    !preparingDownload && setPreparingDownload('Preparing...');
    getAuthCookies({ broadCastRefresh: false }).finally(() => {
      !preparingDownload && setPreparingDownload('');
      saveAs(`${ENV.CONTENT_URL}/${fileURL}`);
    });
  };

  const handleDelete = useMutation({
    mutationFn: async () => {
      setPreparingDownload('Deleting...');
      await deleteAttachment(patientId, docId, previewImage);
    },
    onError: (error) => {
      setPreparingDownload('');
    },
    onSuccess: () => {
      const attachments = selectedPatient[patientId].attachments.filter(
        (file) => file.id !== docId
      );
      const changedData = { ...selectedPatient[patientId], attachments };
      setSelectedPatient({
        ...selectedPatient,
        currentPatient: patientId,
        [patientId]: changedData
      });
      setPreparingDownload('');
    }
  });

  const thisExtention = getExtentionFromMimeType(mimeType) || '';

  const isPDF = getExtentionFromMimeType(mimeType) === 'pdf';
  const isImage = isRenderableFile(mimeType);

  let AttachmentIcon = unknownIcon;
  if (!isImage && iconMap[thisExtention]) {
    AttachmentIcon = iconMap[thisExtention];
  }

  const isUnknowType = AttachmentIcon === unknownIcon;

  return (
    <AttachmentCard width={width} responsiveWidth={responsiveWidth}>
      <ConfirmModal
        isShowing={showConfirmDelete}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
        onConfirm={() => {
          handleDelete.mutate();
          setShowConfirmDelete(false);
        }}
        title="Delete"
        message={
          <Flex direction="column" alignItems="center" gap="16px">
            <FaRegTrashAlt size="32px" />
            <Typography variant="h2">Delete attachment?</Typography>
          </Flex>
        }
        confirmText="Delete"
        style={{ width: '350px' }}
      />
      <AttacmentContentContainer>
        <>
          {isImage && (
            <CookieImg
              filename={fileURL}
              hospitalId={hospitalId}
              options={{ showSpinner: true }}
            />
          )}
          {isPDF && (
            <Document
              file={`${ENV.CONTENT_URL}/${fileURL}`}
              className="pdfAttachment"
              options={{ withCredentials: true }}
              loading={null}
              error={
                <BsFileEarmarkX
                  style={{
                    display: 'flex',
                    flex: '1',
                    height: '100%',
                    padding: '16px',
                    color: '#4d4c4c'
                  }}
                />
              }
            >
              <Thumbnail pageNumber={1} width={width} />
            </Document>
          )}
          {!isImage && !isPDF && (
            <NonImageAttachmentContainer>
              <img src={AttachmentIcon} alt="" />
              {isUnknowType && thisExtention && <div>{thisExtention}</div>}
            </NonImageAttachmentContainer>
          )}
        </>
        {docType && (
          <AttachmentType type={mimeType}>
            <Typography text="white" variant="small">
              {docType}
            </Typography>
          </AttachmentType>
        )}
        {preparingDownload && <Preparing>{preparingDownload}</Preparing>}
        {attachmentBroken && !cookieRefreshing && (
          <BsFileEarmarkX
            style={{
              display: 'flex',
              flex: '1',
              height: '100%',
              padding: '16px',
              color: '#4d4c4c'
            }}
          />
        )}
        {isPinned && <Pinned size="16px" />}
      </AttacmentContentContainer>
      <Footer alignItems="center" justifyContent="space-between">
        <FooterTitle direction="column" title={title}>
          <NoWrapText text="mineshaft" variant="p" bold>
            {title || '--'}
          </NoWrapText>
          <Typography text="mineshaft" variant="small">
            {date}
          </Typography>
        </FooterTitle>
        <ActionMenu>
          <DropdownItemButton
            type="button"
            onClick={() => handlePin.mutate(!isPinned)}
          >
            {isPinned && <RiPushpinLine size="12px" />}
            {!isPinned && <RiPushpinFill size="12px" />}
            &nbsp;&nbsp;
            {isPinned ? 'Unpin' : 'Pin'}
          </DropdownItemButton>
          {userPrivs.DELETE_PATIENT_ATTACHMENT ? (
            <DropdownItemButton
              type="button"
              onClick={() => setShowConfirmDelete(true)}
            >
              <RiDeleteBin7Fill size="12px" />
              &nbsp;&nbsp;Delete
            </DropdownItemButton>
          ) : (
            // TODO: find a better solution.
            <span dataTest="needed to prevent a crash" />
          )}
          <DropdownItemButton type="button" onClick={handleDownload}>
            <RiDownloadFill size="12px" />
            &nbsp;&nbsp;Download
          </DropdownItemButton>
        </ActionMenu>
      </Footer>
    </AttachmentCard>
  );
}

Attachment.propTypes = {
  docId: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  docName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  previewImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isPinned: PropTypes.bool.isRequired,
  width: PropTypes.number,
  responsiveWidth: PropTypes.number,
  patientId: PropTypes.string.isRequired,
  hospitalId: PropTypes.string.isRequired
};

Attachment.defaultProps = {
  width: 114,
  responsiveWidth: 114
};
