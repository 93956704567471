/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { GET } = doFetch.methods;

const postAvatar = async (user_id) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(
    `${uri}/upload?client_id=${hospitalId}&user_id=${user_id}&type=avatar`,
    {
      method: GET,
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      },
      doNotRedirectOnError: true
    }
  );
};
export default postAvatar;
