/* eslint-disable camelcase */
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { getSetting } from '@Utils/userSettings';
import getString from '@Components/strings';
import {
  userInfoState,
  patientListCacheState,
  authRefreshState
} from '@Utils/atoms';
import { useAuth0 } from '@auth0/auth0-react';

import { ENV } from '@Utils/envTools';
import getAuthCookies from '@Utils/api/getAuthCookies';
import { NeosomaError } from '@Utils/common';

const fourMinFortyFiveSec = (4 * 60 + 45) * 1000; // 4 minutes 45 seconds
const idleRecheckSec = 60 * 1000; // 1 minute
const maxIdleTimeout = 20; // 20 * ideleRecheckSec = 20 minutes
const ON = 'true';
const OFF = 'false';
const NOT_SET = 'null';
let userData; // Added to make the scope work with the event listeners below run. Remember me check was not working without this.

function CookieRefresh() {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const resetCache = useResetRecoilState(patientListCacheState);
  const intervalId = useRef(null);
  const cookieIntervalId = useRef(null);
  const idleTimeout = useRef(0);
  const userTimedOut = useRef(false);
  const setUserInfo = useSetRecoilState(userInfoState);
  const setAuthRefresh = useSetRecoilState(authRefreshState);
  const userInfo = useRecoilValue(userInfoState) || {};

  const auth0Settings = ENV.OAUTH2;
  const isAuth0 = auth0Settings.organization !== undefined;

  userData = userInfo;

  const cookieAutoRefesh = {
    get current() {
      return sessionStorage.getItem('cookieAutoRefesh') || 'null';
    },
    set current(val) {
      sessionStorage.setItem('cookieAutoRefesh', val);
    }
  };
  // Call back used by the interval timer to track user idel time.
  function idelTicker() {
    function timerIncrement() {
      idleTimeout.current += 1;
      const userIsidle = idleTimeout.current >= maxIdleTimeout;

      // If user is idle, clear the interval timer and cookie refresh timer.
      // Set global cookieAutoRefesh to false.
      if (userIsidle) {
        resetCache();
        clearInterval(intervalId.current);
        clearInterval(cookieIntervalId.current);
        cookieIntervalId.current = null;
        intervalId.current = null;
        userTimedOut.current = true;
        cookieAutoRefesh.current = OFF;
      }
    }
    // If we have an existing interval clear it as we only want one idel timer at a time.
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    return setInterval(timerIncrement, idleRecheckSec);
  }

  async function refreshAuth0Token() {
    try {
      setAuthRefresh(true);
      const thisToken = await getAccessTokenSilently().catch((error) => {
        throw error;
      });

      const idToken = await getIdTokenClaims();
      const today = new Date();
      const passwordExp = idToken?.neosoma?.password_expiration || today;
      if (today > new Date(passwordExp)) {
        // This will send the user back to the login page with an "unauthorized" message
        throw new NeosomaError(getString('passwordExpiredMessage'));
      }

      // Update userInfo with new token and expiration time
      // This assumes you have a method to update userInfo, adjust as necessary
      setUserInfo((prevUserInfo) => {
        const token_bearer = thisToken || prevUserInfo.token_bearer;
        const thisUserInfo = {
          ...prevUserInfo,
          token_bearer,
          token_expires_at: (idToken?.exp || 0) * 1000
        };
        return thisUserInfo;
      });

      setAuthRefresh(false);
    } catch (error) {
      // This can happen if the user was logged out elsewhere - let's reset their session state
      // and return them to the login page:
      setAuthRefresh(false);
      let authMessage = getString('sessionLogutMessage');
      if (error.type === 'NeosomaError') {
        authMessage = error.message;
      }
      localStorage.removeItem('user');
      navigate('/login', {
        replace: true,
        state: {
          authMessage
        }
      });
    }
  }

  // Call back used by the interval timer refresh the cookies automatically.
  function cookieRefreshTicker() {
    async function cookieRefresh() {
      if (!localStorage.getItem('user')) {
        // Our users session was unexpectedly terminated, so stop trying to refresh
        clearInterval(intervalId.current);
        intervalId.current = null;
        return;
      }
      if (isAuth0) {
        await refreshAuth0Token();
      }
      getAuthCookies({ broadCastRefresh: false, forceRefresh: true });
    }
    if (cookieIntervalId.current !== null) {
      clearInterval(cookieIntervalId.current);
      cookieIntervalId.current = null;
    }

    return setInterval(cookieRefresh, fourMinFortyFiveSec); // 4 minutes 45 seconds
  }

  // clear all tracking timers and set cookieAutoRefesh to null.
  function killTimers() {
    clearInterval(intervalId.current);
    clearInterval(cookieIntervalId.current);
    cookieIntervalId.current = null;
    intervalId.current = null;
    cookieAutoRefesh.current = NOT_SET;
  }

  function hasRememberMe(userId) {
    if (userId) {
      const rememberMe =
        getSetting(userId, 'rememberMe') === undefined
          ? true
          : getSetting(userId, 'rememberMe');
      return rememberMe;
    }
    return false;
  }

  // On mount and cookieAutoRefesh change.
  useEffect(() => {
    // If cookieAutoRefesh is null, start the timer.
    if (cookieAutoRefesh.current === NOT_SET) {
      intervalId.current = idelTicker();
      cookieIntervalId.current = cookieRefreshTicker();
      cookieAutoRefesh.current = ON;
    }

    // If cookieAutoRefesh is on check and start timers up.
    if (cookieAutoRefesh.current === ON) {
      if (!intervalId.current) {
        intervalId.current = idelTicker();
      }
      if (!cookieIntervalId.current) {
        cookieIntervalId.current = cookieRefreshTicker();
      }
    }

    return killTimers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On mount add event user activity listeners.
  useEffect(() => {
    function timerReset(e) {
      idleTimeout.current = 0;
      // check that we have interval timers.
      if (cookieAutoRefesh.current === ON) {
        if (cookieIntervalId.current === null) {
          if (!hasRememberMe(userData.id) && userTimedOut.current) {
            return;
          }
          cookieIntervalId.current = cookieRefreshTicker();
        }
        if (intervalId.current === null) intervalId.current = idelTicker();
      } else if (cookieAutoRefesh.current === OFF) {
        if (!hasRememberMe(userData.id)) {
          return;
        }
        // If cookieAutoRefesh is off and user is idle, start timers.
        intervalId.current = idelTicker();
        cookieIntervalId.current = cookieRefreshTicker();
        cookieAutoRefesh.current = ON;
      }
    }

    window.addEventListener('mousemove', timerReset);
    window.addEventListener('mousedown', timerReset);
    window.addEventListener('keypress', timerReset);
    return () => {
      window.removeEventListener('mousemove', timerReset);
      window.removeEventListener('onclick', timerReset);
      window.removeEventListener('keypress', timerReset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If user Id changes
  useEffect(() => {
    // if no user id, clear timers.
    if (!userInfo.id) {
      killTimers();
    }
    // if we have a user id and cookieAutoRefesh is null, start timers.
    // This is for when the user logs in.
    if (
      userInfo.id &&
      cookieAutoRefesh.current === NOT_SET &&
      intervalId.current === null &&
      cookieIntervalId.current === null
    ) {
      intervalId.current = idelTicker();
      cookieIntervalId.current = cookieRefreshTicker();
      cookieAutoRefesh.current = ON;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [killTimers, userInfo.id]);
  return null;
}

export default CookieRefresh;
