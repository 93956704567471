/* eslint-disable camelcase */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ImPrinter } from 'react-icons/im';
import {
  getAge,
  getSex,
  getDate,
  getScanTimestamp,
  maskPII
} from '@Utils/common';
import { getSegmentationValues, addPriorProprties } from '@Utils/imaging';
import { Flex, Margin, Typography } from '@Components/NeosomaUI';
import { IconButton } from '@Components/IconButton';
import getString from '@Components/strings';
import ImageCard from './ImageCard';
import {
  AnalysisSummaryContainer,
  PatientDataTitle,
  TabTitle,
  StyledTypography,
  StyledSelectMenu,
  ImageCardContainer,
  InnerGraph
} from './AnalysisSummaryStyles';
import AnalysisSummaryTable from './AnalysisSummaryTable';
import EmpiricalResultsGraph from './Graphs/EmpericalResultsGraph';
import logoDarkSVG from '../../assets/login-logo-black.png';

const DEFAULT_IMAGE_TITLES = ['T1c', 'FLAIR', 'Segmentation (T1c)'];

const RESPONSE_TITLES = {
  NAME: {
    title: 'Patient Name',
    match: ['first_name', 'last_name'],
    formatter: maskPII
  },
  MRN: {
    title: 'MRN',
    match: 'patient_iuid',
    formatter: maskPII
  },
  AGE: {
    title: 'Age',
    match: 'dob',
    formatter: getAge
  },
  SEX: {
    title: 'Sex',
    match: 'sex',
    formatter: getSex
  }
};

const ALL_RESPONSE_TITLES = Object.values(RESPONSE_TITLES);

function AnalysisSummaryPatientItem({ title, value, ...props }) {
  return (
    <Flex direction="column" gap="12px" {...props}>
      <PatientDataTitle text="dovegray" uppercase bold>
        {title}
      </PatientDataTitle>
      {value}
    </Flex>
  );
}

AnalysisSummaryPatientItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node
  ]).isRequired
};

export default function AnalysisSummary({ patientData }) {
  const priorElm = useRef(null);
  const getPatientStudy = (studyId) =>
    (patientData.studies || []).find((a) => a.id === studyId);

  const getPatientImage = (studyId, sequence) => {
    // Bust the cache for any "edited" images every 10 seconds
    let filename = `${patientData.hospital_id}/${patientData.id}/${studyId}/`;
    filename += `p${filename.replaceAll('/', '_')}preview`;
    return `${filename}_${sequence}.png?ts=${getScanTimestamp()}`;
  };

  const getStudyDateLabel = (studyId) => {
    switch (studyId) {
      case patientData.latest_study_id: {
        const baseline =
          studyId === patientData.baseline_study_id ? ', Baseline' : '';
        const nadir = studyId === patientData.nadir_study_id ? ', Nadir' : '';
        return <b> {`(Latest${baseline}${nadir})`}</b>;
      }
      case patientData.baseline_study_id: {
        const nadir = studyId === patientData.nadir_study_id ? ', Nadir' : '';
        return <b>{` (Baseline${nadir})`}</b>;
      }
      case patientData.nadir_study_id: {
        const baseline =
          studyId === patientData.baseline_study_id ? ', Baseline' : '';
        return <b>{` (Nadir${baseline})`}</b>;
      }
      default:
        return null;
    }
  };

  const studies =
    (patientData?.studies || []).filter((s) => s.status === 10) || [];

  const dropdownOptions = studies
    .map((study) => ({
      label: (
        <span style={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
          {getDate(study.study_date)}
          {getStudyDateLabel(study.id)}
        </span>
      ),
      value: study.id,
      sorter: study.study_date
    }))
    // Flip sort params from (a, b) to (b, a) to reverse sort
    .sort((a, b) => new Date(b.sorter) - new Date(a.sorter));

  const [selectedStudies, setSelectedStudies] = useState({
    current: dropdownOptions[0]?.value,
    prior: dropdownOptions[Math.min(1, dropdownOptions.length - 1)]?.value
  });

  const handleDropDownChange = (key, id) => {
    setSelectedStudies({
      ...selectedStudies,
      [key]: id
    });
  };

  const handleCurrentMRIChange = (o) => {
    const priorOptions = dropdownOptions.filter(
      (st) => new Date(st.sorter).getTime() <= new Date(o.sorter).getTime()
    );
    const selection = priorOptions[Math.min(1, priorOptions.length - 1)];

    priorElm.current?.setValue(selection);
    setSelectedStudies({
      ...selectedStudies,
      current: o.value,
      prior: selection.value
    });
  };

  const handlePriorMRIChange = (o) => {
    handleDropDownChange('prior', o.value);
  };

  const handlePrint = () => {
    window.print();
  };

  const imageData = (patientData.studies || [])
    .filter((s) => s.status === 10)
    .map((s) => getSegmentationValues(s))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const priorDropdownOptions = dropdownOptions.filter((st) => {
    const currentStudy = getPatientStudy(selectedStudies.current);
    return (
      new Date(st.sorter).getTime() <
      new Date(currentStudy.study_date).getTime()
    );
  });

  const timeStamp = new Date().toLocaleString('en-US');

  if (patientData?.hasDetails && patientData.studies.length === 0) {
    return (
      <Typography variant="h1">No studies found for this patient</Typography>
    );
  }
  return !patientData?.hasDetails ? null : (
    <AnalysisSummaryContainer>
      <TabTitle
        justifyContent="space-between"
        alignItems="center"
        data-test="analysis-summary-title-container"
      >
        <Typography variant="h1" bold>
          Neosoma HGG Report
        </Typography>

        <Flex gap="8px">
          <img
            data-test="logo"
            src={logoDarkSVG}
            alt="Neosoma Logo"
            height="56px"
          />
          <IconButton
            style={{ width: '32px', height: '32px' }}
            icon={<ImPrinter size="20px" />}
            onClick={handlePrint}
          />
        </Flex>
      </TabTitle>
      <Flex justifyContent="space-between" gap="8px">
        {ALL_RESPONSE_TITLES.map((data) => {
          const { title, match, formatter = (a) => a } = data;
          const param2 = match === 'dob' ? patientData.age_type : undefined;
          const matchedData = Array.isArray(match)
            ? match.map((m) => patientData[m]).join(' ')
            : patientData[match];
          return (
            <AnalysisSummaryPatientItem
              key={title}
              title={title}
              value={
                <StyledTypography text="dovegray" bold>
                  {formatter(matchedData, param2)}
                </StyledTypography>
              }
            />
          );
        })}
        <AnalysisSummaryPatientItem
          data-test="analysis-summary-current-study-select-menu"
          title="Current MRI Study"
          value={
            <StyledSelectMenu
              options={dropdownOptions}
              defaultValue={dropdownOptions[0]}
              onChange={handleCurrentMRIChange}
            />
          }
        />
        <AnalysisSummaryPatientItem
          data-test="analysis-summary-current-study-title"
          title="Current MRI Study"
          value={
            <StyledTypography text="dovegray" bold>
              {getDate(getPatientStudy(selectedStudies.current)?.study_date)}
            </StyledTypography>
          }
        />
        <AnalysisSummaryPatientItem
          data-test="analysis-summary-prev-study-select-menu"
          title="Previous MRI Study"
          value={
            <StyledSelectMenu
              externalRef={priorElm}
              options={priorDropdownOptions}
              defaultValue={priorDropdownOptions[0]}
              onChange={handlePriorMRIChange}
            />
          }
        />
        <AnalysisSummaryPatientItem
          data-test="analysis-summary-prev-study-title"
          title="Previous MRI Study"
          value={
            <StyledTypography text="dovegray" bold>
              {getDate(getPatientStudy(selectedStudies.prior)?.study_date)}
            </StyledTypography>
          }
        />
      </Flex>
      {selectedStudies.current && (
        <ImageCardContainer data-test="analysis-summary-image-card-container">
          {['t1c', 'flair', 't1c'].map((sequence, i) => (
            <ImageCard
              // eslint-disable-next-line react/no-array-index-key
              key={`${sequence}-${i}`}
              hospitalId={patientData.hospital_id}
              image={getPatientImage(selectedStudies.current, sequence)}
              overlay={
                i === 2
                  ? getPatientImage(selectedStudies.current, 'seg')
                  : undefined
              }
              title={DEFAULT_IMAGE_TITLES[i]}
              studyId={selectedStudies.current}
              subTitle={getDate(
                getPatientStudy(selectedStudies.current)?.study_date
              )}
              showMenu={false}
            />
          ))}
        </ImageCardContainer>
      )}
      <Margin top="0" />
      <TabTitle data-test="analysis-summary-title">
        <Typography variant="h2" bold>
          Graphical Study
        </Typography>
      </TabTitle>
      <Flex gap="30px" data-test="analysis-summary-empirical-results-graph">
        <InnerGraph>
          <EmpiricalResultsGraph
            data={addPriorProprties(
              imageData
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .filter(
                  (s) =>
                    s.date <=
                    new Date(
                      getPatientStudy(selectedStudies.current)?.study_date
                    ).getTime()
                )
            )}
          />
        </InnerGraph>
      </Flex>
      <Flex
        gap="30px"
        data-test="analysis-summary-empirical-results-graph-print"
        aria-hidden="true" // Hidden because this is print-out only info.
      >
        <InnerGraph>
          <EmpiricalResultsGraph
            data={addPriorProprties(
              imageData
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .filter(
                  (s) =>
                    s.date <=
                    new Date(
                      getPatientStudy(selectedStudies.current)?.study_date
                    ).getTime()
                )
            )}
            extraClass="chart-responsive-container"
          />
        </InnerGraph>
      </Flex>
      <Margin top="0" />
      <TabTitle data-test="analysis-summary-title">
        <Typography variant="h2" bold>
          Report Details
        </Typography>
      </TabTitle>
      <AnalysisSummaryTable
        data={imageData
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .filter(
            (s) =>
              s.date <=
              new Date(
                getPatientStudy(selectedStudies.current)?.study_date
              ).getTime()
          )}
        selectedStudies={selectedStudies}
        patientData={patientData}
      />
      <Flex>
        <p>{getString('analysisSummary.disclaimer')}</p>
        <p data-test="generated-date">Date generated: {timeStamp}</p>
      </Flex>
    </AnalysisSummaryContainer>
  );
}

AnalysisSummary.propTypes = {
  patientData: PropTypes.shape({
    id: PropTypes.string,
    hospital_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    age_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sex: PropTypes.string,
    dob: PropTypes.string,
    diagnosis: PropTypes.string,
    stage: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attending: PropTypes.string,
    referring: PropTypes.string,
    avatar: PropTypes.string,
    molecular_profile: PropTypes.string,
    hpi: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    notes: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.string,
    response: PropTypes.string,
    latest_scan_date: PropTypes.string,
    latest_study_id: PropTypes.string,
    baseline_study_id: PropTypes.string,
    nadir_study_id: PropTypes.string,
    studies: PropTypes.instanceOf(Array),
    hasDetails: PropTypes.bool
  }).isRequired
};
