/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import {
  calculateVolumes,
  calculatePercentChanges,
  dateFormatter,
  ROWS,
  sanitizeValue
} from './AnalysisSummaryHelpers';

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  & td,
  & th {
    padding: 6px 13px;
    border: 1px solid ${({ theme }) => theme.palette.lightsilver};
  }
  & th {
    background-color: ${({ theme }) => theme.palette.zircon};
    color: ${({ theme }) => theme.palette.azure};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
  }
  & td {
    color: ${({ theme }) => theme.palette.mineshaft};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
  .percent-change-cell {
    text-align: end;
  }
  .row-labels {
    background-color: ${({ theme }) => theme.palette.lotion};
    color: ${({ theme }) => theme.palette.mineshaft};
  }

  .cell-top {
    border-top: 2px solid ${({ theme }) => theme.palette.mineshaft};
  }
  .cell-bottom {
    border-bottom: 2px solid ${({ theme }) => theme.palette.mineshaft};
  }
  .cell-top-left {
    border-left: 2px solid ${({ theme }) => theme.palette.mineshaft};
    border-top: 2px solid ${({ theme }) => theme.palette.mineshaft};
  }
  .cell-top-right {
    border-right: 2px solid ${({ theme }) => theme.palette.mineshaft};
    border-top: 2px solid ${({ theme }) => theme.palette.mineshaft};
  }
  .cell-bottom-left {
    border-left: 2px solid ${({ theme }) => theme.palette.mineshaft};
    border-bottom: 2px solid ${({ theme }) => theme.palette.mineshaft};
  }
  .cell-bottom-right {
    border-right: 2px solid ${({ theme }) => theme.palette.mineshaft};
    border-bottom: 2px solid ${({ theme }) => theme.palette.mineshaft};
  }

  .cell-top-left,
  .cell-top-right,
  .cell-bottom-left,
  .cell-bottom-right {
    background-color: ${({ theme }) => theme.palette.honeysuckle};
    color: ${({ theme }) => theme.palette.mineshaft};
  }
  .no-border {
    border: none;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const classMap = {
  enhancing: ' cell-top-right cell-top-left',
  flair: ' cell-bottom-right cell-bottom-left'
};

function AnalysisSummaryTable({ data, selectedStudies, patientData = {} }) {
  if (selectedStudies.current === null && selectedStudies.prior === null) {
    return null;
  }
  const currentIsLatest =
    patientData.latest_study_id === selectedStudies.current;
  const volumes = calculateVolumes(data, selectedStudies, patientData);
  const percentChanges = calculatePercentChanges(volumes);

  const volumeColSpan = volumes.length;
  const percentChangeColSpan = percentChanges.length;

  if (
    data.length > 0 &&
    data[0].date > 0 &&
    data.slice(-1)[0].study_type === 2
  ) {
    delete ROWS.necrosis;
  }

  return (
    <TableContainer data-test="analysis-summary-volume-table">
      <Table>
        <colgroup>
          <col />
        </colgroup>
        <colgroup span={volumeColSpan} />
        <colgroup span={percentChangeColSpan} />
        <tbody>
          <tr>
            <td rowSpan="1" className="no-border" width="10%" />
            <th
              colSpan={volumeColSpan}
              scope="colgroup"
              width={`${
                (90 / (volumeColSpan + percentChangeColSpan)) * volumeColSpan
              }%`}
            >
              Volumes (in cm&sup3;)
            </th>
            <th
              colSpan={percentChangeColSpan}
              scope="colgroup"
              width={`${
                (90 / (volumeColSpan + percentChangeColSpan)) *
                percentChangeColSpan
              }%`}
            >
              {`Percent Change (to ${currentIsLatest ? 'Current' : 'Custom'})`}
            </th>
          </tr>
          <tr>
            <th scope="col">Label</th>
            {volumes.map((rec, idx) => (
              <th
                scope="col"
                key={rec.type}
                width={`${90 / (volumeColSpan + percentChangeColSpan)}%`}
              >
                {rec.type}
                <br />
                {dateFormatter(rec?.date)}
              </th>
            ))}
            {percentChanges.map((rec, idx) => (
              <th
                scope="col"
                key={rec.type}
                width={`${90 / (volumeColSpan + percentChangeColSpan)}%`}
              >
                {rec.type}
                <br />
                {dateFormatter(rec?.date)}
              </th>
            ))}
          </tr>
          {Object.keys(ROWS).map((key) => (
            <tr key={key}>
              <th scope="row" className="row-labels">
                {ROWS[key]}
              </th>
              {volumes.map((rec) => (
                <td key={`${key}-${rec.type}`}>
                  {sanitizeValue(rec[key])} cm&sup3;
                </td>
              ))}
              {percentChanges.map((rec) => (
                <td
                  key={`${key}-${rec.type}`}
                  className={`percent-change-cell${classMap[key] || ''}`}
                >
                  {rec[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      * Whole Tumor = Enhancing + FLAIR
    </TableContainer>
  );
}

export default AnalysisSummaryTable;
