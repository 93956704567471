import { string, oneOfType, node, arrayOf, bool } from 'prop-types';

import flags from './flags';

export function getFeatureFlag(name) {
  const features = JSON.parse(
    localStorage.getItem('flags') || JSON.stringify(flags)
  );
  const feature = features.find((thisFeature) => thisFeature.name === name);

  // Allows user to set session storage to turn on a feature in the browser.
  if (sessionStorage.getItem(name) === 'true') {
    return true;
  }
  if (sessionStorage.getItem(name) === 'false') {
    return false;
  }
  if (feature && feature.active) {
    return true;
  }

  return false;
}

function Feature({ name, isOff, children }) {
  const features = JSON.parse(
    localStorage.getItem('flags') || JSON.stringify(flags)
  );
  const feature = features.find((thisFeature) => thisFeature.name === name);

  // Allows user to set session storage to turn on a feature in the browser.
  if (sessionStorage.getItem(name) === 'true') {
    return isOff ? null : children;
  }
  if (sessionStorage.getItem(name) === 'false') {
    return isOff ? children : null;
  }
  if (feature && feature.active) {
    return isOff ? children : null;
  }

  return isOff ? children : null;
}

Feature.propTypes = {
  name: string.isRequired,
  isOff: bool,
  children: oneOfType([arrayOf(node), node])
};

Feature.defaultProps = {
  isOff: false,
  children: undefined
};

export const FeatureFlagsRoot = ({ children }) => {
  if (!localStorage.getItem('flags')) {
    localStorage.setItem('flags', JSON.stringify(flags));
  } else {
    const features = JSON.parse(localStorage.getItem('flags'));
    if (
      features.length !== flags.length ||
      features.some((f) => !flags.some((ff) => ff.name === f.name))
    ) {
      localStorage.setItem('flags', JSON.stringify(flags));
    }
  }
  return children;
};

FeatureFlagsRoot.propTypes = {
  children: oneOfType([arrayOf(node), node])
};

FeatureFlagsRoot.defaultProps = {
  children: undefined
};

export default Feature;
