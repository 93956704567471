/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Typography from './Typography';

const TabButton = styled.button`
  position: relative;
  padding: 6px 10px;
  border-top: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-right: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-left: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-bottom: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.white : theme.palette.lightsilver};
  background: ${({ theme, selected }) =>
    selected ? theme.palette.white : theme.palette.geyser};
  border-radius: 2px 2px 0 0;
  margin-right: 6px;
  transition: 0.5s all;
  cursor: pointer;
  &:hover:not(:disabled) {
    background: ${({ theme, selected }) =>
      selected ? theme.palette.white : theme.palette.mischka};
  }
  &:disabled {
    cursor: default;
    border-style: dotted;
    & h3 {
      font-weight: 300;
      text-shadow: 1px 1px #fefefe;
      color: ${({ theme }) => theme.palette.bombay};
    }
  }
`;

const TabWrapper = styled.div`
  flex-grow: 1;
  min-width: 900px;
  border: 1px solid ${({ theme }) => theme.palette.lightsilver};
  padding: 16px;
  padding-right: 0;
  border-radius: 0 2px 2px 2px;
  margin-top: -1px;
  background-color: ${({ theme }) => theme.palette.white};
  @media (max-width: 1343px) {
    margin-right: 16px;
  }
`;

export function Tab({ children, style }) {
  return <TabWrapper style={style}>{children}</TabWrapper>;
}

Tab.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

Tab.defaultProps = {
  children: null,
  style: {}
};

function TabTitle({ title, setSelectedTab, selected, disabled }) {
  if (title === undefined) return null;

  return (
    <TabButton
      onClick={setSelectedTab}
      type="button"
      selected={selected}
      disabled={disabled}
    >
      <Typography text={selected ? 'mineshaft' : 'oslogray'} variant="h3" bold>
        {title}
      </Typography>
    </TabButton>
  );
}

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

TabTitle.defaultProps = {
  disabled: false
};

export function Tabs({
  children,
  initialSelectedTab,
  externalSelectedTab,
  setExternalSelectedTab,
  preventTabChange
}) {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  useEffect(() => {
    if (
      externalSelectedTab !== undefined &&
      externalSelectedTab !== selectedTab
    ) {
      setSelectedTab(externalSelectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalSelectedTab]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
      <div>
        {children.map((item, idx) => (
          <TabTitle
            key={item.props?.title}
            title={item.props?.title}
            setSelectedTab={() => {
              if (!preventTabChange) {
                setSelectedTab(idx);
              }
              setExternalSelectedTab(idx);
            }}
            selected={selectedTab === idx}
            disabled={item.props?.disabled}
          />
        ))}
      </div>
      {children[selectedTab]}
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
  initialSelectedTab: PropTypes.number,
  externalSelectedTab: PropTypes.number,
  setExternalSelectedTab: PropTypes.func,
  preventTabChange: PropTypes.bool
};

Tabs.defaultProps = {
  children: null,
  initialSelectedTab: 0,
  externalSelectedTab: 0,
  setExternalSelectedTab: () => {
    /** NO OP */
  },
  preventTabChange: false
};
