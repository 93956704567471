import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from '@emotion/styled';

import {
  Flex,
  Tabs,
  Tab,
  Typography,
  ConfirmModal
} from '@Components/NeosomaUI';

import { getSetting } from '@Utils/userSettings';
import { defaultTab } from '@Components/UserSettings/InputDefaultTab';
import {
  selectedPatientState,
  fullSpinnerState,
  userInfoState,
  unsavedState
} from '@Utils/atoms';
import { PATIENT_PORTAL_TABS as TABS } from '@Utils/defaultConfig';
import getPatientDetail from '@Utils/api/getPatientDetails';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import Imaging from './Components/Imaging';
import AttachmentSidebar from './Components/AttachmentSidebar';
import PatientHeader from './Components/PatientHeader';
import PatientSummary from './Components/PatientSummary';
import PatientTimeLine from './Components/PatientTimeline';
import AnalysisSummary from './Components/AnalysisSummary';
import Attachments from './Attachments';

const DEFAULT_TAB = TABS.map((t) => t.id).indexOf(defaultTab);

const PatientSummaryWrapper = styled(Flex)`
  overflow: auto;
  padding: 69px 16px;
  padding-right: 0;
  padding-bottom: 16px;
  background-color: ${({ theme }) => theme.palette.catskillwhite};
  #root {
    background-color: ${({ theme }) => theme.palette.catskillwhite};
  }
`;

const SummaryTabContainer = styled.div`
  padding: 8px;
  margin-right: 12px;
  gap: 23px;
  display: flex;
  flex-direction: column;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const TabTitle = styled(Typography)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
  margin-bottom: 23px;
`;

// *------------------------------*
// |  COMPONENT :: PatientPortal  |
// *------------------------------*
export default function PatientPortal() {
  const navigate = useNavigate();
  const { id: patientId } = useParams();
  const [patientListState = {}, setPatientListState] =
    useRecoilState(selectedPatientState);
  const userInfo = useRecoilValue(userInfoState);
  const [isLoading, setIsLoading] = useRecoilState(fullSpinnerState);
  const [{ unsaved: unsavedChanges, blocker }, setUnsavedChanges] =
    useRecoilState(unsavedState);
  const sessionTab = (sessionStorage.getItem('prevtab') || '').split(':');
  const userSettingsTab = getSetting(userInfo?.id, 'defultPatientDetailsTab');

  // If the user has a saved tab, use that, otherwise use the default tab
  const preSelectedTab = (() => {
    const hasSavedTab = sessionTab[0] === patientId;
    if (hasSavedTab) {
      return TABS[sessionTab[1]].id;
    }
    if (sessionTab[0] !== patientId) {
      // Remove storage if user navigates to a different patient
      sessionStorage.removeItem('prevtab');
    }
    return userSettingsTab;
  })();

  const tabExists = TABS.map((t) => t.id).indexOf(preSelectedTab) !== -1;
  const prefefedTab = tabExists
    ? TABS.map((t) => t.id).indexOf(preSelectedTab)
    : DEFAULT_TAB;
  const [selectedTab, setSelectedTab] = useState(prefefedTab);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const patientData = patientListState[patientId];

  useEffect(() => {
    if (!patientData || !patientData.hasDetails) {
      setIsLoading(true);
      if (!patientData?.hasDetails) {
        getPatientDetail(patientId).then((data) => {
          const { id } = data || {};

          // If patient id is not found, redirect to home page
          if (!id) {
            setIsLoading(false);
            navigate('/');
            return;
          }
          setIsLoading(false);
          setPatientListState({
            ...patientListState,
            [patientId]: {
              ...patientListState[patientId],
              ...data,
              hasDetails: true
            },
            currentPatient: patientId
          });
        });
      }
    }
    if (blocker && blocker?.state === 'blocked' && blocker?.reset) {
      blocker?.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const beforeUnload = (event) => {
      // Recommended
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    };
    if (unsavedChanges) {
      window.addEventListener('beforeunload', beforeUnload);
    } else {
      window.removeEventListener('beforeunload', beforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [unsavedChanges]);

  const handleTabChangeRequest = (timelineEventType) => {
    let requestedTab = timelineEventType;
    if (isNaN(requestedTab)) {
      // Map the timeline event type to the tab that contains that data.
      const tabMap = {
        Images: 'imaging',
        Treatment: 'patientSummary'
      };
      requestedTab = TABS.map((i) => i.id).indexOf(tabMap[requestedTab]);
    }

    if (requestedTab === undefined || requestedTab === -1) return;

    if (unsavedChanges) {
      setShowUnsavedModal(requestedTab);
      return;
    }

    setSelectedTab(requestedTab);
    // Remember the tab the user was on when they navigate away
    sessionStorage.setItem('prevtab', `${patientId}:${requestedTab}`);
  };

  const handleConfirmUnsaved = () => {
    const tab = showUnsavedModal;
    setUnsavedChanges(false);
    setShowUnsavedModal(false);

    // Check if user is navigating away not changing tabs
    if (typeof tab !== 'boolean') {
      setSelectedTab(tab);
    }
  };
  const handleCancelUnsaved = () => {
    setShowUnsavedModal(false);
  };

  return (
    <Flex direction="column" grow="1">
      <ConfirmModal
        isShowing={showUnsavedModal !== false}
        onCancel={handleCancelUnsaved}
        onConfirm={handleConfirmUnsaved}
        title="Unsaved Changes"
        message={
          <>
            <div>Are you sure you want to leave this tab?</div>
            <div>You have unsaved changes that will be lost.</div>
          </>
        }
        confirmText="Discard and continue"
      />
      {!patientData || isLoading ? (
        <div />
      ) : (
        <>
          <PatientHeader patientData={patientData} />
          <PatientSummaryWrapper gap="16px" grow="1">
            {userPrivs.VIEW_PATIENT_ATTACHMENT && (
              <AttachmentSidebar
                patientId={patientId}
                changeTab={handleTabChangeRequest}
                selectedTab={selectedTab}
              />
            )}
            <TabWrapper data-test="tab-container">
              <Tabs
                initialSelectedTab={prefefedTab}
                externalSelectedTab={selectedTab}
                setExternalSelectedTab={handleTabChangeRequest}
                preventTabChange={unsavedChanges}
              >
                {TABS.map(({ id, displayText }) => {
                  // TODO: decouple the data so this step is not
                  let disabled = false;
                  if (
                    !userPrivs.VIEW_PATIENT_ATTACHMENT &&
                    id === 'attachments'
                  ) {
                    disabled = true;
                  }
                  return (
                    <Tab key={id} title={displayText} disabled={disabled}>
                      <SummaryTabContainer>
                        {
                          {
                            patientSummary: (
                              <PatientSummary
                                patientData={patientData}
                                changeTab={handleTabChangeRequest}
                                setIsUnsaved={setUnsavedChanges}
                              />
                            ),
                            imaging: (
                              <Imaging
                                patientId={patientId}
                                changeTab={handleTabChangeRequest}
                              />
                            ),
                            analysisSummary: (
                              <AnalysisSummary
                                patientData={patientData}
                                changeTab={handleTabChangeRequest}
                              />
                            ),
                            /* dispositions: (  // Removed for MVP NUI-391 DEC-2023
                              <Dispositions
                                patientId={patientId}
                                changeTab={handleTabChangeRequest}
                              />
                            ), */
                            attachments: (
                              <Attachments
                                patientId={patientId}
                                changeTab={handleTabChangeRequest}
                              />
                            )
                          }[id]
                        }
                      </SummaryTabContainer>
                    </Tab>
                  );
                })}
              </Tabs>
            </TabWrapper>
            <PatientTimeLine changeTab={handleTabChangeRequest} />
          </PatientSummaryWrapper>
        </>
      )}
    </Flex>
  );
}
