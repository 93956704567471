import styled from '@emotion/styled';
import { number, func } from 'prop-types';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import {
  Flex,
  DropDown,
  DropdownItem,
  Typography
} from '@Components/NeosomaUI';
import { useEffect } from 'react';

export const DEFAULT_ITEMS_PER_PAGE = 20;

const PaginatorContainer = styled(Flex)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.lightsilver};
  padding: 22px 14px;
  justify-content: space-between;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.dovegray};
  white-space: pre;
`;

const PerPageContainer = styled(Flex)`
  color: ${({ theme }) => theme.palette.mineshaft};
  align-items: center;
`;

const PerPageDropDown = styled(DropDown)`
  color: ${({ theme }) => theme.palette.mineshaft};
  background: ${({ theme }) => theme.palette.antiflashwhite};

  border-color: ${({ theme }) => theme.palette.lightsilver};
  border-radius: 2px;
`;

const PageBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  text-decoration-line: none;
  color: ${({ theme }) => theme.palette.dovegray};

  &:disabled {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.azure};
    text-decoration-line: underline;
    cursor: default;
  }

  &:hover:not([disabled]) {
    text-decoration-line: underline;
    background-color: ${({ theme }) => theme.palette.lightsilver};
  }
`;

const ArrowBtn = styled(PageBtn)`
  cursor: pointer;
  & svg {
    font-size: 15px;
  }
  &:disabled {
    color: ${({ theme }) => theme.palette.dustygray};
  }
`;

const PAGE_OPTIONS = [10, 20, 30, 40];
function Paginator({
  count,
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
  totalItems,
  onPageChange
}) {
  const pages = totalItems
    ? [...Array(Math.ceil(totalItems / rowsPerPage)).keys()]
    : [];
  const hasMoreRight = currentPage > totalItems / rowsPerPage - 1;
  const hasMoreLeft = currentPage === 0;

  useEffect(() => {
    onPageChange({
      page: currentPage,
      count: rowsPerPage
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    onPageChange({
      page: currentPage,
      count: rowsPerPage
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <PaginatorContainer gap="35px" justifyContent="space-between">
      <Flex gap="40px" alignItems="center">
        <Typography bold>{count} Results</Typography>
        <PerPageContainer direction="row">
          Rows per page:{' '}
          <PerPageDropDown buttonText={rowsPerPage} variant="slim" hideOnSelect>
            {PAGE_OPTIONS.map((itemsPerPage) => (
              <DropdownItem
                key={`items-per-page-${itemsPerPage}`}
                onClick={() => setRowsPerPage(itemsPerPage)}
              >
                {itemsPerPage}
              </DropdownItem>
            ))}
          </PerPageDropDown>
        </PerPageContainer>
      </Flex>
      <Flex alignItems="center">
        <ArrowBtn
          type="button"
          disabled={hasMoreLeft}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <FiChevronLeft />
        </ArrowBtn>
        {pages.map((pageNum) => {
          const isActive = currentPage === pageNum;
          return (
            <PageBtn
              key={`page-number-${pageNum}`}
              disabled={isActive}
              type="button"
              onClick={() => setCurrentPage(pageNum)}
            >
              {pageNum + 1}
            </PageBtn>
          );
        })}
        <ArrowBtn
          type="button"
          disabled={hasMoreRight}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <FiChevronRight />
        </ArrowBtn>
      </Flex>
    </PaginatorContainer>
  );
}

Paginator.defaultProps = {
  count: undefined,
  rowsPerPage: DEFAULT_ITEMS_PER_PAGE,
  setRowsPerPage: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  totalItems: undefined,
  onPageChange: () => {}
};

Paginator.propTypes = {
  count: number,
  rowsPerPage: number,
  setRowsPerPage: func,
  currentPage: number,
  setCurrentPage: func,
  totalItems: number,
  onPageChange: func
};

export default Paginator;
