import { useState, useEffect } from 'react';
import { getSetting, setSetting } from '@Utils/userSettings';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '@Utils/atoms';

import { FormLabel } from '@Components/NeosomaUI/Form/Input';
import { SelectMenu, Flex } from '@Components/NeosomaUI';

export const defaultTab = 'imaging';

const OPTIONS = [
  { label: 'Patient Summary', value: 'patientSummary' },
  { label: 'Imaging', value: 'imaging' },
  { label: 'Analysis Summary', value: 'analysisSummary' }
];

function InputDefaultTab() {
  const userInfo = useRecoilValue(userInfoState);
  const [value, setValue] = useState();

  const handleChange = (option) => {
    setValue(option);
    setSetting(userInfo.id, 'defultPatientDetailsTab', option.value);
  };

  useEffect(() => {
    if (userInfo.id) {
      const defultPatientDetailsTab = getSetting(
        userInfo.id,
        'defultPatientDetailsTab'
      );
      // no Setting for this user, use default
      if (defultPatientDetailsTab === undefined) {
        setSetting(userInfo.id, 'defultPatientDetailsTab', defaultTab);
        setValue(OPTIONS.find((o) => o.value === defaultTab));
      }
      const userOption = OPTIONS.find(
        (o) => o.value === defultPatientDetailsTab
      );
      // userOption is a vaild option, use it
      if (userOption) {
        setValue(userOption);
      } else {
        // use default
        setValue(OPTIONS.find((o) => o.value === defaultTab));
      }
    }
  }, [userInfo.id]);

  return (
    <Flex grow="1" direction="column" gap="7px">
      <FormLabel label="Start Tab: Patient Details" />
      <SelectMenu options={OPTIONS} value={value} onChange={handleChange} />
    </Flex>
  );
}

export default InputDefaultTab;
