/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex } from '@Components/NeosomaUI';
import { addAlpha } from '@Components/NeosomaUI/helpers';

// Constants.
export const ICON_BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  DARK: 'dark',
  ACTIVE: 'active'
};

// Styles.
const StyledButton = styled.button`
  -webkit-appearance: none;
  width: 26px;
  height: 26px;
  cursor: pointer;
  transition: 0.5s all;
`;

const PrimaryStyle = ({ palette }) => css`
  background-color: ${palette.brightgray};
  border: 1px solid ${palette.lightsilver};
  color: ${palette.mineshaft};
  &:hover {
    background-color: ${palette.lightsilver};
  }

  &:focus {
    outline: 0;
  }
`;

const DarkStyle = ({ palette }) => css`
  color: ${palette.white};
  background-color: ${palette.shuttlegray};
  border: 0;
  &:focus {
    outline: 0;
  }
`;

const ActiveStyle = ({ palette }) => css`
  color: ${palette.blaze};
  background-color: ${addAlpha(palette.blaze, 0.1)};
  border: 1px solid ${addAlpha(palette.blaze, 0.5)};
  &:hover {
    background-color: ${addAlpha(palette.blaze, 0.2)};
  }
  &:focus {
    outline: 0;
  }
`;

export const IconButton = forwardRef(
  ({ icon, variant, onClick, size, ...props }, ref) => {
    let variantStyle;
    switch (variant) {
      case ICON_BUTTON_VARIANTS.PRIMARY:
        variantStyle = PrimaryStyle;
        break;
      case ICON_BUTTON_VARIANTS.DARK:
        variantStyle = DarkStyle;
        break;
      case ICON_BUTTON_VARIANTS.ACTIVE:
        variantStyle = ActiveStyle;
        break;
      default:
        variantStyle = PrimaryStyle;
    }
    return (
      <StyledButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        css={variantStyle}
        ref={ref}
        onClick={onClick}
        size={size}
      >
        <Flex alignItems="center" justifyContent="center">
          {icon}
        </Flex>
      </StyledButton>
    );
  }
);

IconButton.propTypes = {
  variant: PropTypes.oneOf(Object.values(ICON_BUTTON_VARIANTS)),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.string
};

IconButton.defaultProps = {
  variant: ICON_BUTTON_VARIANTS.PRIMARY,
  icon: null,
  onClick: null,
  size: null
};
