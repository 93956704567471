/* eslint-disable camelcase */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import mimeDB from 'mime-db';

import Feature from '@Utils/FeatureFlags';
import { FiPlus, FiDownload, FiX } from 'react-icons/fi';
import { RiPushpinFill } from 'react-icons/ri';
import { BsList, BsGridFill } from 'react-icons/bs';

import {
  Flex,
  Typography,
  SelectMenu,
  useModal,
  Modal
} from '@Components/NeosomaUI';
import { selectedPatientState } from '@Utils/atoms';
import Table from '@Components/NeosomaUI/Table/Table';
import { IconButton } from '@Components/IconButton';
import { getSlashedDate } from '@Utils/common';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import { bringSanityToMimeType } from '@Utils/generalConstants';
import Attachment from './Components/Attachments/Attachment';
import AddAttachment from './Components/Attachments/AddAttachment';

const neoMimeDB = Object.keys(mimeDB).reduce((objt, key) => {
  const newKey = bringSanityToMimeType(key);
  return {
    ...objt,
    [newKey]: mimeDB[key]
  };
}, {});

const StyledPinned = styled(RiPushpinFill)`
  color: ${({ theme }) => theme.palette.blaze};
  transition: 0.5s all;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.mineshaft};
  }
`;

const StyledUnpinned = styled(RiPushpinFill)`
  color: ${({ theme }) => theme.palette.antiflashwhite};
  transition: 0.5s all;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.blaze};
  }
`;

const ALL_ATTACHMENTS = 'all';

const DEFAULT_ATTACHMENT_OPTION = {
  label: 'All',
  value: 'all'
};

const ALL_OPTION = {
  label: 'All',
  value: 'all'
};

const ATTACHMENT_FILTER_OPTIONS = [
  DEFAULT_ATTACHMENT_OPTION,
  { label: 'Pinned', value: 'pinned' },
  {
    label: 'Pathology',
    value: 'pathology'
  },
  {
    label: 'Radiology',
    value: 'radiology'
  },
  {
    label: 'Oncology',
    value: 'oncology'
  },
  {
    label: 'Surgery',
    value: 'surgery'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const VIEW_MODE_OPTIONS = {
  GRID: 'grid',
  LIST: 'list'
};

function setActionRow() {
  return (
    <Flex gap="16px">
      <FiDownload size="16px" />
      <FiX size="16px" />
    </Flex>
  );
}

function Pin(isPinned) {
  return (
    <Flex gap="8px" justifyContent="center">
      {isPinned ? <StyledPinned size="16px" /> : <StyledUnpinned size="16px" />}
    </Flex>
  );
}

const TABLE_COLUMNS = [
  {
    Header: 'Title',
    accessor: 'document_name'
  },
  {
    Header: 'Type',
    accessor: 'mime_type'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Category',
    accessor: 'document_type'
  },
  {
    id: 'id',
    Header: 'Pinned',
    accessor: (d) => Pin(d.pinned)
  },
  {
    id: 'actions',
    Header: 'Actions',
    accessor: () => setActionRow()
  }
];

const TabTitle = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
  padding-bottom: 8px;
`;

const StyledSelectMenu = styled(SelectMenu)`
  min-width: 200px;
`;

const buildOptionsFromAttachments = (attachments) => {
  const options = attachments.reduce((obj, attachment) => {
    const { s3_file_name, mime_type } = attachment;

    const attactmentExtention =
      neoMimeDB[mime_type]?.extensions[0] ||
      s3_file_name.split('.').pop() ||
      'other';
    return {
      ...obj,
      [attactmentExtention]: {
        label: attactmentExtention,
        value: mime_type
      }
    };
  }, {});
  return options;
};

export default function Attachments({ patientId }) {
  const pData = useRecoilValue(selectedPatientState) || {};
  const patientData = pData[patientId];
  const { attachments = [] } = patientData;

  const ATTACHMENT_TYPE_OPTIONS = useMemo(
    () =>
      Object.values({
        ALL_OPTION,
        ...buildOptionsFromAttachments(attachments)
      }),
    [attachments]
  );

  const [viewMode, setViewMode] = useState(VIEW_MODE_OPTIONS.GRID);
  const {
    isShowing: addAttachmentIsVisible,
    toggle: toggleAddAttachmentModal
  } = useModal();
  const [filterAttachments, setFilterAttachments] = useState(ALL_OPTION);
  const [filterFileType, setFilterFileType] = useState(ALL_OPTION);

  const filterAttachmentFunction = (attachmentData, filterVal) => {
    if (filterVal === ALL_ATTACHMENTS) {
      return attachmentData;
    }
    if (filterVal === 'pinned') {
      return attachmentData.filter((dataItem) => dataItem.pinned);
    }
    if (filterVal.startsWith('image'))
      return attachmentData.filter(
        (dataItem) => (dataItem.mime_type || '').toLowerCase() === filterVal
      );
    return attachmentData.filter(
      (dataItem) => (dataItem.document_type || '').toLowerCase() === filterVal
    );
  };

  const filterAttachmentByFileType = (currentSet, filterVal) =>
    currentSet.filter((file) => file.mime_type === filterVal);

  let finalAttachmentSet = filterAttachmentFunction(
    attachments,
    filterAttachments.value
  );

  if (filterFileType.value !== ALL_ATTACHMENTS) {
    finalAttachmentSet = filterAttachmentByFileType(
      finalAttachmentSet,
      filterFileType.value
    );
  }

  const canAttach = userPrivs.ADD_PATIENT_ATTACHMENT;
  return (
    <>
      <Modal
        isShowing={addAttachmentIsVisible}
        hide={toggleAddAttachmentModal}
        title="Add attachment"
        onPrimaryClick={() => {}}
        buttonText="Add Attachment"
        spinnerText="Uploading attachment..."
      >
        <AddAttachment
          documentType={filterAttachments.value}
          setDocumentType={(selectedType) => {
            setFilterFileType(ALL_OPTION);
            setFilterAttachments(
              ATTACHMENT_FILTER_OPTIONS.find((op) => op.value === selectedType)
            );
          }}
        />
      </Modal>
      <TabTitle justifyContent="space-between" alignItems="center">
        <Typography variant="h1" bold>
          Attachments
        </Typography>
        <Flex gap="8px">
          <Feature name="show-treatment-list">
            <IconButton
              onClick={() => setViewMode(VIEW_MODE_OPTIONS.LIST)}
              icon={<BsList size="24px" />}
              variant={
                viewMode === VIEW_MODE_OPTIONS.GRID ? 'primary' : 'active'
              }
            />

            <IconButton
              onClick={() => setViewMode(VIEW_MODE_OPTIONS.GRID)}
              icon={<BsGridFill size="24px" />}
              variant={
                viewMode === VIEW_MODE_OPTIONS.LIST ? 'primary' : 'active'
              }
            />
          </Feature>
          {canAttach && (
            <IconButton
              onClick={toggleAddAttachmentModal}
              icon={<FiPlus size="24px" />}
            />
          )}
        </Flex>
      </TabTitle>

      <Flex gap="16px">
        <Flex direction="column" gap="8px">
          <Typography bold>Filter</Typography>
          <StyledSelectMenu
            options={ATTACHMENT_FILTER_OPTIONS}
            defaultValue={ALL_OPTION}
            onChange={setFilterAttachments}
            value={filterAttachments}
          />
        </Flex>
        <Flex direction="column" gap="8px">
          <Typography bold>File Type</Typography>
          <StyledSelectMenu
            options={ATTACHMENT_TYPE_OPTIONS}
            defaultValue={ALL_OPTION}
            onChange={setFilterFileType}
            value={filterFileType}
          />
        </Flex>
      </Flex>

      {viewMode === VIEW_MODE_OPTIONS.GRID ? (
        <Flex gap="16px" wrap>
          {finalAttachmentSet.length ? (
            finalAttachmentSet.map((at) => (
              <Attachment
                key={at.id}
                docId={at.id}
                docType={at.document_type}
                docName={at.document_name}
                mimeType={at.mime_type}
                date={getSlashedDate(at.date_created).replace(/\//g, '-')}
                previewImage={at.s3_file_name}
                title={at.document_name}
                isPinned={!!at.pinned}
                patientId={patientId}
                hospitalId={patientData.hospital_id}
                width={156}
                responsiveWidth={156}
              />
            ))
          ) : (
            <Typography text="shuttlegray" bold>
              No {filterAttachments.label.replace('All', '')} Attachments Found
            </Typography>
          )}
        </Flex>
      ) : (
        <Table columns={TABLE_COLUMNS} data={finalAttachmentSet} />
      )}
    </>
  );
}

Attachments.propTypes = {
  patientId: PropTypes.string
};

Attachments.defaultProps = {
  patientId: ''
};
