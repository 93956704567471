import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';

import { ENV } from '@Utils/envTools';
import getAuthCookies from '@Utils/api/getAuthCookies';
import { cookieRefreshState } from '@Utils/atoms';
import InlineSpinner from '@Components/NeosomaUI/InlineSpinner/InlineSpinner';
import { BsFileEarmarkX } from 'react-icons/bs';

const Image = styled.img`
  &.hidden {
    position: fixed;
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  }
`;

const Spinner = styled(InlineSpinner)`
  width: 100%;
  justify-content: center;
  position: absolute;
  min-height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 0;
  background-color: #ffffff92;
  & img {
    width: 46px !important;
    height: 35px !important;
  }
`;

function CookieImg({
  filename,
  hospitalId,
  className = '',
  options: {
    contentUrl = ENV.CONTENT_URL,
    stealthMode = false,
    showSpinner = false,
    hideBroken = false
  },
  onError,
  ...props
}) {
  // Error state tracking
  const canRetry = useRef(true);
  const errorCount = useRef(0);
  const lastCookieRefresh = useRef(null);
  const cookieRefreshing = useRecoilValue(cookieRefreshState);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  if (!hospitalId || !filename) {
    return null;
  }

  const handleError = () => {
    // first image load attempt fails. (either cookie is stale or image is broken)
    //   fetch cookies and wait.
    //     s: got new cookies
    //       retry image load.
    //         s: all good.( we wont know this happened) as the error handler will not be called.
    //           new fail: cookie if failure happened within 7 seconds of the last cookie refresh.
    //             true: image is broken.
    //             false: can retry again in 5min.
    //         f: image is broken (not a problem due to stale cookie)
    //     f: cookie call failed so not sure at this point if image is broken can retry again in 5min.
    errorCount.current += 1;
    if (errorCount.current > 1 && canRetry.current) {
      // If we have already retried once or more, check if the error happened within 7 seconds of the last cookie refresh.
      if (Date.now() - lastCookieRefresh.current < 7000) {
        // Image is broken. We wont retry.
        canRetry.current = false;
        setImageLoadFailed(true);
        return;
      }
      if (Date.now() - lastCookieRefresh.current > 1000 * 60 * 5) {
        // If the last cookie refresh was more than 5 minutes ago, we can retry.
        errorCount.current = 1;
      }
    }
    if (errorCount.current === 1 && canRetry.current) {
      getAuthCookies()
        // SUCCESS
        .then(() => {
          // we got cookies. image will try to reload.
          lastCookieRefresh.current = Date.now();
          canRetry.current = true;
        })
        // FAILED
        .catch((err) => {
          // failed to get cookie but we can retry as we don't know if the image is broken at this point.
          lastCookieRefresh.current = Date.now();
          canRetry.current = true;
        });
    }
  };

  const isBrokenImage = !!imageLoadFailed;
  const waitForCookieRefresh = cookieRefreshing.refreshing;

  return (
    <>
      {showSpinner && waitForCookieRefresh && <Spinner singelLoader />}
      {!isBrokenImage && !waitForCookieRefresh && (
        <Image
          className={stealthMode ? `hidden ${className}` : `${className}`}
          // This is just here to trigger the onError event. hidden from the user.
          onError={handleError}
          src={waitForCookieRefresh ? '' : `${contentUrl}/${filename}`}
          draggable="false"
          {...props}
        />
      )}
      {isBrokenImage && !hideBroken && (
        <BsFileEarmarkX
          style={{
            display: 'flex',
            flex: '1',
            height: '100%',
            padding: '16px',
            color: '#4d4c4c'
          }}
        />
      )}
    </>
  );
}

CookieImg.defaultProps = {
  className: '',
  onError: () => {
    /* NO OP */
  },
  options: {
    waitForCookieRefresh: false,
    contentUrl: ENV.CONTENT_URL,
    stealthMode: false,
    showSpinner: false,
    hideBroken: false
  }
};

CookieImg.propTypes = {
  filename: PropTypes.string.isRequired,
  hospitalId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onError: PropTypes.func,
  options: PropTypes.shape({
    waitForCookieRefresh: PropTypes.bool,
    contentUrl: PropTypes.string,
    stealthMode: PropTypes.bool, // Hide image from user will cause a cookie refresh
    showSpinner: PropTypes.bool,
    hideBroken: PropTypes.bool
  })
};

export default CookieImg;
