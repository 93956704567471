import postStudyExportRequest from './postStudyExportRequest';

const warmUpExport = async (patientId, studyId) =>
  postStudyExportRequest({
    patientId,
    studyId,
    seriesTypeId: 0,
    lesionTypeId: 0
  });

export default warmUpExport;
