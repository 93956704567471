import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { TbKey, TbKeyOff } from 'react-icons/tb';
import { Modal, Typography } from '@Components/NeosomaUI';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  justify-content: center;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
  margin-top: 15px;
`;

const IconContainer = styled.div`
  display: flex;
  border-radius: 100%;
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.neosomaOrange};
  &#slider {
    position: absolute;
    transform: translateX(-100%);
    animation-iteration-count: infinite;
  }

  &.stop {
    animation-play-state: paused;
    transform: translateX(0%);
  }

  &.slide-in-out {
    animation: slide-in-out 3s forwards;
  }

  @keyframes slide-in-out {
    0% {
      transform: translateX(0%);
    }
    5% {
      transform: translateX(0%);
    }
    33% {
      transform: translateX(-80%);
    }
    66% {
      transform: translateX(80%);
    }
    95% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  &.success {
    background-color: ${({ theme }) => theme.palette.salem};
    transform: translateX(0%) !important;
  }
  &.failed {
    background-color: ${({ theme }) => theme.palette.thunderbird};
    transform: translateX(0%) !important;
  }
`;

const StatusText = styled(Typography)`
  text-align: center;
  padding: 20px;
`;

function PasswordVerificationModal({
  isShowing,
  setShowing,
  passwordChangeState
}) {
  const iconClass = `${
    passwordChangeState === 'waiting' ? 'slide-in-out' : 'stop'
  } ${passwordChangeState !== 'waiting' ? passwordChangeState : ''}`;
  return (
    <Modal
      title="Updating Password"
      isShowing={isShowing}
      hideFooter
      hide={() => setShowing(false)}
      style={{ width: '350px', marginTop: '78px' }}
      hideHeaderCloseButton
    >
      <Container>
        <IconWrapper>
          <IconContainer id="slider" className={iconClass}>
            {passwordChangeState === 'waiting' && <TbKey size="23" />}
            {passwordChangeState === 'success' && <TbKey size="23" />}
            {passwordChangeState === 'failed' && <TbKeyOff size="23" />}
          </IconContainer>
        </IconWrapper>
        {passwordChangeState === 'waiting' && (
          <StatusText variant="displayTwo">
            Verifying new password...
          </StatusText>
        )}
        {passwordChangeState === 'success' && (
          <StatusText variant="displayTwo">Password Change Complete</StatusText>
        )}
        {passwordChangeState === 'failed' && (
          <StatusText variant="displayTwo">
            Password Change Has Failed
          </StatusText>
        )}
      </Container>
    </Modal>
  );
}

PasswordVerificationModal.propTypes = {
  isShowing: PropTypes.bool,
  setShowing: PropTypes.func,
  passwordChangeState: PropTypes.string
};

PasswordVerificationModal.defaultProps = {
  isShowing: false,
  setShowing: () => {},
  // This is not a hardcoded password
  passwordChangeState: 'waiting' // NOSONAR
};

export default PasswordVerificationModal;
