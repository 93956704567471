/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { BsFillPencilFill, BsXLg } from 'react-icons/bs';

import { Flex } from '@Components/NeosomaUI';
import { defaultTheme } from '../theme';
import Paginator from './Paginator';

const StyledTable = styled.table`
  border-spacing: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-left: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightsilver};
  width: 100%;
  tr {
    position: relative;
    :last-child {
      td {
        border-bottom: 0;
      }
    }
    &:hover {
      .show-on-hover {
        display: block;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightsilver};
    border-right: 1px solid ${({ theme }) => theme.palette.lightsilver};
  }
  td:last-child {
    padding-right: 40px;
  }
`;

const StyledTableHeader = styled.thead`
  background-color: ${({ theme }) => theme.palette.selago};
  border-right: 1px solid ${({ theme }) => theme.palette.lightsilver};
  color: ${({ theme }) => theme.palette.azure};
  font-weight: 700;
  text-align: left;
`;

const EditButtons = styled(Flex)`
  position: absolute;
  right: 8px;
  bottom: 8px;
  cursor: pointer;
  display: none;
`;

const EditButton = styled(BsFillPencilFill)`
  margin-right: 8px;
`;

function EditActions({ onEdit, onDelete, hideEdit, hideDelete }) {
  return (
    <EditButtons gap="8px" className="show-on-hover">
      {!hideEdit && (
        <EditButton
          title="Edit Row"
          onClick={onEdit}
          color={defaultTheme.palette.azure}
          size="16px"
        />
      )}
      {!hideDelete && (
        <BsXLg
          title="Delete Row"
          onClick={onDelete}
          color={defaultTheme.palette.blaze}
          size="16px"
        />
      )}
    </EditButtons>
  );
}

EditActions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool
};

EditActions.defaultProps = {
  hideEdit: false,
  hideDelete: false
};

// eslint-disable-next-line react/prop-types
function TableRow({
  selectedRow,
  setSelectedRow,
  getCellProps,
  cell,
  allowEdit,
  allowDelete,
  onEdit,
  isEditing,
  onDelete,
  row,
  onRowClick
}) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <td
      {...getCellProps()}
      onClick={(e) => {
        const rowId = row.original.id;
        if (onRowClick) {
          onRowClick(e, rowId);
        }
        if (allowEdit && selectedRow !== rowId) {
          setSelectedRow(rowId);
        }
      }}
    >
      <div style={{ padding: isEditing ? 0 : undefined }}>
        {cell.render('Cell')}
        <EditActions
          key={`${row.original.id}-${cell.column.Header}`}
          style={{ padding: isEditing ? undefined : 0 }}
          onDelete={() => onDelete(row)}
          onEdit={() => {
            onEdit();
          }}
          hideEdit={!allowEdit}
          hideDelete={!allowDelete}
        />
      </div>
    </td>
  );
}

TableRow.propTypes = {
  selectedRow: PropTypes.string,
  setSelectedRow: PropTypes.func.isRequired,
  getCellProps: PropTypes.func.isRequired,
  cell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

TableRow.defaultProps = {
  selectedRow: null
};

// Create an editable cell renderer
function EditableCell({
  value: initialValue,
  row: { index, original = {} },
  column: { id, readonly, EditorComponent, ...rest },
  isEditing, // This is a custom function that we supplied to our table instance
  selectedRow,
  handleDataChange
}) {
  const thisRowId = original.id;

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue || '');
  const onChange = (e) => {
    const isEvent =
      e.constructor.name !== 'SyntheticEvent' &&
      e.constructor.name !== 'SyntheticBaseEvent';
    const nextValue = isEvent ? e : e.target.value;
    setValue(nextValue);
    handleDataChange(thisRowId, id, nextValue === '' ? null : nextValue);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const isEditingThisRow = isEditing && selectedRow === thisRowId && !readonly;
  const Editor = EditorComponent || 'input';
  return (
    <>
      {isEditingThisRow && <Editor value={value} onChange={onChange} />}
      {!isEditingThisRow && value}
    </>
  );
}

// eslint-disable-next-line react/prop-types
function Table({
  columns,
  data,
  handleDataChange,
  allowEdit,
  allowDelete,
  onDelete,
  isEditing,
  onEdit,
  usePagination,
  totalItems,
  startPaginatingAt,
  onPageChange,
  rowsPerPage,
  setRowsPerPage,
  onRowClick
}) {
  const [currentPage, setCurrentPage] = useState(startPaginatingAt ?? 0);
  const [selectedRow, setSelectedRow] = useState(null);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      defaultColumn: {
        Cell: EditableCell
      },
      autoResetPage: !isEditing,
      isEditing,
      selectedRow,
      handleDataChange
    });

  const handleEditClick = (rowId) => {
    setSelectedRow(rowId);
    onEdit(rowId);
  };

  return (
    <>
      <StyledTable {...getTableProps()}>
        <StyledTableHeader>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </StyledTableHeader>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <TableRow
                    index={idx}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    key={idx}
                    getCellProps={cell.getCellProps}
                    cell={cell}
                    allowEdit={allowEdit}
                    allowDelete={allowDelete}
                    onDelete={onDelete}
                    isEditing={isEditing}
                    onEdit={() => handleEditClick(row?.original?.id)}
                    row={row}
                    onRowClick={onRowClick}
                  />
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      {usePagination && (
        <Paginator
          count={data?.length}
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
export default Table;
