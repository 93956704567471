/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { userInfoState, hidePIIState } from '@Utils/atoms';
import { getSetting, setSetting } from '@Utils/userSettings';
import Checkbox from '@Components/NeosomaUI/Form/CheckBox';

function InputHidePII() {
  const userInfo = useRecoilValue(userInfoState);
  const hidePII = getSetting(userInfo.id, 'hidePII') || false;
  const [checked, setChecked] = useState(hidePII);
  const [hidePIIValue, setHidePIIValue] = useRecoilState(hidePIIState);

  const handleChange = (event) => {
    const checkedValue = event.target.checked;
    const thisHidePII = getSetting(userInfo.id, 'hidePII');
    if (thisHidePII !== checkedValue) {
      setSetting(userInfo.id, 'hidePII', checkedValue);
      sessionStorage.setItem('hidePII', checkedValue);
    }
    setChecked(checkedValue);
  };

  useEffect(() => {
    const changed = checked === !!hidePIIValue;
    if (changed) {
      // update idle timeout based on remember me selection.
      setHidePIIValue(checked ? undefined : false);
    }
  }, [checked, hidePIIValue, setHidePIIValue]);

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        id="userSettingsHidePII"
        name="userSettingsHidePII"
        label="Screen Sharing Mode (do not show PII)"
        style={{ width: 'fit-content', gap: '10px' }}
      />
    </div>
  );
}

export default InputHidePII;
