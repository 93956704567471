import IFU from '../assets/Instructions for Use - Neosoma HGG.pdf';
import { VERSION, HGG_VERSION } from '../utils/envTools';

const strings = {
  dashboardVersionText: `Neosoma Dashboard ver: ${VERSION}`,
  dashboardGUIDText: `(01)00860009223646(10)${VERSION.replaceAll('.', '')}`,
  defaultDisclaimer:
    'The use of this software is restricted to authorized {{HOSPITAL_NAME}} users only. ' +
    'If you are not an authorized user or employee, please exit immediately. By continuing ' +
    'to use this system, you consent to the terms and conditions of use as defined here: ' +
    'https://www.neosomainc.com/terms-of-service.',
  hggVersionText: `Neosoma HGG ver: ${HGG_VERSION}`,
  hggGUIDText: `(01)00860009223639(10)${HGG_VERSION.replaceAll('.', '')}`,
  IFU_url: IFU,
  IFU_filename: 'Instructions for Use - Neosoma HGG.pdf',
  sessionTimeoutMessage:
    'Your session has expired. Please log in again to continue.',
  passwordExpiredMessage:
    'Your password has expired. Please use the "Forgot Password" link to reset it.',
  sessionLogutMessage: `It looks like your Session has expired or your credentials are not valid for the current request \n\nPlease enter your username and password to continue.`,
  'analysisSummary.disclaimer':
    'Important: This report is for informational purposes only, not for diagnostic use. It should not solely guide medical decisions and must be reviewed by a qualified medical professional.',
  accessionNotFound:
    'No records were found using the identifier provided.\nTry searching for the patient using the form below.'
};

export default function getString(key) {
  if (strings[key]) {
    return strings[key];
  }
  return '';
}
