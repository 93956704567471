import styled from '@emotion/styled';
// eslint-disable-next-line import/no-cycle
import { Button, Flex } from '@Components/NeosomaUI';

const Container = styled(Flex)`
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background: black;
  color: white;
  width: 100vw;
  height: 100vh;
  & iframe {
    width: 100%;
  }
  z-index: 2000;
`;

const LeftPane = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  background-color: ${({ theme }) => theme.palette.emperor};
  .logo {
    padding: 8px;
    padding-left: 16px;
    padding-top: 11px;
  }
  @media (max-width: 1039px) {
    position: absolute;
    visibility: collapse;
  }
`;

const ImageList = styled('div')`
  width: 184px;
  display: flex;
  flex: 1;
  row-gap: 19px;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
`;

const CloseButton = styled(Button)`
  flex: 1;
`;

const MinimizeButton = styled.button`
  display: flex;
  border: none;
  padding: 5px;
  height: 27px;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.palette.tundora};
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  &:focus-visible {
    outline: none;
    border-bottom: 2px solid ${({ theme }) => theme.palette.tangerine};
  }
`;

const ImageCard = styled.div`
  width: 144px;
  height: 190px;
  position: relative;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.palette.eclipse};
  background: ${({ selected, theme }) =>
    selected ? theme.palette.selago : theme.palette.lightsilver};
  box-shadow: 0px 4px 7px 0px #0000005e;
  cursor: ${({ selected }) => (selected ? 'auto' : 'pointer')};
  & img {
    height: 151px !important;
    width: 126px !important;
  }
  ${({ selected }) =>
    selected &&
    '&:not(:focus-visible) {outline: 2px solid #FFB800; outline-offset: 1px;}'}
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: none;
  }
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ImageCardLabel = styled.div`
  color: var(--neutrals-n-2000, #272727);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 6px;
`;

const BottomContainer = styled.div`
  border-top: 1.5px solid ${({ theme }) => theme.palette.tundora};
  display: flex;
  padding: 16px;
  position: relative;
  @media (max-width: 1039px) {
    position: absolute;
    visibility: visible;
    top: calc(100vh - 75px);
    border-top: 0px;
  }

  & > div {
    position: absolute;
    background: transparent;
    box-shadow: 0px -8px 12px -4px #1c1c23db;
    width: 100%;
    height: 20px;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

const CompareLabel = styled.div`
  position: absolute;
  padding: 1px 4px;
  background-color: #ffb800;
  right: -2px;
  top: -2px;
  color: #472500;
  box-shadow: -1px 1px 2px 0px #0000005e;
`;

const EditingArrowContainer = styled.div`
  letter-spacing: 0.6px;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 2px 0;
  gap: 6px;
  color: ${({ theme }) => theme.palette.malachite};
`;

export {
  Container,
  LeftPane,
  ImageList,
  CloseButton,
  MinimizeButton,
  ImageCard,
  ImageCardLabel,
  BottomContainer,
  CompareLabel,
  EditingArrowContainer
};
