/* eslint-disable react/prop-types */ import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  dateFormatter,
  getMaxYAxisValue,
  getTicksArray
} from './EmpericalResultsGraph';

export default function Enhancing({ data }) {
  const axisType = localStorage.axisScale === 'off' ? 'string' : 'number';
  const axisScale = localStorage.axisScale === 'off' ? 'none' : 'time';

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        height={300}
        width={600}
        data={data}
        margin={{
          top: 5,
          right: 60,
          left: 20,
          bottom: 45
        }}
      >
        <Legend verticalAlign="top" height={36} />
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          type={axisType}
          scale={axisScale}
          angle={45}
          dx={25}
          dy={30}
          domain={[data[0].date, data[data.length - 1].date]}
          tickFormatter={dateFormatter}
        />
        <YAxis
          interval={0}
          ticks={getTicksArray(data, 'enhancing', 5)}
          domain={[0, getMaxYAxisValue(data, 'enhancing', 5)]}
          type="number"
          scale="linear"
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          wrapperStyle={{ zIndex: '1000' }}
          labelFormatter={dateFormatter}
          formatter={(value, name, obj) => {
            // Access obj.payload for full data set
            const value0 = (obj.payload.prior || {})[obj.dataKey] || 0;
            let display = `${value.toFixed(2)} cm³`;
            if (value === 0) {
              display += ' (Not Detected)';
            } else if (value0 === 0) {
              display += ' (Initial Measurement)';
            } else {
              const delta = Math.min(999.99, ((value - value0) / value0) * 100);
              if (delta === 0) {
                display += ' (No Change)';
              } else {
                const pos = delta > 0 ? '↑' : '↓';
                display += ` (${pos}${Math.abs(delta).toFixed(2)}%`;
                display += ` from ${value0.toFixed(2)} cm³)`;
              }
            }
            return display;
          }}
        />
        <Area
          name="Enhancing"
          type="monotone"
          dataKey="enhancing"
          dot={{ r: 3 }}
          activeDot={{ r: 5 }}
          stroke="#FF0000"
          strokeWidth={2}
          fillOpacity={0.2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
