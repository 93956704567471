/* eslint-disable camelcase */
import { bool, object } from 'prop-types';
import styled from '@emotion/styled';
import { FaRegEye as FaEye, FaRegEyeSlash as FaEyeSlash } from 'react-icons/fa';

const Container = styled.span`
  position: relative;
  padding-top: 1px;
  .eye {
    color: ${({ theme }) => theme.palette.dovegray};
    display: ${({ showEye }) => (showEye ? 'block' : 'none')};
  }
  .noEye {
    color: ${({ theme }) => theme.palette.dovegray};
    display: ${({ showEye }) => (showEye ? 'none' : 'block')};
  }
`;
const StyledDiv = styled.div`
  position: absolute;
  bottom: -7px;
  left: ${({ showEye }) => (showEye ? '-10.75px' : '-15px')};
  font-size: 9px;
  letter-spacing: 0.3px;
  pointer-events: none;
  color: ${({ theme }) => theme.palette.dovegray};
`;

export default function ReviewedIcon({
  record: { sort_type } = {},
  isHovered,
  canEditPatient
}) {
  const isReviewed = sort_type === 4;
  const showEye = (() => {
    if (canEditPatient) {
      return (isHovered && !isReviewed) || (!isHovered && isReviewed);
    }
    return isReviewed;
  })();

  return (
    <Container showEye={showEye}>
      <FaEye size="20px" className="eye" />
      <FaEyeSlash size="20px" className="noEye" />
      <StyledDiv className="reviewed-text">
        {showEye ? 'Reviewed' : 'Unreviewed'}
      </StyledDiv>
    </Container>
  );
}

ReviewedIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: object,
  isHovered: bool,
  canEditPatient: bool
};

ReviewedIcon.defaultProps = {
  record: {},
  isHovered: false,
  canEditPatient: false
};
