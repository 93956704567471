import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { bool, arrayOf, string, shape, func } from 'prop-types';

const SideBarContainer = styled.nav`
  min-width: 160px;
  padding-top: 8px;
`;

const SideBarLink = styled.a`
  color: ${({ theme, selected, disabled }) => {
    if (disabled) {
      return selected ? theme.palette.abbey : theme.palette.submarine;
    }
    return selected ? theme.palette.blaze : theme.palette.dovegray;
  }};
  text-decoration: none;
  font-size: 0.875rem;
  background-color: ${({ theme, selected, disabled }) => {
    if (disabled) {
      return selected ? theme.palette.submarine : theme.palette.white;
    }
    return selected ? theme.palette.romantic : theme.palette.white;
  }};
  display: block;
  padding: 8px 16px;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  transition: 0.5s all;
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.abbey : theme.palette.blaze};
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.submarine : theme.palette.romantic};
    font-weight: 700;
  }
`;

function SideBar({ data, activeTab, updateSelection, isLoading }) {
  const [deferedTab, setDeferedTab] = useState();

  useEffect(() => {
    if (deferedTab !== undefined) {
      setDeferedTab(undefined);
      updateSelection(deferedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  return (
    <SideBarContainer>
      <ul>
        {data.map((rec) => (
          <li key={rec.id}>
            <SideBarLink
              href=" "
              selected={rec.id === activeTab}
              onClick={(e) => {
                e.preventDefault();
                if (isLoading) {
                  if (rec.id !== activeTab) setDeferedTab(rec.id);
                } else if (rec.id !== activeTab) updateSelection(rec.id);
              }}
            >
              {rec.displayText}
            </SideBarLink>
          </li>
        ))}
      </ul>
    </SideBarContainer>
  );
}

SideBar.defaultProps = {
  activeTab: '',
  data: [],
  updateSelection: () => {},
  isLoading: false
};

SideBar.propTypes = {
  activeTab: string,
  data: arrayOf(shape({ type: string })),
  updateSelection: func,
  isLoading: bool
};

export default SideBar;
