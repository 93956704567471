import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  ModalInnerStyle,
  ModalHeader,
  ModalFooter
} from '@Components/NeosomaUI/Modal';

const ConfirmContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;
`;

const ModalContainer = styled.div`
  min-width: 400px;
  min-height: 110px;
  padding: 20px;
  color: ${({ theme }) => theme.palette.mineshaft};
`;

function UnsavedConfirmModal({ isShowing, onCancleClick, onPrimayClick }) {
  const modalEml = useRef(null);
  const isShowingModal = useRef(undefined);

  useEffect(() => {
    const modal = modalEml.current;
    if (!modal) return () => {};
    const keyPressHandler = (e) => {
      if (e.key === 'Escape') {
        onCancleClick();
      }
      if (e.key === 'Enter') {
        onPrimayClick();
      }
    };

    if (isShowingModal.current === undefined && isShowing) {
      window.addEventListener('keyup', keyPressHandler);
      isShowingModal.current = true;
      modal?.focus();
    }

    return () => {
      window.removeEventListener('keyup', keyPressHandler);
      isShowingModal.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  return (
    <ConfirmContainer ref={modalEml}>
      <ModalInnerStyle>
        <ModalHeader title="Unsaved Changes" onCancleClick={onCancleClick} />
        <ModalContainer>
          <>
            <div>Are you sure you want to leave this window?</div>
            <div>You have unsaved changes that will be lost.</div>
          </>
        </ModalContainer>
        <ModalFooter
          confirmText="Discard and continue"
          onPrimayClick={onPrimayClick}
          onCancleClick={onCancleClick}
        />
      </ModalInnerStyle>
    </ConfirmContainer>
  );
}

UnsavedConfirmModal.propTypes = {
  isShowing: PropTypes.bool,
  onCancleClick: PropTypes.func,
  onPrimayClick: PropTypes.func
};

UnsavedConfirmModal.defaultProps = {
  isShowing: false,
  onCancleClick: () => {
    /* Noop */
  },
  onPrimayClick: () => {
    /* Noop */
  }
};

export default UnsavedConfirmModal;
