/* eslint-disable camelcase */
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { bool, func } from 'prop-types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AvatarEditor from 'react-avatar-editor';

import { ENV } from '@Utils/envTools';
import { userInfoState, avatarTimeStampState } from '@Utils/atoms';
import postAvatar from '@Utils/api/postAvatar';
import getAuthCookies from '@Utils/api/getAuthCookies';
import uploadAvatar from '@Utils/api/uploadAvatar';

import { LuImageMinus, LuImagePlus } from 'react-icons/lu';
import { TbFileUpload } from 'react-icons/tb';
import { PiImageDuotone } from 'react-icons/pi';
import { MdArrowDropUp, MdOutlineRotate90DegreesCw } from 'react-icons/md';

import { Flex, Modal, Error } from '@Components/NeosomaUI';

const acceptedFileTypes = 'image/*';
const SizeTooLarge = 'exceeds size limit or 20mb';

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

const Quadrants = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  gap: 20px;
  width: 350px;
  margin: 0 auto;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.mineshaft};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 8px;
  & #attach-type {
    font-weight: 400;
  }
  input[type='file'] {
    border: 1px dashed ${({ theme }) => theme.palette.lightsilver};
    border-radius: 4px;
    height: 150px;
    background-color: ${({ theme }) => theme.palette.lotion};
    color: transparent;
  }
  input::file-selector-button {
    width: 100%;
    height: 100%;
    color: transparent;
    opacity: 0;
  }
  .example {
    font-weight: 300;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 18px;
  padding: 20px 0;
  input {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 20px 46px;
  gap: 10px;
`;

const StyledAvatarEditor = styled(AvatarEditor)`
  width: 350px;
  height: 350px;
  cursor: grab;
  touch-action: none;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 8px;
  background: black;
`;

const StyledFlex = styled(Flex)`
  width: 350px;
  align-items: center;
`;

const FileInputLabel = styled(Flex)`
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: 49px;
  font-weight: 400;
  align-items: center;
  pointer-events: none;
  gap: 2px;
  & strong {
    margin-left: 6px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  & svg {
    color: ${({ theme }) => theme.palette.azure};
  }
  .attached-filename {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.mineshaft};
  }
`;

function UserImageUpload({ showModal, hideModal, updateAvatar }) {
  const { id, home_hospital_id } = useRecoilValue(userInfoState) || {};
  const userId = useMemo(() => id, [id]);
  const userHomeHospitalId = useMemo(
    () => home_hospital_id,
    [home_hospital_id]
  );
  const [hasError, setHasError] = useState(false);
  const uploadFailedCount = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imageScale, setImageScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const editorRef = useRef(null);

  // Image stuff.
  const setAvatarTimeStamp = useSetRecoilState(avatarTimeStampState);
  const [disabled, setDisabled] = useState(undefined);
  const [disableButtons, setDisableButtons] = useState(undefined);
  const [invalidFile, setInvalidFile] = useState(false);
  const [fileAttached, setFileAttached] = useState(undefined);
  const fileInput = useRef(null);

  const hospitalId = ENV.CLIENT_ID;

  const [userIsAtHomeHospital, setUserIsAtHomeHospital] = useState(
    userHomeHospitalId === hospitalId
  );

  useEffect(() => {
    setUserIsAtHomeHospital(hospitalId === userHomeHospitalId);
  }, [hospitalId, userHomeHospitalId, userIsAtHomeHospital, showModal]);

  const handleImageSelectChange = (e) => {
    setInvalidFile(false);
    // If no file attached, reset state
    if (!e.target.files.length) {
      if (fileAttached) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(fileAttached);
        e.target.files = dataTransfer.files;
      }
      return;
    }
    const thisFile = e.target.files[0];
    // CHECK if file is valid
    const whitelist = acceptedFileTypes.split(',');
    const fileExtension = thisFile.name.split('.').pop();
    const whiteListAllowsImages = whitelist.includes('image/*');

    let validFile = false;
    if (whiteListAllowsImages && thisFile.type.includes('image')) {
      validFile = true;
    }
    if (whitelist.includes(`.${fileExtension}`)) {
      validFile = true;
    }

    if (thisFile.size / 1024 / 1024 > 20) {
      validFile = false;
    }

    if (!validFile) {
      setFileAttached('');
      if (thisFile.size / 1024 / 1024 > 20) {
        setInvalidFile(`${thisFile.name} ${SizeTooLarge}`);
        return;
      }
      setInvalidFile(true);
      return;
    }
    setFileAttached(thisFile);
  };

  const handleChange = (event) => {
    if (
      Number.parseFloat(event.target.value) < 1.049 &&
      Number.parseFloat(event.target.value) > 0.932
    ) {
      setImageScale(1);
      return;
    }
    setImageScale(Number.parseFloat(event.target.value));
  };

  const handleCancel = () => {
    hasError && setHasError(false);
    setFileAttached(undefined);
    setInvalidFile(false);
    setImageScale(1);
    setRotation(0);
    setFileAttached(undefined);
    if (fileInput?.current?.value) {
      fileInput.current.value = '';
    }
    uploadFailedCount.current = 0;
    hideModal && hideModal();
  };

  const onClickSave = useCallback(async () => {
    if (editorRef.current) {
      setIsLoading(true);
      let processFailed = false;
      getAuthCookies();
      // If you want the image resized to the canvas size (also a HTMLCanvasElement)

      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const blob = dataURItoBlob(canvasScaled.toDataURL('image/png'));
      const file = new File([blob], `user_${userId}.png`, {
        type: 'image/png'
      });

      // Disable inputs
      setDisabled(true);
      setDisableButtons(true);

      const processHasError = (err) => {
        uploadFailedCount.current += 1;
        // eslint-disable-next-line no-console
        console.error(err);
        setIsLoading(false);
        setDisableButtons(undefined);
        setDisabled(undefined);
        setHasError(
          `There was an error uploading your avatar image. ${
            uploadFailedCount.current < 4
              ? 'Please try again.'
              : 'Perhaps try again in a few minutes.'
          }`
        );
        processFailed = true;
      };

      // Inifialize new file
      const data = await postAvatar(userId).catch(processHasError);
      if (processFailed) return;
      // TODO: Handle error
      if (data === undefined || data?.status?.type === 'error') {
        processHasError();
      }
      if (processFailed) return;
      if (data.uploadURL) {
        await uploadAvatar(data.uploadURL, file).catch(processHasError);
        if (processFailed) return;
        setTimeout(() => {
          updateAvatar();
          setIsLoading(false);
          setDisableButtons(undefined);
          setDisabled(undefined);
          setFileAttached(undefined);
          setImageScale(1);
          if (fileInput?.current?.value) {
            fileInput.current.value = '';
          }
          hideModal();
          setTimeout(() => {
            setAvatarTimeStamp(Date.now());
          }, 20);
        }, 1000);
      }
    }
  }, [hideModal, setAvatarTimeStamp, updateAvatar, userId]);

  const disablePrimaryButton = () => {
    if (!userIsAtHomeHospital) return true;
    if (hasError) return hasError;
    if (isLoading) return 'showSpinner';
    return !fileAttached;
  };

  const disableAllButtons = () => {
    if (isLoading) return 'showSpinner';
    return disableButtons;
  };

  return (
    <Modal
      isShowing={showModal}
      onCancelClick={handleCancel}
      onPrimaryClick={onClickSave}
      disablePrimary={disablePrimaryButton()}
      disableButtons={disableAllButtons()}
      spinnerText={isLoading && 'Uploading...'}
      title="Upload Avatar Image"
    >
      <div>
        {hasError && <Error errorMessage={hasError} showIcon />}
        {!userIsAtHomeHospital && (
          <InfoContainer>
            <div>
              You can only change your avatar image when logged into your
              &quot;Home&quot; Hospital.
            </div>
            <div>
              Please log out and change to that hospital to continue this
              operation.
            </div>
          </InfoContainer>
        )}
        {!fileAttached && userIsAtHomeHospital && (
          <Quadrants>
            <Label htmlFor="myfile">
              Select an image to upload:
              <input
                ref={fileInput}
                type="file"
                id="myfile"
                name="myfile"
                onChange={handleImageSelectChange}
                disabled={disabled}
                accept={acceptedFileTypes}
                onEvent={handleImageSelectChange}
              />
              <FileInputLabel direction="column">
                {fileAttached?.name ? (
                  <TbFileUpload size="64px" />
                ) : (
                  <PiImageDuotone size="64px" />
                )}
                <div
                  style={{ marginBottom: '16px' }}
                  className={fileAttached?.name && 'attached-filename'}
                >
                  {
                    // eslint-disable-next-line no-nested-ternary
                    fileAttached?.name
                      ? fileAttached.name
                      : // eslint-disable-next-line no-nested-ternary
                      invalidFile
                      ? `${invalidFile}`.includes(SizeTooLarge)
                        ? invalidFile
                        : 'File type is invalid'
                      : 'No file selected'
                  }
                </div>
                <Flex>
                  Drag and Drop file here or <strong> Choose file</strong>
                </Flex>
              </FileInputLabel>
              <Flex style={{ justifyContent: 'space-between' }}>
                <div style={{ fontWeight: '300' }}> </div>
                <div style={{ fontWeight: '300' }}>Maximum size: 20MB</div>
              </Flex>
            </Label>
          </Quadrants>
        )}
        {fileAttached && userIsAtHomeHospital && (
          <ModalContainer>
            <div style={{ position: 'relative' }}>
              <StyledAvatarEditor
                ref={editorRef}
                image={fileAttached}
                width={250}
                height={250}
                border={0}
                color={[23, 35, 55, 0.5]} // RGBA
                scale={imageScale}
                rotate={rotation}
                borderRadius={100000}
              />
              <MdOutlineRotate90DegreesCw
                size="20px"
                onClick={() => setRotation(rotation + 90)}
                style={{
                  backgroundColor: '#ffffffaf',
                  borderRadius: '100%',
                  position: 'absolute',
                  bottom: '11px',
                  right: '6px',
                  padding: '5px',
                  height: '30px',
                  width: '30px'
                }}
              />
            </div>
            <StyledFlex gap="15px">
              <LuImageMinus size="20px" />
              <input
                value={imageScale}
                step={0.00000001}
                onChange={handleChange}
                type="range"
                min=".6"
                max="2"
                className="slider"
                id="myRange"
              />
              <LuImagePlus size="26px" />
              <MdArrowDropUp
                size="26px"
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '186.5px',
                  pointerEvents: 'none'
                }}
              />
            </StyledFlex>
          </ModalContainer>
        )}
      </div>
    </Modal>
  );
}

UserImageUpload.propTypes = {
  hideModal: func,
  showModal: bool,
  updateAvatar: func
};

UserImageUpload.defaultProps = {
  hideModal: () => {
    /* No Op */
  },
  showModal: false,
  updateAvatar: () => {
    /* No Op */
  }
};

export default UserImageUpload;
