/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { POST, PUT } = doFetch.methods;

const updateRating = async (rating) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();
  const HTTP_METHOD = rating.id ? PUT : POST;

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(`${uri}/patient/study/rating?client_id=${hospitalId}`, {
    method: HTTP_METHOD,
    headers: {
      Authorization: authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(rating),
    doNotRedirectOnError: true
  });
};
export default updateRating;
