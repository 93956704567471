import { useEffect } from 'react';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import { shape, string, oneOfType, node, arrayOf } from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import getString from '@Components/strings';

import { getSetting } from '@Utils/userSettings';
import {
  userInfoState,
  showDisclaimerState,
  rolodexState,
  authRefreshState
} from '@Utils/atoms';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { promiseSetRecoil } from 'recoil-outside';
import { ENV } from '@Utils/envTools';
import getUserRolodex from './api/getUserRolodex';

async function cleanUp() {
  await promiseSetRecoil(userInfoState, {});
  localStorage.removeItem('user');
}

function ProtectedRoute({ userInfo, children, ...props }) {
  const setShowDisclaimer = useSetRecoilState(showDisclaimerState);
  const [rolodex, setRolodex] = useRecoilState(rolodexState);

  // Code to refresh the token if it is expired.
  const setUserInfo = useSetRecoilState(userInfoState);
  const setAuthRefresh = useSetRecoilState(authRefreshState);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();

  const auth0Settings = ENV.OAUTH2;
  const isAuth0 = auth0Settings.organization !== undefined;

  const rememberMe =
    getSetting(userInfo.id, 'rememberMe') === undefined
      ? true
      : getSetting(userInfo.id, 'rememberMe');

  useEffect(() => {
    const refresh = async () => {
      if (isAuth0 && userInfo.token_expires_at < Date.now()) {
        setAuthRefresh(true);
        const tkn = await getAccessTokenSilently().catch((error) => {
          localStorage.removeItem('user');

          navigate('/login', {
            replace: true,
            state: {
              authMessage: getString('sessionLogutMessage')
            }
          });
        });
        const idToken = await getIdTokenClaims();

        // If token is empty just return (which could have been from the getAccessTokenSilently "catch" above)
        if (!idToken) return;

        // Check the password expiration:
        const today = new Date();
        const passwordExp = idToken.neosoma?.password_expiration || today;
        if (today > new Date(passwordExp)) {
          localStorage.removeItem('user');

          navigate('/login', {
            replace: true,
            state: {
              authMessage: getString('passwordExpiredMessage')
            }
          });
        }

        setUserInfo({
          ...userInfo,
          token_bearer: tkn,
          token_expires_at: (idToken.exp || 0) * 1000
        });
        setAuthRefresh(false);
      }
    };
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // End of token refresh code.

  if (!userInfo || userInfo?.id === undefined) {
    if (window.location.pathname !== '/login') {
      sessionStorage.setItem('redirect', window.location.pathname);
    }
    return <Navigate to="/login" replace />;
  }

  const hidePII = getSetting(userInfo.id, 'hidePII') || false;

  // Set hidePII for the session DEV-352
  sessionStorage.setItem('hidePII', hidePII);

  const inSession = sessionStorage.getItem('memento') === 'true';

  // if rememberMe is false and and we are not in the same session.
  if (!rememberMe && !inSession) {
    cleanUp();
    return <Navigate to="/login" replace />;
  }
  // Show disclaimer if user is in protected routes.
  setTimeout(() => {
    setShowDisclaimer(showDisclaimerState.disclaimerOptions.show);
  }, 1);
  if (rolodex === undefined) {
    getUserRolodex(userInfo.id).then((data) => {
      if (Array.isArray(data)) {
        const rolodexData = data.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur }),
          {}
        );
        setRolodex(rolodexData);
        sessionStorage.setItem('rolodex', JSON.stringify(rolodexData));
      }
    });
  }

  return (
    <>
      {children}
      <Outlet />
    </>
  );
}

ProtectedRoute.defaultProps = {
  userInfo: undefined,
  children: undefined
};

ProtectedRoute.propTypes = {
  userInfo: shape({ id: string }),
  children: oneOfType([arrayOf(node), node])
};

export default ProtectedRoute;
