/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { PUT } = doFetch.methods;

const pinAttachment = async (patientId, docId, docName, pinState) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(
    `${uri}/patient/attachments?client_id=${hospitalId}&patient_id=${patientId}`,
    {
      method: PUT,
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: docId,
        document_name: docName,
        pinned: pinState
      })
    }
  );
};
export default pinAttachment;
