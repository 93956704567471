/** @jsxImportSource @emotion/react */
import { Popover } from 'react-tiny-popover';
import { useRef, useState, Children, cloneElement } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FiMoreVertical } from 'react-icons/fi';

import { addAlpha, useDetectOutsideClick } from './helpers';

// Styles.
const RelativeContainer = styled.div`
  position: relative;
`;

const ActionMenuContainer = styled.nav`
  background-color: ${({ theme }) => theme.palette.white};
  padding: 6px 0;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px
    ${({ theme }) => addAlpha(theme.palette.black, 0.25)};
  position: absolute;
  right: ${({ position }) => (position === 'right' ? 0 : 'auto')};
  top: 15px;
  z-index: 999;
  min-width: 140px;
  display: flex;
  flex-direction: column;
  & button {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    border: none;
    width: 100%;
    background-color: transparent;
    justify-content: flex-start;
    color: ${({ theme }) => theme.palette.mineshaft};
    padding: 8px 10px;
    white-space: nowrap;
    &:hover {
      background-color: ${({ theme }) => theme.palette.antiflashwhite};
    }
  }
  & p[type='button'] {
    padding: 0;
    & a,
    & button {
      padding: 8px 15px;
      cursor: pointer;
    }
  }
`;

const ActionMenuButton = styled(FiMoreVertical)`
  color: ${({ theme }) => theme.palette.shuttlegray};
  cursor: pointer;
`;

export default function ActionMenu({ position, children }) {
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef(null);
  useDetectOutsideClick(wrapperRef, () => setIsActive(false));

  const newChildren = Children.map(children, (child) =>
    cloneElement(child, { dimissMenu: () => setIsActive(false) })
  );
  return (
    <>
      <style>
        {`
          .action-menu-popover {
            z-index: 25;
          }
        `}
      </style>
      <Popover
        isOpen={isActive}
        onClickOutside={() => setIsActive(false)}
        containerClassName="action-menu-popover"
        content={
          <ActionMenuContainer position={position} hideOnSelect>
            {newChildren}
          </ActionMenuContainer>
        }
      >
        <RelativeContainer ref={wrapperRef}>
          <ActionMenuButton
            onClick={() => setIsActive(!isActive)}
            size="20px"
          />
        </RelativeContainer>
      </Popover>
    </>
  );
}

ActionMenu.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string
};

ActionMenu.defaultProps = {
  children: null,
  position: 'left'
};
