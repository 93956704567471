/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { BsFillPencilFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import DatePicker from 'react-datepicker';

import {
  Button,
  Flex,
  Modal,
  ConfirmModal,
  SelectMenu,
  Typography,
  useModal
} from '@Components/NeosomaUI';
import { FormTextarea } from '@Components/NeosomaUI/Form/Input';
import Table from '@Components/NeosomaUI/Table/Table';
import { IconButton } from '@Components/IconButton';
import { getSlashedDatetime } from '@Utils/common';
import putPatientSummaryUpdate from '@Utils/api/putPatientSummaryUpdate';
import deleteTreatment from '@Utils/api/deleteTreatment';
import { defaultTheme as UItheme } from '@Components/NeosomaUI/theme';
import { selectedPatientState } from '@Utils/atoms';

import {
  TUMOR_TYPES_OPTIONS,
  TUMOR_LOCATIONS_OPTIONS,
  TUMOR_GRADES_OPTIONS,
  TUMOR_PROFILES_OPTIONS,
  TUMOR_RESPONSE_OPTIONS
} from '@Utils/generalConstants';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import Feature from '@Utils/FeatureFlags';
import AddTreatment, { MODES } from './AddTreatment';
import SummaryNotes, { FormItem } from './PatientSummaryNotes';

const Select = styled.select`
  width: 100%;
`;

const TABLE_COLUMNS = [
  //  { Header: 'ID', accessor: 'id', readonly: true },
  {
    Header: 'Therapy Type',
    accessor: 'therapy_type',
    EditorComponent: ({ ...props }) => (
      <Select {...props}>
        <option>Radiotherapy</option>
        <option>Chemotherapy</option>
      </Select>
    )
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    formatter: (date) => getSlashedDatetime(date),
    EditorComponent: ({ ...props }) => <DatePicker {...props} />
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    formatter: (date) => getSlashedDatetime(date),
    EditorComponent: ({ ...props }) => <DatePicker {...props} />
  },
  {
    Header: 'Clinician',
    accessor: 'clinician'
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    EditorComponent: ({ ...props }) => <textarea {...props} />
  }
];

const EMPTY_TREATMENT = {
  therapy_type: '--',
  start_date: '--',
  end_date: '--',
  clinician: '--',
  notes: '--'
};

const FormRow = styled(Flex)`
  width: 100%;
  margin-bottom: 26px;
  &:last-child {
    margin-bottom: 10px;
    align-items: flex-start;
  }
  & input:disabled,
  & textarea:disabled {
    color: ${({ theme }) => theme.palette.mineshaft};
    background-color: ${({ theme }) => theme.palette.lotion};
  }
`;

const TabTitle = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
  padding-bottom: 8px;
  margin-bottom: 23px;
`;

// Format data for table before passing to table component
const formatData = (data = [EMPTY_TREATMENT]) =>
  data.map((record) =>
    TABLE_COLUMNS.reduce(
      (obj, col) => {
        if (col.formatter) {
          return {
            ...obj,
            [col.accessor]: col.formatter(record[col.accessor])
          };
        }
        return { ...obj, [col.accessor]: record[col.accessor] };
      },
      { ...record }
    )
  );

// even when disabled have white background and black text
const formInputStyles = {
  singleValue: (base, state) => ({
    ...base,
    color: `${UItheme.palette.mineshaft}`
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled
      ? UItheme.palette.lotion
      : base.backgroundColor
  })
};

// *-------------------------------*
// |  COMPONENT :: PatientSummary  |
// *-------------------------------*
export default function PatientSummary({ patientData = {}, setIsUnsaved }) {
  const {
    diagnosis,
    diagnosis_grade,
    molecular_profile,
    primary_location,
    treatment_response,
    summary
    // notes
  } = patientData;
  const [disableFormFields, setDisableFormFields] = useState(true);
  const [savingPatient, setSavingPatient] = useState(false);
  const [treatmentData, setTreatmentData] = useState(patientData?.treatments);
  const [updateTreatmentMode, setUpdateTreatmentMode] = useState(undefined);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(undefined);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmUndoChanges, setShowConfirmUndoChanges] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(undefined);

  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const { isShowing: addTreatmentIsVisible, toggle: toggleAddTreatment } =
    useModal();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    watch
  } = useForm({
    defaultValues: {
      diagnosis,
      diagnosis_grade,
      molecular_profile,
      primary_location,
      treatment_response,
      summary
    },
    resetOptions: {
      keepDirtyValues: false,
      keepErrors: false
    }
  });

  const formMutation = useMutation(putPatientSummaryUpdate, {
    // TODO: handle errors
    onSuccess: (newData) => {
      setDisableFormFields(true);
      setSavingPatient(false);
      // update global state.
      setSelectedPatient({
        ...selectedPatient,
        [patientData.id]: { ...selectedPatient[patientData.id], ...newData }
      });
      setIsUnsaved(false);
    },
    onError: () => {
      setSavingPatient(false);
    }
  });

  // this is the data from the form...
  const onSubmit = (formData) => {
    if (isDirty) {
      setSavingPatient(true);
      formMutation.mutate({
        patientId: patientData.id,
        changesPayload: formData
      });
    }
  };

  // clear form
  const resetForm = () => {
    setShowConfirmUndoChanges(false);
    setIsUnsaved(false);
    setSavingPatient(false);
    setDisableFormFields(true);
    reset();
  };

  const handleConfirmDelete = ({ original: { id } }) => {
    setItemToDelete(id);
    setShowConfirmDelete(true);
  };

  const handleDelete = () => {
    const id = itemToDelete;
    deleteTreatment(patientData.id, id);
    setTreatmentData(treatmentData.filter((t) => t.id !== id));
    setSelectedPatient({
      ...selectedPatient,
      [patientData.id]: {
        ...selectedPatient[patientData.id],
        treatments: treatmentData.filter((t) => t.id !== id)
      }
    });
    // Clean up tracking states
    setItemToDelete(undefined);
    setShowConfirmDelete(false);
  };

  const handleUndoChanges = (type) => {
    if (type === 'YES') {
      resetForm();
    } else {
      setShowConfirmUndoChanges(false);
    }
  };

  useEffect(() => {
    setTreatmentData(patientData?.treatments);
  }, [patientData?.treatments, selectedPatient]);

  const getOptions = (key) => {
    switch (key) {
      case 'diagnosis':
        if (!TUMOR_TYPES_OPTIONS.find((t) => t.value === diagnosis)) {
          return [
            ...TUMOR_TYPES_OPTIONS,
            { label: diagnosis || '', value: diagnosis || '' }
          ];
        }
        return TUMOR_TYPES_OPTIONS;
      case 'diagnosis_grade':
        if (!TUMOR_GRADES_OPTIONS.find((t) => t.value === diagnosis_grade)) {
          return [
            ...TUMOR_GRADES_OPTIONS,
            { label: diagnosis_grade || '', value: diagnosis_grade || '' }
          ];
        }
        return TUMOR_GRADES_OPTIONS;
      case 'molecular_profile':
        if (
          !TUMOR_PROFILES_OPTIONS.find(
            (t) =>
              t.value.toLowerCase() === (molecular_profile || '').toLowerCase()
          )
        ) {
          return [
            ...TUMOR_PROFILES_OPTIONS,
            { label: molecular_profile || '', value: molecular_profile || '' }
          ];
        }
        return TUMOR_PROFILES_OPTIONS;
      case 'primary_location':
        if (
          !TUMOR_LOCATIONS_OPTIONS.find(
            (t) =>
              t.value.toLowerCase() === (primary_location || '').toLowerCase()
          )
        ) {
          return [
            ...TUMOR_LOCATIONS_OPTIONS,
            { label: primary_location || '', value: primary_location || '' }
          ];
        }
        return TUMOR_LOCATIONS_OPTIONS;
      case 'treatment_response':
        if (
          !TUMOR_RESPONSE_OPTIONS.find((t) => t.value === treatment_response)
        ) {
          return [
            ...TUMOR_RESPONSE_OPTIONS,
            { label: treatment_response || '', value: treatment_response || '' }
          ];
        }
        return TUMOR_RESPONSE_OPTIONS;

      default:
        return [];
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'summary' && type === 'change') {
        setIsUnsaved(true);
      }
    });

    return () => subscription.unsubscribe();
  }, [setIsUnsaved, watch]);

  return (
    <>
      <section id="Diagnosis">
        <TabTitle justifyContent="space-between" alignItems="center">
          <Typography variant="h1" bold>
            Diagnosis
          </Typography>
          {userPrivs.EDIT_PATIENT && (
            <IconButton
              icon={<BsFillPencilFill size="20px" />}
              onClick={() => setDisableFormFields(!disableFormFields)}
            />
          )}
        </TabTitle>
        <Modal
          isShowing={addTreatmentIsVisible}
          hide={toggleAddTreatment}
          title={
            updateTreatmentMode === MODES.ADD
              ? 'Add Treatment'
              : 'Edit Treatment'
          }
          buttonText={updateTreatmentMode === MODES.ADD ? 'Save' : 'Update'}
          hideFooter // Needed for the form submit to work
        >
          <AddTreatment
            mode={updateTreatmentMode}
            patientId={patientData.id}
            treatmentId={selectedTreatmentId}
          />
        </Modal>
        <ConfirmModal
          isShowing={showConfirmDelete}
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={handleDelete}
          title="Confirm Delete"
          message="Are you sure you want to delete this treatment?"
          confirmText="Delete Treatment"
        />
        <ConfirmModal
          isShowing={showConfirmUndoChanges}
          onCancel={() => handleUndoChanges('NO')}
          onConfirm={() => handleUndoChanges('YES')}
          title="Unsaved Changes"
          message="Are you sure you want discard changes?"
          confirmText="Discard Changes"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow gap="26px" alignItems="center">
            <FormItem label="Tumor Type" name="tumorType">
              <Controller
                control={control}
                name="diagnosis"
                render={({ ref, field: { value } }) => {
                  const OPTIONS = getOptions('diagnosis');
                  return (
                    <SelectMenu
                      inputRef={ref}
                      options={OPTIONS}
                      placeholder="--"
                      value={OPTIONS.find((t) => t.value === value)}
                      onChange={(val) => {
                        setIsUnsaved(true);
                        setValue('diagnosis', val.value, { shouldDirty: true });
                      }}
                      disabled={disableFormFields || savingPatient}
                      styles={formInputStyles}
                    />
                  );
                }}
              />
            </FormItem>
            <FormItem label="Grade" name="grade">
              <Controller
                control={control}
                name="diagnosis_grade"
                render={({ ref, field: { value } }) => (
                  <SelectMenu
                    inputRef={ref}
                    options={TUMOR_GRADES_OPTIONS}
                    placeholder="--"
                    value={TUMOR_GRADES_OPTIONS.find((t) => t.value === value)}
                    onChange={(val) => {
                      setIsUnsaved(true);
                      setValue('diagnosis_grade', val.value, {
                        shouldDirty: true
                      });
                    }}
                    disabled={disableFormFields || savingPatient}
                    styles={formInputStyles}
                  />
                )}
              />
            </FormItem>
            <Feature name="non-mvp-feature">
              <FormItem label="Molecular Profile" name="molecularProfile">
                <Controller
                  control={control}
                  name="molecular_profile"
                  render={({ ref, field: { value } }) => {
                    const OPTIONS = getOptions('molecular_profile');
                    return (
                      <SelectMenu
                        inputRef={ref}
                        options={OPTIONS}
                        placeholder="--"
                        value={OPTIONS.find(
                          (t) =>
                            t.value.toLowerCase() ===
                            (value || '').toLowerCase()
                        )}
                        onChange={(val) => {
                          setIsUnsaved(true);
                          setValue('molecular_profile', val.value, {
                            shouldDirty: true
                          });
                        }}
                        disabled={disableFormFields || savingPatient}
                        styles={formInputStyles}
                      />
                    );
                  }}
                />
              </FormItem>
            </Feature>
          </FormRow>
          <FormRow gap="26px" alignItems="center">
            <FormItem label="Location" name="location">
              <Controller
                control={control}
                name="primary_location"
                render={({ ref, field: { value } }) => {
                  const OPTIONS = getOptions('primary_location');
                  return (
                    <SelectMenu
                      inputRef={ref}
                      options={OPTIONS}
                      placeholder="--"
                      value={OPTIONS.find(
                        (t) =>
                          t.value.toLowerCase() === (value || '').toLowerCase()
                      )}
                      onChange={(val) => {
                        setIsUnsaved(true);
                        setValue('primary_location', val.value, {
                          shouldDirty: true
                        });
                      }}
                      disabled={disableFormFields || savingPatient}
                      styles={formInputStyles}
                    />
                  );
                }}
              />
            </FormItem>
            <FormItem label="Response" name="treatmentResponse">
              <Controller
                control={control}
                name="treatment_response"
                render={({ ref, field: { value } }) => {
                  const OPTIONS = getOptions('treatment_response');
                  return (
                    <SelectMenu
                      inputRef={ref}
                      options={OPTIONS}
                      placeholder="--"
                      value={OPTIONS.find(
                        (t) =>
                          t.value.toLowerCase() === (value || '').toLowerCase()
                      )}
                      onChange={(val) => {
                        setIsUnsaved(true);
                        setValue('treatment_response', val.value, {
                          shouldDirty: true
                        });
                      }}
                      disabled={disableFormFields || savingPatient}
                      styles={formInputStyles}
                    />
                  );
                }}
              />
            </FormItem>
          </FormRow>
          <FormRow gap="26px" alignItems="center">
            <FormItem label="HPI" name="HPI">
              <FormTextarea
                style={{ height: '182px' }}
                id="HPI"
                name="summary"
                disabled={disableFormFields || savingPatient}
                {...register('summary')}
              />
            </FormItem>
            <SummaryNotes patient={patientData} />
          </FormRow>
          {!disableFormFields && (
            <Flex gap="8px" justifyContent="flex-end">
              <Button
                type="button"
                variant="primary-inverse"
                onClick={() => {
                  if (isDirty) setShowConfirmUndoChanges(true);
                  else resetForm();
                }}
                disabled={savingPatient}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={savingPatient || !isDirty}
              >
                {savingPatient ? 'Saving...' : 'Save'}
              </Button>
            </Flex>
          )}
        </form>
      </section>
      <Feature name="non-mvp-feature">
        <section id="Treatment">
          <TabTitle justifyContent="space-between" alignItems="center">
            <Typography variant="h1" bold>
              Treatment
            </Typography>
            {userPrivs.CREATE_PATIENT_TREATMENTS && (
              <IconButton
                icon={<FiPlus size="24px" />}
                onClick={() => {
                  setUpdateTreatmentMode(MODES.ADD);
                  toggleAddTreatment();
                }}
              />
            )}
          </TabTitle>

          <Table
            columns={TABLE_COLUMNS}
            data={formatData(treatmentData)}
            allowEdit={userPrivs.EDIT_PATIENT_TREATMENTS}
            allowDelete={userPrivs.DELETE_PATIENT_TREATMENTS}
            onEdit={(id) => {
              setUpdateTreatmentMode(MODES.EDIT);
              setSelectedTreatmentId(id);
              toggleAddTreatment();
            }}
            onDelete={handleConfirmDelete}
          />
        </section>
      </Feature>
    </>
  );
}

PatientSummary.propTypes = {
  patientData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    age: PropTypes.string,
    sex: PropTypes.string,
    dob: PropTypes.string,
    diagnosis: PropTypes.string,
    diagnosis_grade: PropTypes.string,
    molecular_profile: PropTypes.string,
    stage: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attending: PropTypes.string,
    referring: PropTypes.string,
    avatar: PropTypes.string,
    summary: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    notes: PropTypes.arrayOf(PropTypes.object),
    primary_location: PropTypes.string,
    treatment_response: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    treatments: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  setIsUnsaved: PropTypes.func.isRequired
};
