/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const ALIGN_LEFT = 'left';
const ALIGN_CENTER = 'center';
const ALIGN_RIGHT = 'right';

const REGULAR = '400';
const BOLD = '700';

const TypographyBase = styled.p`
  font-weight: ${REGULAR};
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: ${BOLD};
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ text, theme }) =>
    text &&
    css`
      color: ${theme.palette[text] || text};
    `}
`;

const displayOne = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.displayOne};
  line-height: ${lineHeight.displayOne};
`;

const displayTwo = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.displayTwo};
  line-height: ${lineHeight.displayTwo};
`;

const h1 = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.h1};
  line-height: ${lineHeight.h1};
`;

const h2 = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.h2};
  line-height: ${lineHeight.h2};
`;

const h3 = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.h3};
  line-height: ${lineHeight.h3};
`;

const p = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.p};
  line-height: ${lineHeight.p};
`;

const small = ({
  fontFamily,
  components: {
    text: { size, lineHeight }
  }
}) => css`
  font-family: ${fontFamily.display};
  font-size: ${size.small};
  line-height: ${lineHeight.small};
`;

const VARIANT_MAP = {
  displayOne,
  displayTwo,
  h1,
  h2,
  h3,
  p,
  small
};

const ELEMENT_MAP = {
  displayOne: 'h1',
  displayTwo: 'h2'
};

// Component.
const Typography = forwardRef(
  ({ children, element, variant, bold, uppercase, ...props }, ref) => (
    <TypographyBase
      as={element || ELEMENT_MAP[variant] || variant}
      css={VARIANT_MAP[variant]}
      ref={ref}
      bold={bold}
      uppercase={uppercase}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </TypographyBase>
  )
);

Typography.propTypes = {
  align: PropTypes.oneOf([ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT]),
  element: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(VARIANT_MAP)),
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  children: PropTypes.node
};

Typography.defaultProps = {
  align: null,
  element: null,
  text: null,
  variant: 'p',
  children: null,
  bold: false,
  uppercase: false
};

export default Typography;
