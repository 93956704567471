/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';

const { PUT } = doFetch.methods;

const uploadAvatar = async (s3_file_url, fileObject) => {
  doFetch(
    s3_file_url,
    {
      method: PUT,
      headers: {
        'Content-Type': fileObject.type,
        'Content-Disposition': `attachment; filename=${fileObject.name}`
      },
      body: fileObject,
      doNotRedirectOnError: true
    },
    false
  );
};
export default uploadAvatar;
