/* eslint-disable camelcase */
import { useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FiPlus } from 'react-icons/fi';

import {
  Flex,
  Typography,
  SelectMenu,
  useModal,
  Modal
} from '@Components/NeosomaUI';
import { PATIENT_PORTAL_TABS } from '@Utils/defaultConfig';
import { IconButton } from '@Components/IconButton';
import { selectedPatientState } from '@Utils/atoms';
import { getSlashedDate } from '@Utils/common';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import Attachment from './Attachments/Attachment';
import AddAttachment from './Attachments/AddAttachment';
import { DOCUMENT_TYPES } from './Attachments/helpers';

const ALL_ATTACHMENTS = 'all';

const ATTACHMENT_FILTER_OPTIONS = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Pinned',
    value: 'pinned',
    check: (attachment) => attachment.pinned === 1
  },
  // add document types
  ...Object.keys(DOCUMENT_TYPES).map((docKey) => ({
    label: docKey,
    value: DOCUMENT_TYPES[docKey]
  }))
];

const DEFAULT_ATTACHMENT_OPTION = ATTACHMENT_FILTER_OPTIONS[0];

const AttachmentWrapper = styled.div`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  width: 244px;
  min-width: 244px;
  @media (max-width: 1439px) {
    width: 186px;
    min-width: 186px;
  }
  @media (max-width: 1133px) {
    display: none;
  }
`;

export default function AttachmentsSidebar({ patientId, selectedTab = {} }) {
  const pData = useRecoilValue(selectedPatientState) || {};
  const patientData = pData[patientId];
  const { attachments = [] } = patientData;

  const {
    isShowing: addAttachmentIsVisible,
    toggle: toggleAddAttachmentModal
  } = useModal();

  const [filterAttachments, setFilterAttachments] = useState(
    DEFAULT_ATTACHMENT_OPTION
  );

  // *----------------------*
  // | Filter Attachment(s) |
  // *----------------------*
  const finalAttachmentSet = useMemo(() => {
    if (filterAttachments.value === ALL_ATTACHMENTS) {
      return attachments;
    }
    if (filterAttachments.value === DEFAULT_ATTACHMENT_OPTION.value) {
      return attachments;
    }

    if (!filterAttachments.check) {
      return attachments.filter(
        (dataItem) =>
          (dataItem.document_type || '').toUpperCase() ===
          (filterAttachments.value || '').toUpperCase()
      );
    }

    return attachments.filter((dataItem) => filterAttachments?.check(dataItem));
  }, [attachments, filterAttachments]);

  return (
    <AttachmentWrapper
      className={`right-attachment-sidebar${
        selectedTab === PATIENT_PORTAL_TABS.map((t) => t.id).indexOf('imaging')
          ? ' imaging'
          : ''
      }`}
    >
      <Modal
        isShowing={addAttachmentIsVisible}
        hide={toggleAddAttachmentModal}
        title="Add attachment"
        buttonText="Add Attachment"
        spinnerText="Uploading attachment..."
      >
        <AddAttachment
          documentType={filterAttachments.value}
          setDocumentType={(newValue) =>
            setFilterAttachments(
              ATTACHMENT_FILTER_OPTIONS.find((o) => o.value === newValue)
            )
          }
        />
      </Modal>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h3" text="shuttlegray" uppercase bold>
          Attachments
        </Typography>
        {userPrivs.ADD_PATIENT_ATTACHMENT && (
          <IconButton
            onClick={toggleAddAttachmentModal}
            icon={<FiPlus size="20px" />}
            variant="dark"
          />
        )}
      </Flex>
      <SelectMenu
        options={ATTACHMENT_FILTER_OPTIONS}
        defaultValue={DEFAULT_ATTACHMENT_OPTION}
        onChange={setFilterAttachments}
        value={filterAttachments}
      />
      <Flex gap="16px" wrap>
        {finalAttachmentSet.length ? (
          finalAttachmentSet.map((attachment) => {
            const {
              id,
              date_created: date,
              mime_type: mimeType,
              document_type: docType,
              s3_file_name: previewImage,
              document_name,
              pinned: isPinned
            } = attachment;
            return (
              <Attachment
                docId={id}
                docType={docType}
                docName={document_name}
                key={id}
                mimeType={mimeType}
                date={getSlashedDate(date).replace(/\//g, '-')}
                previewImage={previewImage}
                title={document_name}
                isPinned={!!isPinned}
                patientId={patientId}
                hospitalId={patientData.hospital_id}
                responsiveWidth={85}
              />
            );
          })
        ) : (
          <Typography text="shuttlegray" bold>
            No {filterAttachments.label.replace('All', '')} Attachments Found
          </Typography>
        )}
      </Flex>
    </AttachmentWrapper>
  );
}
AttachmentsSidebar.propTypes = {
  patientId: PropTypes.string.isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
