import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FiChevronDown } from 'react-icons/fi';
import { Transition } from 'react-transition-group';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { Flex, Typography } from '@Components/NeosomaUI';
import { selectedPatientState } from '@Utils/atoms';
import { TUMOR_EVENTS } from '@Utils/generalConstants';
import { getDate } from '@Utils/common';
import PatientTimelineEvent from './PatientTimelineEvent';

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0
};

const transitionStyles = {
  entering: { opacity: 1, height: 'auto' },
  entered: { opacity: 1, height: 'auto' },
  exiting: { opacity: 0, height: 'auto' },
  exited: { opacity: 0, height: 0 }
};

const TimelineWrapper = styled.div`
  height: calc(-226px + 100vh);
  width: 250px;
  min-width: 250px;
  @media (max-width: 1439px) {
    width: 210px;
    min-width: 210px;
  }
  @media (max-width: 1343px) {
    display: none;
  }
`;

const TimeLineItemHolder = styled(Flex)`
  border-left: 1px solid ${({ theme }) => theme.palette.lightsilver};
`;

const TimeLineHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.mineshaft};
  margin: 16px 0;
`;

const CollapseIcon = styled(FiChevronDown)`
  color: ${({ theme }) => theme.palette.mineshaft};
  transition: 0.5s all;
  ${({ $rotate }) =>
    $rotate === 'true'
      ? 'transform: rotate(180deg)'
      : 'tansform: rotate(0deg)'};
  cursor: pointer;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.submarine};
  margin: 5px 16px 0 -5px;
`;

export default function PatientTimeLine({ changeTab }) {
  const navigate = useNavigate();
  const [upcomingIsOpen, setUpcomingIsOpen] = useState(true);
  const [pastIsOpen, setPastIsOpen] = useState(true);

  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);

  const {
    dispositions = [],
    studies = [],
    treatments = []
  } = selectedPatient[selectedPatient.currentPatient];

  const patientTimeline = useMemo(() => {
    const getDateFromData = (data) => {
      if (data.eventType === TUMOR_EVENTS.IMAGES) {
        return data.study_date;
      }
      return data.start_date ? data.start_date : data.date_created;
    };

    const nextTreatments = treatments.reduce((arr, t) => {
      let nextArr = [...arr];
      if (t.end_date) {
        const startDate = {
          ...t,
          eventType: TUMOR_EVENTS.TREATMENT,
          event: `${t.therapy_type?.replace(/^(.*?) \/ /, '')} Start`,
          date: getDate(t.start_date),
          key: `${t.id}-start`
        };
        delete startDate.end_date;
        const endDate = {
          ...t,
          eventType: TUMOR_EVENTS.TREATMENT,
          event: `${t.therapy_type?.replace(/^(.*?) \/ /, '')} End`,
          date: getDate(t.end_date),
          key: `${t.id}-end`
        };
        delete endDate.start_date;

        nextArr = [...arr, startDate, endDate];
        return nextArr;
      }

      return [
        ...arr,
        {
          ...t,
          eventType: TUMOR_EVENTS.TREATMENT,
          event: t.therapy_type?.replace(/^(.*?) \/ /, ''),
          date: getDate(getDateFromData(t)),
          key: t.id
        }
      ];
    }, []);

    const merged = dispositions.reduce(
      (obj, d) => {
        const { studyNote, nonStudy } = obj;
        if (d.study_id) {
          const study = studies.find((s) => s.id === d.study_id);
          if (study) {
            return {
              studyNote: [
                ...studyNote,
                {
                  ...study,
                  notes: d.notes
                }
              ],
              nonStudy
            };
          }
        }
        return { studyNote, nonStudy: [...nonStudy, d] };
      },
      { studyNote: [], nonStudy: [] }
    );

    const { studyNote: studyDispositions, nonStudy: nonStudyDispositions } =
      merged;
    return [
      ...nonStudyDispositions.map((d) => ({
        ...d,
        eventType: TUMOR_EVENTS.REVIEW,
        event: d.event_type?.replace(/^(.*?) \/ /, '') || '',
        date: getDate(
          getDateFromData({ ...d, eventType: TUMOR_EVENTS.REVIEW })
        ),
        key: d.id
      })),
      ...studies.map((s) => ({
        ...s,
        eventType: TUMOR_EVENTS.IMAGES,
        event: 'Images',
        date: getDate(
          getDateFromData({ ...s, eventType: TUMOR_EVENTS.IMAGES })
        ),
        key: s.id,
        notes: studyDispositions.find((d) => d.id === s.id)?.notes
      })),
      ...nextTreatments
    ];
  }, [dispositions, studies, treatments]);

  patientTimeline.sort((a, b) => new Date(b.date) - new Date(a.date));

  const isBeforeToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return new Date(date) < today;
  };

  const findDateKey = (item) => {
    let key = item.study_date ? 'study_date' : 'date_created';

    if (item.end_date) {
      key = 'end_date';
    } else if (item.start_date) {
      key = 'start_date';
    }
    return key;
  };
  const upcomingTimeLineItems = useMemo(
    () => patientTimeline.filter((p) => !isBeforeToday(p[findDateKey(p)])),
    [patientTimeline]
  );

  const pastTimeLineItems = useMemo(
    () => patientTimeline.filter((p) => isBeforeToday(p[findDateKey(p)])),
    [patientTimeline]
  );

  const handleEventClick = (event, eventType, eventData) => {
    if (event.detail === 2) {
      if (eventType === TUMOR_EVENTS.IMAGES && eventData?.id) {
        setSelectedPatient({ ...selectedPatient, currentStudy: eventData?.id });
        navigate(`/viewer/${eventData?.id}`, {
          state: { initiator: 'patientTimeline' }
        });
      }
      changeTab(eventType);
    }
  };

  return (
    <TimelineWrapper data-test="Timeline">
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h3" text="shuttlegray" uppercase bold>
          Patient Timeline
        </Typography>
      </Flex>
      <Flex
        style={{
          minHeight: 'calc(100vh - 241px)',
          height: 'calc(100% - 241px)',
          overflowY: 'auto',
          overflowX: 'visible',
          paddingLeft: '4px',
          paddingRight: '16px'
        }}
        direction="column"
      >
        <TimeLineHeader justifyContent="space-between" alignItems="center">
          <Typography text="mineshaft" variant="p" bold uppercase>
            Upcoming
          </Typography>
          <CollapseIcon
            size="20px"
            rotate={`${!!upcomingIsOpen}`}
            onClick={() => setUpcomingIsOpen(!upcomingIsOpen)}
          />
        </TimeLineHeader>
        <Transition in={upcomingIsOpen} timeout={duration}>
          {(state) => (
            <TimeLineItemHolder
              direction="column"
              gap="16px"
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
            >
              {upcomingTimeLineItems.map((event) => (
                <Flex alignItems="center" key={event.key}>
                  <Circle />
                  <PatientTimelineEvent
                    timelineEvent={event}
                    onClick={handleEventClick}
                  />
                </Flex>
              ))}
            </TimeLineItemHolder>
          )}
        </Transition>
        <TimeLineHeader justifyContent="space-between" alignItems="center">
          <Typography text="mineshaft" variant="p" bold uppercase>
            Past
          </Typography>
          <CollapseIcon
            size="20px"
            rotate={`${!!pastIsOpen}`}
            onClick={() => setPastIsOpen(!pastIsOpen)}
          />
        </TimeLineHeader>
        <Transition in={pastIsOpen} timeout={duration}>
          {(state) => (
            <TimeLineItemHolder
              direction="column"
              gap="16px"
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
            >
              {pastTimeLineItems.map((event, i) => (
                <Flex alignItems="center" key={event.key}>
                  <Circle />
                  <PatientTimelineEvent
                    timelineEvent={event}
                    onClick={handleEventClick}
                  />
                </Flex>
              ))}
            </TimeLineItemHolder>
          )}
        </Transition>
      </Flex>
    </TimelineWrapper>
  );
}

PatientTimeLine.propTypes = {
  changeTab: PropTypes.func
};

PatientTimeLine.defaultProps = {
  changeTab: () => {
    /* NO OP */
  }
};
