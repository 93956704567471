/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { viewerState, selectedPatientState } from '@Utils/atoms';
import getString from '@Components/strings';
import getPatientDetailsByAccessionNumber from '@Utils/api/getPatientDetailsByAccessionNumber';

import { Spinner } from '../../TaskView/TaskListLoader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.platinum};

  & h2 {
    font-size: 4rem;
    font-weight: 100;
    color: ${({ theme }) => theme.palette.shuttlegray};
  }
`;

function findStudyByAccession(patientList, accession_number) {
  const searchKey = 'accession_number';
  let patientId;
  let located_study_id;
  Object.keys(patientList).forEach((key) => {
    if (patientId && located_study_id) return;
    const patient = patientList[key];
    const study = patient?.studies?.find((s) => {
      if (s[searchKey] === accession_number) {
        patientId = s.id;
        return true;
      }
      return false;
    });
    if (study) {
      located_study_id = study.id;
      patientId = study.patient_id;
    }
  });
  return [patientId, located_study_id];
}

function AccessionLauncher() {
  const { accession_number } = useParams();
  const [visibleState, setVisible] = useRecoilState(viewerState);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPatient = {}, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const navigate = useNavigate();

  useEffect(() => {
    const [patient_id, found_study_id] = findStudyByAccession(
      selectedPatient,
      accession_number
    );

    if (
      selectedPatient.currentPatient !== undefined &&
      selectedPatient.currentStudy !== undefined &&
      selectedPatient.currentPatient === patient_id &&
      selectedPatient.currentStudy === found_study_id &&
      !visibleState.visible
    ) {
      // If we are already on the correct patient and study, don't do anything
      setTimeout(
        () => navigate({ to: `/viewer/${found_study_id}`, replace: true }),
        0
      );
    } else if (
      selectedPatient.currentPatient !== undefined &&
      selectedPatient.currentPatient === patient_id &&
      !found_study_id &&
      !visibleState.visible
    ) {
      if (!selectedPatient.currentStudy) {
        setSelectedPatient({
          ...selectedPatient,
          currentPatient: patient_id,
          currentStudy: found_study_id
        });
      }
      setTimeout(
        () => navigate({ to: `/viewer/${found_study_id}`, replace: false }),
        0
      );
    } else if (patient_id && found_study_id && !visibleState.visible) {
      // If we found the patient and study, set the current patient and study
      setSelectedPatient({
        ...selectedPatient,
        currentPatient: patient_id,
        currentStudy: found_study_id
      });
      setTimeout(
        () => navigate(`/viewer/${found_study_id}`, { replace: false }),
        0
      );
    } else if (accession_number) {
      // If we didn't find the patient in cache, fetch patient details with study_id
      setIsLoading(true);
      getPatientDetailsByAccessionNumber(accession_number)
        .then((data) => {
          if (Object.keys(data).length === 0) {
            // if nothing is found, navigate to patient search page
            setIsLoading(false);
            navigate('/patient-search', {
              replace: true,
              state: { toastMessage: getString('accessionNotFound') }
            });
            return;
          }
          const patient = { ...data, hasDetails: true };
          const studyId = patient.studies.find(
            (s) => s.accession_number === accession_number
          ).id;
          setSelectedPatient({
            ...selectedPatient,
            [patient.id]: patient,
            currentPatient: patient.id,
            currentStudy: studyId
          });
          setIsLoading(false);

          setTimeout(() => {
            navigate(`/viewer/${studyId}`, {
              replace: true,
              state: { initiator: 'AccessionLauncher' }
            });
          }, 0);
        })
        .catch((error) => {
          setIsLoading(false);
          navigate('/');
        });
    } else {
      // If we didn't find the patient and study, navigate to the home page
      navigate('/patient-search', {
        replace: true,
        state: { toastMessage: getString('accessionNotFound') }
      });
    }

    return () => {
      setVisible({ visible: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accession_number, navigate]);

  return (
    <Container>
      {isLoading && (
        <div className="loading">
          <Spinner
            style={{
              justifyContent: 'center',
              paddingBottom: '100px' // Offset for pagginator
            }}
          >
            <span className="loader" />
          </Spinner>
        </div>
      )}
    </Container>
  );
}

export default AccessionLauncher;
