/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { userInfoState, idleTimeoutState } from '@Utils/atoms';
import { getSetting, setSetting } from '@Utils/userSettings';
import Checkbox from '@Components/NeosomaUI/Form/CheckBox';

function InputRememberMe() {
  const userInfo = useRecoilValue(userInfoState);
  const rememberMe =
    getSetting(userInfo.id, 'rememberMe') === undefined
      ? true
      : getSetting(userInfo.id, 'rememberMe');
  const [checked, setChecked] = useState(rememberMe);
  const [idleTimeout, setIdleTimeout] = useRecoilState(idleTimeoutState);

  const handleChange = (event) => {
    const checkedValue = event.target.checked;
    const thisRemberMe =
      getSetting(userInfo.id, 'rememberMe') === undefined
        ? true
        : getSetting(userInfo.id, 'rememberMe');
    if (thisRemberMe !== checkedValue) {
      setSetting(userInfo.id, 'rememberMe', checkedValue);
      sessionStorage.setItem('memento', !checkedValue);
    }
    setChecked(checkedValue);
  };

  useEffect(() => {
    const changed = checked === !!idleTimeout;
    if (changed) {
      // update idle timeout based on remember me selection.
      setIdleTimeout(checked ? undefined : 'start');
    }
  }, [checked, idleTimeout, setIdleTimeout]);

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        id="userSettingsRememberMe"
        name="userSettingsRememberMe"
        label="Remember Me"
        style={{ width: 'fit-content', gap: '10px' }}
      />
    </div>
  );
}

export default InputRememberMe;
