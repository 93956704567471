/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Flex from './Flex';
import Typography from './Typography';

// Styles.
const ErrorContainer = styled(Flex)`
  background: ${({ theme }) => theme.palette.thunderbird};
  border-radius: 2px;
  padding: 18px 14px;
  margin-bottom: 10px;
  align-items: center;
  column-gap: 16px;
  & p {
    font-size: 14.5px;
    font-weight: 300;
    white-space: pre-line;
    line-height: 17px;
  }
  & svg {
    width: 22px;
    height: 22px;
  }
`;

export default function Error({ errorMessage, showIcon, visible, ...props }) {
  return (
    <ErrorContainer
      style={{ visibility: visible ? 'visible' : 'hidden' }}
      {...props}
    >
      {showIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <g clipPath="url(#clip0_2229_16884)">
            <path
              d="M9.00098 16.5C4.85873 16.5 1.50098 13.1423 1.50098 9C1.50098 4.85775 4.85873 1.5 9.00098 1.5C13.1432 1.5 16.501 4.85775 16.501 9C16.501 13.1423 13.1432 16.5 9.00098 16.5ZM9.00098 15C10.5923 15 12.1184 14.3679 13.2436 13.2426C14.3688 12.1174 15.001 10.5913 15.001 9C15.001 7.4087 14.3688 5.88258 13.2436 4.75736C12.1184 3.63214 10.5923 3 9.00098 3C7.40968 3 5.88355 3.63214 4.75834 4.75736C3.63312 5.88258 3.00098 7.4087 3.00098 9C3.00098 10.5913 3.63312 12.1174 4.75834 13.2426C5.88355 14.3679 7.40968 15 9.00098 15ZM8.25098 5.25H9.75098V6.75H8.25098V5.25ZM8.25098 8.25H9.75098V12.75H8.25098V8.25Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2229_16884">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      <Typography text="white">{errorMessage}</Typography>
    </ErrorContainer>
  );
}

Error.defaultProps = {
  errorMessage: '',
  showIcon: false,
  visible: true
};

Error.propTypes = {
  errorMessage: PropTypes.string,
  showIcon: PropTypes.bool,
  visible: PropTypes.bool
};
