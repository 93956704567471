/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { GET } = doFetch.methods;
const queue = [];
const isRolodexRefresh = { refreshing: false };

async function getUserRolodex() {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  if (!hospitalId) {
    return Promise.resolve({});
  }
  if (isRolodexRefresh.refreshing) {
    return new Promise((resolve) => {
      queue.push(resolve);
    });
  }

  isRolodexRefresh.refreshing = true;

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(`${uri}/user?client_id=${hospitalId}`, {
    method: GET,
    headers: {
      Authorization: authorization,
      'Content-Type': 'application/json'
    },
    doNotRedirectOnError: true
  })
    .then((data) => {
      isRolodexRefresh.refreshing = false;
      queue.forEach((resolve) => resolve(data));
      return data;
    })
    .catch((error) => {
      isRolodexRefresh.refreshing = false;
      queue.forEach((resolve) => resolve(error));
      return error;
    });
}
export default getUserRolodex;
