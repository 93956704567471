/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';

const { PUT } = doFetch.methods;

const uploadAttachment = async (s3_file_url, fileObject) => {
  doFetch(s3_file_url, {
    method: PUT,
    headers: {
      'Content-Type': fileObject.type,
      'Content-Disposition': `attachment; filename=${fileObject.name}`
    },
    body: fileObject,
    doNotRedirectOnError: true
  });
};
export default uploadAttachment;
