import { rest } from 'msw';
import { ENV } from '@Utils/envTools';
import taskData from './data/tasks.json';

const { NEO_API, CLIENT_ID } = ENV;
const handlers = [
  // Intercept all requests to the "/user" endpoint
  // regardless of their method, and respond with a "403 Forbidden" response.
  rest.all(`${NEO_API}/user`, (req, res, ctx) => res(ctx.status(403))),
  rest.get(`${NEO_API}/client_id`, (req, res, ctx) => {
    const clientId = req.referrer.includes('localhost:300') && CLIENT_ID;
    if (clientId) {
      return res(ctx.json({ status: 'success', clientId: CLIENT_ID }));
    }
    return res(ctx.status(200), ctx.json({ status: 'error', clientId: '' }));
  }),
  rest.post(`${NEO_API}/tasks`, async (req, res, ctx) => {
    const { taskType, itemCount, pageNumber = 0 } = await req.json();
    const records = taskType
      ? taskData.records.filter((rec) => rec.task_type === taskType) || []
      : taskData.records || [];

    const itemStart = pageNumber * itemCount;
    const totalPages = Math.ceil(records.length / itemCount);
    let thisPage = pageNumber;
    if (thisPage > totalPages) thisPage = totalPages;
    const metadata = {
      page: thisPage,
      per_page: itemCount,
      page_count: totalPages,
      total_count: records.length
    };
    return res(
      ctx.status(200),
      ctx.json({
        metadata,
        records: records.slice(itemStart, itemStart + itemCount)
      })
    );
  }),
  rest.post(`${NEO_API}/patients`, async (req, res, ctx) => {
    const { filters, itemCount, pageNumber = 0 } = await req.json();

    let { records } = taskData;
    // TODO this filtering should be done through the API...
    const { lastName, mrn } = filters;
    if (lastName && lastName.trim().length > 0) {
      records = taskData.records.filter((patient) =>
        patient.last_name.toLowerCase().includes(lastName.toLowerCase())
      );
    }
    if (mrn && mrn.trim().length > 0) {
      records = taskData.records.filter(
        (patient) => patient.id.toString() === mrn.toString()
      );
    }

    const itemStart = pageNumber * itemCount;
    const totalPages = Math.ceil(records.length / itemCount);
    let thisPage = pageNumber;
    if (thisPage > totalPages) thisPage = totalPages;
    const metadata = {
      page: thisPage,
      per_page: itemCount,
      page_count: totalPages,
      total_count: records.length
    };

    return res(
      ctx.status(200),
      ctx.json({
        metadata,
        records: records.slice(itemStart, itemStart + itemCount)
      })
    );
  })
];

export default handlers;
