import styled from '@emotion/styled';
import PropTypes, { node, oneOfType, arrayOf } from 'prop-types';
import { forwardRef } from 'react';

const INPUT_SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

const StyledInput = styled.input`
  border: ${({ theme, error }) =>
    error
      ? `1px solid ${theme.palette.thunderbird}`
      : `1px solid ${theme.palette.geyser}`};
  background-color: ${({ theme }) => theme.palette.white};
  min-height: 38px;
  color: ${({ theme }) => theme.palette.mineshaft};
  padding: 8px 7px;
  border-radius: 2px;
  outline-color: ${({ theme, error }) =>
    error ? theme.palette.thunderbird : 'initial'};

  &:disabled {
    background-color: ${({ theme }) => theme.palette.selago};
    color: ${({ theme }) => theme.palette.submarine};
  }
`;

const StyledTextarea = styled.textarea`
  border: ${({ theme, error }) =>
    error
      ? `1px solid ${theme.palette.thunderbird}`
      : `1px solid ${theme.palette.geyser}`};
  background-color: ${({ theme }) => theme.palette.white};
  min-height: 32px;
  color: ${({ theme }) => theme.palette.mineshaft};
  padding: 8px 7px;
  border-radius: 2px;
  height: 130px;

  &:disabled {
    background-color: ${({ theme }) => theme.palette.selago};
    color: ${({ theme }) => theme.palette.submarine};
  }
`;

const StyledFormLabel = styled.label`
  color: ${({ theme }) => theme.palette.mineshaft};
  font-weight: 700;
`;

const StyledFormLabelCheckbox = styled.label`
  color: ${({ theme }) => theme.palette.mineshaft};
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledRequiredForm = styled.label`
  color: ${({ theme }) => theme.palette.blaze};
  font-weight: 700;
  padding: 0 4px;
`;

const FormLabel = forwardRef(
  ({ id, name, label, required, children, ...props }, ref) => {
    if (children?.props?.type === 'checkbox') {
      return (
        <StyledFormLabelCheckbox ref={ref} name={name} htmlFor={id} {...props}>
          {children}
          {label}
          {required && <StyledRequiredForm>*</StyledRequiredForm>}
        </StyledFormLabelCheckbox>
      );
    }
    return (
      <StyledFormLabel id={id} ref={ref} name={name} {...props}>
        {label}
        {required && <StyledRequiredForm>*</StyledRequiredForm>}
        {children}
      </StyledFormLabel>
    );
  }
);

FormLabel.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: oneOfType([arrayOf(node), node])
};

FormLabel.defaultProps = {
  id: 'my-label',
  name: 'my-input',
  label: null,
  required: false,
  children: null
};

const FormInput = forwardRef(
  ({ id, name, label, type, size, placeholder, ...props }, ref) => (
    <StyledInput
      id={id}
      ref={ref}
      name={name}
      type={type}
      aria-label={label}
      placeholder={placeholder}
      {...props}
    />
  )
);

FormInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(INPUT_SIZE)),
  placeholder: PropTypes.string
};

FormInput.defaultProps = {
  id: 'my-input',
  name: 'my-input',
  label: null,
  type: 'text',
  text: null,
  size: INPUT_SIZE.MEDIUM,
  placeholder: null
};

const FormTextarea = forwardRef(
  ({ id, name, label, type, size, placeholder, ...props }, ref) => (
    <StyledTextarea
      id={id}
      ref={ref}
      name={name}
      type={type}
      aria-label={label}
      placeholder={placeholder}
      {...props}
    />
  )
);

FormTextarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(INPUT_SIZE)),
  placeholder: PropTypes.string
};

FormTextarea.defaultProps = {
  id: 'my-textarea',
  name: 'my-textarea',
  label: null,
  type: 'textarea',
  text: null,
  size: INPUT_SIZE.MEDIUM,
  placeholder: null
};

export { FormInput, FormLabel, FormTextarea };
