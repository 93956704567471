/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ENV } from '@Utils/envTools';
import postPatientNoteUpdate from '@Utils/api/postPatientNoteUpdate';
import putPatientNoteUpdate from '@Utils/api/putPatientNoteUpdate';
import deletePatientNote from '@Utils/api/deletePatientNote';
import {
  selectedPatientState,
  userInfoState,
  rolodexState
} from '@Utils/atoms';
import { Flex, Button, ConfirmModal } from '@Components/NeosomaUI';
import { FormLabel } from '@Components/NeosomaUI/Form/Input';

import { RiSendPlane2Fill, RiDeleteBin7Fill } from 'react-icons/ri';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import PatientSummaryEditNote from './PatientSummaryEditNote';

const NotesContainer = styled(Flex)`
  --view-bg-color: ${({ theme, userCanView }) =>
    userCanView ? 'inherit' : theme.palette.antiflashwhite};
  height: 182px;
  & .patient-summary-notes-prev-notes {
    position: relative;
    height: 100%;
    & .patient-summary-notes-prev-view {
      background-color: var(--view-bg-color);
      border: 1px solid ${({ theme }) => theme.palette.geyser};
      border-radius: 2px;
      overflow: auto;
      padding: 1px 16px;
      height: 100%;
      & ol {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    & .patient-summary-notes-prev-footer,
    & .patient-summary-notes-prev-header {
      position: absolute;
      height: 6px;
      background-color: var(--view-bg-color);
      bottom: 1px;
      left: 1px;
      width: calc(100% - 2px);
    }
    & .patient-summary-notes-prev-header {
      top: 1px;
    }
  }
  & .patient-summary-notes-add-notes {
    display: flex;
    gap: 8px;
  }

  .patient-summary-list-item {
    border-top: 1px solid ${({ theme }) => theme.palette.geyser};
    &.patient-summary-list-item:last-of-type {
      border-top: 1px solid transparent;
    }
  }

  & input {
    &:focus {
      outline: none;
    }
    border: 1px solid ${({ theme }) => theme.palette.bombay};
    border-radius: 2px;
    flex-grow: 1;
    padding: 4px 8px;
    &:disabled {
      border-color: ${({ theme }) => theme.palette.platinum} !important;
    }
  }
  & button {
    padding: 3px 10px;
    height: fit-content;
    &:disabled {
      background-color: ${({ theme }) => theme.palette.azure} !important;
    }
  }
`;

const NoteContainer = styled.li`
  display: flex;
  gap: 8px;
  padding: 4px 0;
  & .note-avatar {
    display: flex;
    justify-content: center;
    align-items: top;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.lotion};
  }
`;

const Note = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 12px;
  &div {
    flex-grow: 1;
  }
  & .note-meta-data {
    display: flex;
    justify-content: space-between;
  }
  & .note-username,
  & .note-timestamp {
    font-size: 9px;
  }
  & .note-timestamp {
    color: ${({ theme }) => theme.palette.dustygray};
  }

  & .note-content {
    white-space: pre-wrap; // preserve line breaks
    display: flex;
    justify-content: space-between;
    position: relative;
    & button {
      right: 0;
      bottom: 2px;
      position: absolute;
      background-color: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.eclipse};
      border: none;
      padding: 0 8px;
      padding-top: 3px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
`;

const AddNoteButton = styled(Button)`
  align-items: center;
`;

export const WarningIcon = styled('div')`
  content: '!';
  position: absolute;
  font-weight: 600;
  border: 1px solid #d59624;
  height: 14px;
  width: 13px;
  right: 6px;
  margin-top: 4px;
  border-radius: 3px;
  text-align: center;
  background-color: rgb(255 202 60);
  font-size: 10px;
  line-height: 13px;
  cursor: default;
  user-select: none;
  z-index: 1;
`;

const isBeforeToday = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return new Date(date) < today;
};

// *-------------------------------*
// |  COMPONENT :: FormItem        |
// *-------------------------------*
export function FormItem({ label, name, children }) {
  return (
    <Flex direction="column" gap="8px" fullwidth>
      <FormLabel label={label} name={name} />
      {children}
    </Flex>
  );
}

// *-------------------------------*
// |  COMPONENT :: SummaryNote     |
// *-------------------------------*
function SummaryNote({ note, shiftHeld, handleDelete }) {
  const rolodex = useRecoilValue(rolodexState);
  const displayDate = (() => {
    const thisDate = new Date(note.date_created);
    if (isBeforeToday(thisDate)) {
      const [hr, min, sec] = thisDate.toLocaleTimeString().split(':');
      const [month, day, year] = thisDate.toLocaleDateString().split('/');
      const todayYear = new Date().getFullYear();
      let showYear = false;
      if (year < todayYear) {
        showYear = true;
      }
      return `${hr}:${min} ${
        sec.split(' ').length > 1 ? sec.split(' ')[1] : ''
      } ${`0${month}`.slice(-2)}/${`0${day}`.slice(-2)}${
        showYear ? `/${year}` : ''
      }`;
    }

    const [hr, min, sec] = thisDate.toLocaleTimeString().split(':');
    return `${hr}:${min} ${sec.split(' ').length > 1 ? sec.split(' ')[1] : ''}`;
  })();
  const creatorUserInfo = (rolodex || {})[note.user_id] || {
    first_name: '',
    last_name: ''
  };
  const creatorName = `${creatorUserInfo.first_name}${
    creatorUserInfo.first_name
      ? `${creatorUserInfo.last_name ? ` ${creatorUserInfo.last_name}` : ''}`
      : creatorUserInfo.last_name
  }`;

  return (
    <NoteContainer id={`note-${note.id}`}>
      <Note>
        <div className="note-meta-data">
          <div className="note-username">{creatorName || '--'}</div>
          <div className="note-timestamp">{displayDate}</div>
        </div>
        <div className="note-content">
          <div>{note.notes}</div>
          {userPrivs.DELETE_PATIENT_NOTES && shiftHeld && (
            <button
              type="button"
              onClick={() => handleDelete(note.id)}
              title="Remove this message"
            >
              <RiDeleteBin7Fill size="12px" />
            </button>
          )}
        </div>
      </Note>
    </NoteContainer>
  );
}

// *-------------------------------*
// |  COMPONENT :: SummaryNotes    |
// *-------------------------------*
function SummaryNotes({ patient }) {
  const [canEdit] = useState(userPrivs.EDIT_PATIENT_NOTES);
  const [canAdd] = useState(userPrivs.CREATE_PATIENT_NOTES);
  const [canView] = useState(userPrivs.VIEW_PATIENT_NOTES);
  const inputRef = useRef(null);
  const { notes = [] } = patient;
  const [shiftHeld, setShiftHeld] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [saving, setSaving] = useState(false);
  const [savingEdit, setSavingEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const userInfo = useRecoilValue(userInfoState);
  const [hovered, setHovered] = useState(false);

  // Edit Note vars
  const [showConfirm, setShowConfirm] = useState(false);
  const [editedText, setEditedText] = useState('');
  const [noteToEdit, setNoteToEdit] = useState(null);
  const [editError, setEditError] = useState(false);
  const [, setAddError] = useState(false);

  // Delete Note vars
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteInprogress, setDeleteInprogress] = useState(false);

  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);

  const noteAdd = useMutation(postPatientNoteUpdate, {
    // TODO: handle errors
    onSuccess: (newData) => {
      /* note data 
        "id": "1476XIPPIX",
        "hospital_id": "7DIRURGFUQ",
        "patient_id": "1075XZNK7X",
        "notes": "asfd",
        "date_created": "2024-01-02T14:37:32.000Z",
        "user_id": "18XQRTW2H4",
        "date_updated": null,
        "update_user_id": null,
        "deleted": 0
      */
      const hospitalId = ENV.CLIENT_ID;

      const thisNote = {
        id: newData?.patient_note_id,
        hospital_id: hospitalId,
        patient_id: patient.id,
        notes: newNote.trim(),
        date_created: new Date().toISOString(),
        user_id: userInfo.id
      };
      // update global state.
      setSelectedPatient({
        ...selectedPatient,
        [patient.id]: {
          ...selectedPatient[patient.id],
          notes: [...selectedPatient[patient.id].notes, thisNote]
        }
      });
      setSaving(false);
      setDisabled(false);
      setNewNote('');
      setTimeout(() => {
        const noteElement = document.getElementById(`note-${thisNote.id}`);
        if (noteElement) {
          noteElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
        }
        inputRef.current.focus();
      }, 100);
    },
    onError: () => {
      setSaving(false);
      setDisabled(false);
    }
  });

  const noteEdit = useMutation(putPatientNoteUpdate, {
    // TODO: handle errors
    onSuccess: (foo) => {
      if (
        foo &&
        Object.prototype.hasOwnProperty.call(foo, 'status') &&
        foo.status.type === 'error'
      ) {
        setEditError(noteToEdit.id);
        setSavingEdit(false);
        setDisabled(false);
        return;
      }

      const thisNote = {
        ...noteToEdit,
        notes: editedText.trim()
      };
      // update global state.
      setSelectedPatient({
        ...selectedPatient,
        [patient.id]: {
          ...selectedPatient[patient.id],
          notes: selectedPatient[patient.id].notes.map((n) =>
            n.id === thisNote.id ? thisNote : n
          )
        }
      });
      setSavingEdit(false);
      setDisabled(false);
      setShowConfirm(false);
    },
    onError: () => {
      setEditError(noteToEdit.id);
      setSavingEdit(false);
      setDisabled(false);
    }
  });

  const noteDelete = useMutation(deletePatientNote, {
    // TODO: handle errors
    onSuccess: (deleteRes) => {
      if (
        deleteRes &&
        Object.prototype.hasOwnProperty.call(deleteRes, 'status') &&
        deleteRes.status.type === 'error'
      ) {
        setDeleteError(noteToDelete);
      }

      // update global state.
      setSelectedPatient({
        ...selectedPatient,
        [patient.id]: {
          ...selectedPatient[patient.id],
          notes: selectedPatient[patient.id].notes.filter(
            (n) => n.id !== noteToDelete
          )
        }
      });
      setShowConfirmDelete(false);
      setDeleteInprogress(false);
    },
    onError: () => {
      setDeleteError(noteToDelete);
      setShowConfirmDelete(false);
      setDeleteInprogress(false);
    }
  });

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddNote();
    }
  };
  const handleNoteChange = (e) => {
    setNewNote(e.target.value);
  };

  const handleAddNote = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    const patientId = patient.id;
    if (patientId && newNote.trim().length > 0) {
      setSaving(true);
      setAddError(false);
      noteAdd.mutate({ patientId, notes: newNote.trim() });
    }
  };

  const openModal = (note) => {
    setEditedText(note.notes);
    setNoteToEdit(note);
    setShowConfirm(true);
  };

  const handleCancelEdit = () => {
    setEditError(false);
    setShowConfirm(false);
  };

  const handleConfirmEdit = (e, newText) => {
    e?.stopPropagation();
    e?.preventDefault();
    const patientId = patient.id;

    if (patientId && noteToEdit.notes.length > 0) {
      setSavingEdit(true);
      setEditError(false);
      noteEdit.mutate({
        patientId,
        notes: (e.target.value || editedText).trim(),
        noteId: noteToEdit.id
      });
    }
  };

  const handleConfirmDelete = () => {
    const patientId = patient.id;
    setDeleteInprogress(true);
    noteDelete.mutate({ patientId, noteId: noteToDelete });
  };
  const handleCancelDelete = () => {
    setDeleteInprogress(false);
    setShowConfirmDelete(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.shiftKey) {
        setShiftHeld(true);
      }
    };
    const handleKeyUp = (e) => {
      if (!e.shiftKey) {
        setShiftHeld(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  return (
    <FormItem label="Notes" name="Notes">
      <ConfirmModal
        isShowing={showConfirmDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this note?"
        disableButtons={deleteInprogress ? 'showSpinner' : false}
        confirmText="Delete Note"
        spinnerText="Deleting..."
      />
      <ConfirmModal
        isShowing={showConfirm}
        onCancel={(e) => handleCancelEdit()}
        onConfirm={handleConfirmEdit}
        title="Edit Note"
        message={
          <PatientSummaryEditNote
            note={noteToEdit}
            setEditedText={setEditedText}
            editedText={editedText}
            errorMessage={
              noteToEdit?.id === editError
                ? 'Note update failed, please try again.'
                : ''
            }
          />
        }
        disableButtons={savingEdit ? 'showSpinner' : false}
        disablePrimary={
          editedText === noteToEdit?.notes || editedText?.length < 1
        }
        confirmText="Update Note"
        spinnerText="Saving Changes..."
      />
      <NotesContainer direction="column" gap="3px" userCanView={canView}>
        <div
          className="patient-summary-notes-prev-notes"
          onMouseEnter={(e) => {
            setHovered(true);
          }}
          onMouseLeave={(e) => {
            setHovered(false);
          }}
        >
          <div className="patient-summary-notes-prev-header" />
          <div className="patient-summary-notes-prev-view">
            <ol>
              {canView &&
                notes.map((note) => (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    key={note.id}
                    onClick={(e) => {
                      if (e.detail === 2 && canEdit) openModal(note);
                    }}
                    onKeyDown={(e) => {
                      e.key === 'Enter' && openModal(note);
                    }}
                    className="patient-summary-list-item"
                  >
                    {note.id === deleteError && (
                      <WarningIcon title="Note failed to delete, please try again">
                        !
                      </WarningIcon>
                    )}
                    <SummaryNote
                      note={note}
                      shiftHeld={shiftHeld && hovered}
                      handleDelete={(noteId) => {
                        setNoteToDelete(noteId);
                        setShowConfirmDelete(true);
                      }}
                    />
                  </div>
                ))}
            </ol>
          </div>
          <div className="patient-summary-notes-prev-footer" />
        </div>
        {canAdd && (
          <div className="patient-summary-notes-add-notes">
            <input
              ref={inputRef}
              value={newNote}
              onChange={handleNoteChange}
              onKeyDown={handleInputKeyDown}
              type="text"
              placeholder="Enter Patient Note (Hit Enter to Save)...."
              className="patient-summary-notes__input"
              disabled={noteAdd.isLoading || saving}
            />
            <AddNoteButton
              variant="primary"
              type="button"
              onClick={handleAddNote}
              disabled={noteAdd.isLoading || saving || disabled}
            >
              {noteAdd.isLoading ? 'Adding...' : 'Add Note'}&nbsp;&nbsp;
              <RiSendPlane2Fill style={{ paddingTop: '1px' }} />
            </AddNoteButton>
          </div>
        )}
      </NotesContainer>
    </FormItem>
  );
}

export default SummaryNotes;
