import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, Flex } from '@Components/NeosomaUI';
import { IoMdArrowRoundBack } from 'react-icons/io';
import UserSettingsImage from './UserSettingsImage';
import UserSettingsControls from './UserSettingsControls';
import VersionInfoCard from './VersionInfoCard';
import ReleaseNotes from './ReleaseNotes';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.athensgray};
  flex: 1;
  padding: 40px 40px 90px 40px;
  align-items: center;
  gap: 18px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 32px;
  max-width: 910px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  & button {
    margin-top: 10px;
    height: 26px;
  }
`;

const ButtonContainer = styled(Flex)`
  max-width: 734px;
  width: 100%;
  & button {
    font-size: 15px !important;
    padding: 2px;
  }
`;

export default function UserSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== 'default';
  const handleBack = () => {
    if (doesAnyHistoryEntryExist && window.history.state.idx !== 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: false });
    }
  };

  return (
    <MainContainer>
      <ButtonContainer>
        <Button type="button" variant="link" onClick={handleBack}>
          <IoMdArrowRoundBack size="16px" />
          &nbsp;
          {window.history.state.idx !== 0 ? 'Back to previous' : 'Go to home'}
        </Button>
      </ButtonContainer>
      <ContentContainer>
        <LeftContainer>
          <UserSettingsImage />
          <ReleaseNotes />
          <VersionInfoCard />
        </LeftContainer>
        <UserSettingsControls />
      </ContentContainer>
    </MainContainer>
  );
}
