import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import useModal from './UseModal';

export default function ConfirmModal({
  isShowing,
  onConfirm,
  onCancel,
  title,
  message,
  confirmText,
  cancelText,
  spinnerText,
  obscureBackground,
  hideFooter,
  hideHeaderCloseButton,
  style,
  disablePrimary,
  disableButtons
}) {
  const { isShowing: warningIsVisible, setIsShowing } = useModal();
  useEffect(() => {
    setIsShowing(isShowing);
  }, [isShowing, setIsShowing]);

  return (
    <Modal
      isShowing={warningIsVisible}
      hide={setIsShowing}
      title={title}
      buttonText={confirmText}
      cancelText={cancelText}
      onCancelClick={onCancel}
      onPrimaryClick={onConfirm}
      obscureBackground={obscureBackground}
      hideFooter={hideFooter}
      hideHeaderCloseButton={hideHeaderCloseButton}
      externalDisablePrimary={disablePrimary}
      disableButtons={disableButtons}
      spinnerText={spinnerText}
      style={{
        width: '400px',
        marginTop: '200px',
        boxShadow: '0 0 30px #00000080',
        ...style
      }}
    >
      <span>{message}</span>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isShowing: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  obscureBackground: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideHeaderCloseButton: PropTypes.bool,
  disablePrimary: PropTypes.bool,
  disableButtons: PropTypes.bool,
  spinnerText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

ConfirmModal.defaultProps = {
  isShowing: false,
  onConfirm: () => {
    /* NoOp */
  },
  onCancel: () => {
    /* NoOp */
  },
  title: 'Warning',
  message: 'Are you sure?',
  confirmText: 'Okay',
  cancelText: 'Cancel',
  obscureBackground: false,
  hideFooter: false,
  hideHeaderCloseButton: false,
  disablePrimary: false,
  disableButtons: false,
  spinnerText: '',
  style: {}
};
