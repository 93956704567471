import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { RxReload } from 'react-icons/rx';

const RefreshContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  width: 300px;
`;

const RefreshButton = styled.button`
  padding: 6px;
  border-radius: 1px;
  border: 0.5px solid ${({ theme }) => theme.palette.lightsilver};
  background: ${({ theme }) => theme.palette.brightgray};
  height: 32px;
  width: 32px;
  color: #464646;

  &:hover {
    background: ${({ theme }) => theme.palette.platinum};
  }

  & .spin {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

function TaskListReloadButton({ isLoading, onClick }) {
  const [showSpinner, setShowSpinner] = useState(false);

  const handleClick = () => {
    setShowSpinner(true);
    onClick && onClick();
    setTimeout(() => {
      setShowSpinner(false);
    }, 2000);
  };

  useEffect(() => {
    if (!isLoading) {
      setShowSpinner(false);
    }
    if (isLoading) {
      setShowSpinner(true);
    }
  }, [isLoading]);

  return (
    <RefreshContainer>
      <RefreshButton onClick={handleClick}>
        <RxReload
          className={showSpinner ? 'spin' : ''}
          size="18px"
          style={{
            color: '#6B6B6B'
          }}
        />
      </RefreshButton>
    </RefreshContainer>
  );
}

TaskListReloadButton.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func
};

TaskListReloadButton.defaultProps = {
  isLoading: false,
  onClick: undefined
};

export default TaskListReloadButton;
