// Package modules.
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

function shouldForwardProp(prop) {
  return ![
    'alignItems',
    'direction',
    'gap',
    'inline',
    'justifyContent',
    'wrap',
    'fullwidth'
  ].includes(prop);
}

// Constants.
const DEFAULT_GAP = '0px';

// Styles.
const StyledFlex = styled('div', { shouldForwardProp })`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : null)};
  ${({ gap }) =>
    gap !== DEFAULT_GAP &&
    css`
      --gapX: ${(typeof gap === 'object' ? gap.x : gap) || DEFAULT_GAP};
      --gapY: ${(typeof gap === 'object' ? gap.y : gap) || DEFAULT_GAP};

      gap: var(--gapY) var(--gapX);
    `};
  flex-grow: ${({ grow }) => grow};
`;

const Flex = forwardRef((props, ref) => <StyledFlex ref={ref} {...props} />);

export default Flex;

Flex.defaultProps = {
  alignItems: 'normal',
  direction: 'initial',
  inline: false,
  gap: DEFAULT_GAP,
  grow: 0,
  justifyContent: 'normal',
  wrap: false
};

Flex.propTypes = {
  alignItems: PropTypes.string,
  direction: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool,
  justifyContent: PropTypes.string,
  wrap: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
