/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { PUT } = doFetch.methods;

const putPatientUpdate = async ({ patientId, changesPayload }) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(
    `${uri}/patient/patient?client_id=${hospitalId}&patient_id=${patientId}`,
    {
      method: PUT,
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: patientId, ...changesPayload }),
      doNotRedirectOnError: true
    }
  );
};
export default putPatientUpdate;
