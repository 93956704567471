import { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { ENV } from '@Utils/envTools';
import { Typography } from '@Components/NeosomaUI';

function getSubdomain() {
  let domain = window.location.hostname;
  if (window.location.hostname.includes('://')) {
    [, domain] = window.location.hostname.split('://');
  }
  const subdomain = domain.split('.')[0];
  return subdomain;
}

function getDomainTitle() {
  return ENV.DOMAIN_TITLE || getSubdomain();
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-height: 100px;
  max-width: 450px;
  margin-bottom: 46px;
  h2 {
    font-size: 28px;
    font-weight: 700;
  }
  animation: fadeInAnimation ease 550ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  max-height: 100px;
  max-width: 450px;
`;

function BrandingImg() {
  const configLoaded = ENV.CLIENT_ID !== undefined;
  const [brandImgSrc, setBrandImgSrc] = useState(null);
  const [slightDelay, setSlightDelay] = useState();
  const [noImage, setNoImage] = useState(false);
  const [domainTitle, setDomainTitle] = useState('');

  useEffect(() => {
    if (configLoaded && ENV.BRAND_LOGO_IMAGE) {
      setNoImage(false);
      setBrandImgSrc(`/configs/images/${ENV.BRAND_LOGO_IMAGE}`);
    } else {
      setBrandImgSrc(null);
      setNoImage(true);
      setTimeout(() => setDomainTitle(getDomainTitle()), 120);
    }
    setTimeout(() => setSlightDelay(true), 50);
  }, [configLoaded, slightDelay, setSlightDelay]);

  return (
    <ImageContainer>
      {!noImage && (
        <Image src={brandImgSrc} alt="" onError={() => setNoImage(true)} />
      )}
      {noImage && domainTitle && (
        <Typography variant="h2">{`${`Welcome to ${getDomainTitle().replace(
          'Redirect',
          'Neosoma'
        )}`}`}</Typography>
      )}
    </ImageContainer>
  );
}

export default BrandingImg;
