import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { differenceInYears, isDate } from 'date-fns';
import DatePicker from 'react-datepicker';
import { FaRegCalendarAlt, FaTimes } from 'react-icons/fa';

import getPatientData from '@Utils/api/getPatientData';
import { Button, Flex, Typography } from '@Components/NeosomaUI';
import InlineSpinner from '@Components/NeosomaUI/InlineSpinner/InlineSpinner';
import { FormInput, FormLabel } from '@Components/NeosomaUI/Form/Input';
import Table from '@Components/NeosomaUI/Table/Table';
import { defaultTheme } from '@Components/NeosomaUI/theme';
import { getSlashedDatetime, maskPII } from '@Utils/common';
import { IoMdArrowRoundBack } from 'react-icons/io';

const PatientSearchContainer = styled(Flex)`
  overflow: hidden;
  position: relative;
  padding: 32px;
  background-color: ${({ theme }) => theme.palette.catskillwhite};
  flex: 1;
  min-width: 750px;
  & input {
    border: 1px solid #d6d6d6;
  }
  & input:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
  }
  & .react-datepicker-wrapper input {
    height: 38px;
    width: 100%;
    padding-left: 35px;
  }
  & h2 {
    font-size: 18px;
  }
  & tr:hover {
    background-color: ${({ theme }) => theme.palette.selago};
  }
`;

const PatientSearchFields = styled.div`
  padding: 17px 24px;
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  background-color: ${({ theme }) => theme.palette.geyser};
  margin: 16px 0 58px 0;
`;

const PatientSearchTableContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  background-color: ${({ theme }) => theme.palette.white};
  padding: 16px;
  margin-top: 18px;
`;

const FormRow = styled(Flex)`
  width: 100%;
  [data-lastpass-icon-root] {
    display: none !important;
  }
`;

const PatientSearchInputs = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 30px;
`;

const PatientSearchControls = styled(Flex)`
  width: 20%;
  padding: 31px 0 31px 31px;
  border-left: 1px solid ${({ theme }) => theme.palette.submarine};
`;

const Spinner = styled(InlineSpinner)`
  width: 100%;
  justify-content: flex-start;
  position: absolute;
  left: 40px;
  min-height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 0;
  z-index: 10;
`;

const ButtonContainer = styled(Flex)`
  max-width: 734px;
  width: 100%;
  & button {
    font-size: 15px !important;
    padding: 2px;
  }
  margin-bottom: 20px;
`;

const ToastMessage = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 5px;
`;

const ToastMessageCard = styled.div`
  white-space: pre-wrap;
  background-color: white;
  padding: 23px 30px;
  border: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-radius: 3px;
  box-shadow: 0px 4px 25px ${({ theme }) => theme.palette.platinum};
  display: flex;
  position: relative;
  font-size: 14px;
`;

const CloseButton = styled.button`
  color: ${({ theme }) => theme.palette.abbey};
  position: absolute;
  right: 5px;
  top: 8px;
  display: flex;
  border: 0;
  background-color: transparent;
`;

const SEARCH_RESULTS_COLUMNS = [
  {
    Header: 'Patient Name',
    id: 'name',
    accessor: (d) =>
      `${maskPII(d.last_name)}${
        d.last_name && d.first_name ? ', ' : ''
      }${maskPII(d.first_name)}`
  },
  {
    Header: 'MRN',
    accessor: (d) => `${maskPII(d.patient_iuid)}`
  },
  {
    Header: 'DOB',
    id: 'dob',
    accessor: (d) =>
      d.age_type === 0 ? maskPII(getSlashedDatetime(d.dob)) : '--'
  },
  {
    Header: 'Age',
    id: 'age',
    accessor: (d) =>
      `${
        isDate(new Date(d.dob)) && d.age_type !== 2
          ? differenceInYears(new Date(), new Date(d.dob))
          : '--'
      }${d.dob ? ' YO' : ''}`
  },
  {
    Header: 'Sex',
    accessor: 'sex'
  },
  {
    Header: 'Diagnosis',
    accessor: 'diagnosis'
  },
  {
    Header: 'Stage',
    accessor: 'diagnosis_grade'
  },
  {
    Header: 'Last Scan',
    id: 'latest_scan_date',
    accessor: (d) => getSlashedDatetime(d.latest_scan_date)
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (d) => 'Active' // Set to always active for now NUI-215
  }
];

export default function PatientSearch() {
  const [patientSearchData, setPatientSeachData] = useState();
  const [isTopLevel] = useState(window.history.state.idx === 0);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const patientDataLoader = useMutation(
    (filters = {}) => {
      const filter = Object.keys(filters).reduce((obj, key) => {
        if (filters[key]) {
          return {
            ...obj,
            [key]: filters[key]
          };
        }
        return obj;
      }, {});
      return getPatientData(filter);
    },
    {
      onSuccess: (data) => {
        setPatientSeachData(data);
      }
    }
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty }
  } = useForm();

  // this is the data from the form...
  const onSubmit = (data) => {
    const newData = { ...data };
    if (data.dob) {
      // convert to UTC to prevent timezone issues
      const utcDOB = new Date(
        Date.UTC(
          data.dob.getUTCFullYear(),
          data.dob.getUTCMonth(),
          data.dob.getUTCDate()
        )
      );
      const [newDOB] = utcDOB.toISOString().split('T');
      newData.dob = newDOB;
    }
    setPatientSeachData(undefined);
    patientDataLoader.mutate(newData);
  };

  const onClearClick = (e) => {
    e.preventDefault();
    reset();
    setPatientSeachData(undefined);
  };

  const handleClick = (event, id) => {
    if (event.detail === 2) {
      navigate(`/patients/${id}`);
    }
  };

  const doesAnyHistoryEntryExist = location.key !== 'default';
  const handleBack = () => {
    if (doesAnyHistoryEntryExist && !isTopLevel) {
      navigate(-1);
    } else {
      navigate('/', { replace: false });
    }
  };

  const handleDismiss = () => {
    setMessage('');
  };

  useEffect(() => {
    if (location.state?.toastMessage) {
      setMessage(location?.state?.toastMessage);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.pathname, location?.state?.toastMessage, navigate]);

  const disableSearch = !isDirty || patientDataLoader.isLoading;

  return (
    <PatientSearchContainer direction="column">
      {message && (
        <ToastMessage>
          <ToastMessageCard>
            <div>{message}</div>
            <CloseButton onClick={handleDismiss}>
              <FaTimes />
            </CloseButton>
          </ToastMessageCard>
        </ToastMessage>
      )}
      <ButtonContainer>
        <Button type="button" variant="link" onClick={handleBack}>
          <IoMdArrowRoundBack size="16px" />
          {!isTopLevel ? ' Back to previous' : ' Go to home'}
        </Button>
      </ButtonContainer>
      <Typography variant="h2" uppercase bold>
        Patient Search
      </Typography>
      <PatientSearchFields>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex>
            <PatientSearchInputs>
              <FormRow gap="26px" alignItems="center">
                <Flex direction="column" gap="8px" fullwidth>
                  <FormLabel label="Last Name" name="last_name" />
                  <FormInput
                    disabled={patientDataLoader.isLoading}
                    id="LastName"
                    name="last_name"
                    style={{
                      WebkitTextSecurity:
                        sessionStorage.getItem('hidePII') === 'true'
                          ? 'disc'
                          : 'none'
                    }}
                    autoComplete="off"
                    {...register('last_name')}
                  />
                </Flex>
                <Flex direction="column" gap="8px" fullwidth>
                  <FormLabel label="MRN#" name="patient_iuid" />
                  <FormInput
                    disabled={patientDataLoader.isLoading}
                    id="mrn"
                    name="patient_iuid"
                    style={{
                      WebkitTextSecurity:
                        sessionStorage.getItem('hidePII') === 'true'
                          ? 'disc'
                          : 'none'
                    }}
                    autoComplete="off"
                    {...register('patient_iuid')}
                  />
                </Flex>
              </FormRow>
              <FormRow gap="26px" alignItems="center">
                <Flex direction="column" gap="8px" fullwidth>
                  <FormLabel label="DOB" name="dob" />
                  <Controller
                    control={control}
                    name="dob"
                    render={({ field }) => (
                      <DatePicker
                        disabled={patientDataLoader.isLoading}
                        portalId="root-portal"
                        selected={field.value}
                        dateFormat="MM/dd/yyyy"
                        id="dob"
                        autoComplete="off"
                        wrapperClassName={
                          sessionStorage.getItem('hidePII') === 'true'
                            ? 'hideDatePickerEntry'
                            : ''
                        }
                        onChange={(date) =>
                          setValue('dob', date, {
                            shouldDirty: true
                          })
                        }
                        showIcon
                        icon={
                          <div>
                            <FaRegCalendarAlt
                              color={defaultTheme.palette.shuttlegray}
                              size="20px"
                            />
                          </div>
                        }
                        showYearDropdown
                        showPopperArrow={false}
                      />
                    )}
                  />
                </Flex>
                <Flex direction="column" gap="8px" fullwidth />
              </FormRow>
            </PatientSearchInputs>
            <PatientSearchControls
              direction="column"
              gap="31px"
              justifyContent="space-evenly"
              fullwidth
            >
              <Button
                variant="primary"
                type="submit"
                disabled={disableSearch}
                style={{ position: 'relative' }}
              >
                {patientDataLoader.isLoading ? (
                  <>
                    <Spinner singelLoader />
                    Searching...
                  </>
                ) : (
                  'Search'
                )}
              </Button>
              <Button
                variant="primary-inverse"
                onClick={onClearClick}
                disabled={patientDataLoader.isLoading}
              >
                Clear
              </Button>
            </PatientSearchControls>
          </Flex>
        </form>
      </PatientSearchFields>
      {patientSearchData && (
        <>
          <Typography variant="h2" uppercase bold>
            SEARCH RESULTS ({patientSearchData.length})
          </Typography>
          <PatientSearchTableContainer>
            {patientSearchData.length ? (
              <Table
                columns={SEARCH_RESULTS_COLUMNS}
                data={patientSearchData}
                onRowClick={handleClick}
              />
            ) : (
              <Typography variant="h2" uppercase bold>
                No Items
              </Typography>
            )}
          </PatientSearchTableContainer>
        </>
      )}
    </PatientSearchContainer>
  );
}
