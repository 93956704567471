/** @jsxImportSource @emotion/react */
import {
  forwardRef,
  useRef,
  useState,
  createContext,
  useMemo,
  useContext,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { FaCaretRight } from 'react-icons/fa';

import { useDetectOutsideClick } from './helpers';
// eslint-disable-next-line import/no-cycle
import {
  DropdownItemButton,
  PrimaryStyle,
  PlainStyle,
  SecondaryStyle,
  SlimStyle,
  RelativeContainer,
  StyledButton,
  UpIcon,
  DownIcon,
  DropDownMenu,
  SlimTransparentStyle
} from './DropDownStyles';

// Constants.
export const DROP_DOWN_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SLIM: 'slim',
  SLIM_TRANSPARENT: 'slim_transpar',
  PLAIN: 'plain'
};

export const Context = createContext();

export function DropDownSubMenu({
  itemName,
  menuLabel,
  children,
  hideOnSelect = false
}) {
  const ref = useRef();
  const [isExpanded, setExpand] = useState(false);
  const handleExpandClick = (e) => {
    const isLabelItem = e.target.getAttribute('data-test') === 'dropdownLabel';
    // prevent minimize when clicking on label item
    if (!isLabelItem) setExpand(!isExpanded);
  };
  return (
    <DropdownItem hideOnSelect={hideOnSelect} onClick={handleExpandClick}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <button type="button">{itemName}</button>
        {menuLabel && <FaCaretRight />}
      </div>
      {isExpanded && (
        <DropDownMenu ref={ref} style={{ left: '-187px' }}>
          {menuLabel && (
            <DropdownItem data-test="dropdownLabel" hideOnSelect={false}>
              <button type="button">{menuLabel}</button>
            </DropdownItem>
          )}
          {children}
        </DropDownMenu>
      )}
    </DropdownItem>
  );
}

DropDownSubMenu.propTypes = {
  itemName: PropTypes.string,
  menuLabel: PropTypes.string,
  hideOnSelect: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

DropDownSubMenu.defaultProps = {
  itemName: '',
  menuLabel: '',
  hideOnSelect: false
};

export function DropdownItem({
  onClick,
  children,
  hideOnSelect: itemHideOnSelect,
  disabled,
  ...props
}) {
  const { hideOnSelect, setIsActive } = useContext(Context);
  const handleClick = (e) => {
    if (disabled) return;
    onClick(e);
    if (hideOnSelect && (itemHideOnSelect === undefined || itemHideOnSelect))
      setIsActive(false);
  };

  return (
    <DropdownItemButton
      type="button"
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </DropdownItemButton>
  );
}

DropdownItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  hideOnSelect: PropTypes.bool,
  disabled: PropTypes.bool
};

DropdownItem.defaultProps = {
  onClick: () => {
    /* no op */
  },
  children: null,
  hideOnSelect: undefined,
  disabled: false
};

export const DropDown = forwardRef(
  (
    {
      children,
      variant,
      buttonText,
      position,
      hideOnSelect,
      customPadding,
      externalShowMenu,
      externalRef,
      setExternalShowMenu,
      ...props
    },
    ref
  ) => {
    let variantStyle;
    switch (variant) {
      case DROP_DOWN_VARIANTS.PRIMARY:
        variantStyle = PrimaryStyle;
        break;
      case DROP_DOWN_VARIANTS.PLAIN:
        variantStyle = PlainStyle;
        break;
      case DROP_DOWN_VARIANTS.SECONDARY:
        variantStyle = SecondaryStyle;
        break;
      case DROP_DOWN_VARIANTS.SLIM:
        variantStyle = SlimStyle;
        break;
      case DROP_DOWN_VARIANTS.SLIM_TRANSPARENT:
        variantStyle = SlimTransparentStyle;
        break;
      default:
        variantStyle = PrimaryStyle;
    }
    const [isActive, setIsActive] = useState(false);
    const wrapperRef = useRef(null);
    useDetectOutsideClick(externalRef || wrapperRef, () => {
      setExternalShowMenu(undefined);
      setIsActive(false);
    });

    useEffect(() => {
      if (externalShowMenu !== undefined) setIsActive(externalShowMenu);
    }, [externalShowMenu]);

    return (
      <Context.Provider
        value={useMemo(() => ({ hideOnSelect, setIsActive }), [hideOnSelect])}
      >
        <RelativeContainer
          ref={wrapperRef}
          style={{ display: props.hidden ? 'none' : 'inherit' }}
        >
          <StyledButton
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            css={variantStyle}
            ref={ref}
            onClick={() => {
              setExternalShowMenu(!isActive);
              setIsActive(!isActive);
            }}
          >
            {buttonText}
            {isActive ? <UpIcon /> : <DownIcon />}
          </StyledButton>
          {isActive && (
            <DropDownMenu
              position={position}
              customPadding={customPadding}
              variant={variant}
            >
              {children}
            </DropDownMenu>
          )}
        </RelativeContainer>
      </Context.Provider>
    );
  }
);

DropDown.propTypes = {
  variant: PropTypes.oneOf(Object.values(DROP_DOWN_VARIANTS)),
  children: PropTypes.node,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string,
  hideOnSelect: PropTypes.bool,
  customPadding: PropTypes.string,
  hidden: PropTypes.bool,
  externalShowMenu: PropTypes.bool,
  setExternalShowMenu: PropTypes.func,
  externalRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

DropDown.defaultProps = {
  variant: DROP_DOWN_VARIANTS.PRIMARY,
  children: null,
  buttonText: 'Actions',
  position: 'left',
  hideOnSelect: false,
  customPadding: '12px',
  hidden: false,
  externalShowMenu: undefined,
  setExternalShowMenu: () => {
    /* no op */
  },
  externalRef: undefined
};
