import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { addAlpha } from './helpers';
import Typography from './Typography';
// eslint-disable-next-line import/no-cycle
import { DROP_DOWN_VARIANTS } from './DropDown';

// Styles.
export const RelativeContainer = styled.div`
  position: relative;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: ${({ theme }) => theme.components.button.text.size};
  font-weight: 400;
  line-height: 1.428;
  box-shadow: none;
  transition: 0.5s all;
  height: 30px;
  &:disabled,
  &[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:focus-visible,
  &:focus {
    outline-color: ${({ theme }) => theme.palette.yelloworange};
    outline-offset: 2px;
  }
`;

export const PrimaryStyle = ({ palette, components }) => css`
  background-color: ${palette.azure};
  color: ${components.button.text.primary};
  &:hover {
    background-color: ${palette.cerulean};
  }
`;

export const PlainStyle = ({ palette, components }) => css`
  color: ${components.button.text.primary};
  background-color: transparent;
  &:focus {
    outline: 0;
  }
`;

export const SecondaryStyle = ({ palette, components }) => css`
  background: ${palette.tangerine};
  color: ${components.button.text.secondary};
  &:hover {
    background: ${palette.yelloworange};
  }

  &:focus {
    outline: 1px solid ${palette.yelloworange};
  }
`;

export const SlimStyle = ({ palette, components }) => css`
  background: ${palette.antiflashwhite};
  color: ${components.button.text.primaryinverse};
  font-size: 12px;
  border: 1px solid ${palette.azure};
  font-weight: 400;
  padding: 4px 7px;
  height: 23px;
  &:focus {
    outline: 1px solid ${palette.cerulean};
    border-color: transparent;
  }
`;

export const SlimTransparentStyle = ({ palette, components }) => css`
  background: transparent;
  color: ${components.button.text.primaryinverse};
  border: 1px solid ${palette.azure};
  font-size: 12px;
  font-weight: 400;
  padding: 4px 7px;
  height: 23px;
  &:focus {
    outline: 1px solid ${palette.cerulean};
    border-color: transparent;
  }
`;

export const DropDownMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white};
  padding: 3px 0;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px
    ${({ theme }) => addAlpha(theme.palette.black, 0.25)};
  position: absolute;
  right: ${({ position }) => (position === 'right' ? 0 : 'auto')};
  top: ${({ variant }) =>
    variant === DROP_DOWN_VARIANTS.SLIM ||
    variant === DROP_DOWN_VARIANTS.SLIM_TRANSPARENT
      ? '30px'
      : '45px'};
  z-index: 999;
  min-width: 180px;
  & p[type='button'] {
    padding: 0;
    & a,
    & button {
      background-color: transparent;
      text-align: left;
      border: 0;
      width: 100%;
      padding: 8px 15px;
      cursor: pointer;
    }
  }
`;

export const DownIcon = styled(FaCaretDown)`
  display: inline-flex;
  margin-left: 5px;
  margin-right: -4px;
`;

export const UpIcon = styled(FaCaretUp)`
  display: inline-flex;
  margin-left: 5px;
  margin-right: -4px;
`;

export const DropdownItemButton = styled(Typography)`
  border: none;
  width: 100%;
  background-color: transparent;
  display: flex;

  color: ${({ theme }) => theme.palette.dovegray};
  white-space: nowrap;
  cursor: auto;

  & a {
    width: 100%;
    cursor: pointer;
  }
  & button {
    cursor: pointer;
  }

  &:not([data-test='dropdownLabel']) {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.mineshaft};
  }

  &[data-test='dropdownLabel'] {
    font-weight: 600;
    border-bottom: 1px solid ${({ theme }) => theme.palette.mercury};
  }

  &:hover:not([data-test='dropdownLabel']):not([disabled]) {
    background-color: ${({ theme }) => theme.palette.antiflashwhite};
  }
  &[disabled] {
    color: ${({ theme }) => theme.palette.oslogray};
    cursor: auto;
  }
`;
