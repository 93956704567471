class NeosomaError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    this.type = 'NeosomaError';
  }
}
function getAge(birthDate, ageType = 0) {
  if (ageType === 2) return undefined;
  const dob = new Date(birthDate);
  if (Object.prototype.toString.call(dob) !== '[object Date]') return '--';
  const Age = Math.floor((new Date() - dob) / 31557600000);
  // eslint-disable-next-line consistent-return
  return !isNaN(Age) ? Age.toString() : '--';
}

// Function to get cookie value by name
function getCookieValue(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

function getDate(timestamp) {
  if (!timestamp) return '-';
  let date = new Date(timestamp);
  date = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  // Use the GB date formatting of dd-MON-yyyy
  // But zero-pad the initial day-of-month, and correct for 4 char "Sept" (sigh)
  const formattedDate = date
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
    .replace(/ /g, '-')
    .replace(/Sept/, 'Sep');
  return `0${formattedDate}`.slice(-11);
}

function getDateWithSpaces(timestamp) {
  if (!timestamp) return '-';
  return getDate(timestamp).replace(/-/g, ' ');
}

function getSlashedDatetime(datetime) {
  if (!datetime) return '';
  let date = new Date(datetime);
  date = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  // Use the GB date formatting of dd-MON-yyyy using this format because it includes leading zeros
  const [DAY, MONTH, YEAR] = date
    .toLocaleDateString('en-GB', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    })
    .split('/');
  return `${MONTH}/${DAY}/${YEAR}`;
}

function getSlashedDateLocalTime(datetime) {
  if (!datetime) return '';
  let date = new Date(datetime);
  if (!datetime.endsWith('Z')) {
    date = new Date(
      date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
    );
  }
  // Use the GB date formatting of dd-MON-yyyy using this format because it includes leading zeros
  const [DAY, MONTH, YEAR] = date
    .toLocaleDateString('en-GB', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    })
    .split('/');
  return `${MONTH}/${DAY}/${YEAR} ${date.toLocaleTimeString('en-US')}`;
}

function getSlashedDate(date) {
  if (!date) return '-';
  const dateStr = (
    date instanceof Date ? date.toISOString() : date.toString()
  ).substring(0, 10);
  const [year, month, day] = dateStr.split('T')[0].split('-');
  return `${month}/${day}/${year}`;
}

function getSex(id) {
  if (!id) return '-';
  switch (id.toUpperCase()) {
    case 'F':
      return 'Female';
    case 'M':
      return 'Male';
    default:
      return '-';
  }
}

function generateScanImageFilename(patientId, hospitalId, studyId, sufix = '') {
  // "example: 13NCJKUCID/931USXYWZH/5610QRP75L/"
  let filename = `${hospitalId}/${patientId}/${studyId}/`;
  // "example: p13NCJKUCID_931USXYWZH_5610QRP75L_preview.png"
  filename += `p${filename.replaceAll(
    '/',
    '_'
  )}preview${sufix}.png?ts=${getScanTimestamp()}`;
  return filename;
}

function detectURLs(message) {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})+[^. ]/g;
  return message.match(urlRegex);
}

function replaceURLs(message) {
  if (!message) return '';

  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})+[^. ]/g;
  return message.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = `http://${hyperlink}`;
    }
    return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}

function getScanTimestamp() {
  let ts = sessionStorage.getItem('timestamp');
  if (!ts) {
    ts = refreshScanTimestamp();
  }
  return ts;
}

function refreshScanTimestamp() {
  const newTs = new Date().getTime();
  sessionStorage.setItem('timestamp', newTs);
  return newTs;
}

function maskPII(str) {
  if (sessionStorage.getItem('hidePII') === 'true' && str) {
    return str.replace(/[^ ]/g, '•');
  }
  return str;
}

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export {
  NeosomaError,
  getAge,
  getCookieValue,
  getDate,
  getSex,
  getSlashedDate,
  getSlashedDatetime,
  getSlashedDateLocalTime,
  getDateWithSpaces,
  getScanTimestamp,
  refreshScanTimestamp,
  generateScanImageFilename,
  detectURLs,
  maskPII,
  replaceURLs,
  isSafari
};
