import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Loader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
  width: calc(100% - 14px);
  background-color: #0000000e;
  backdrop-filter: blur(0.65px);
  z-index: 4;
`;

export const Spinner = styled('div')`
  position: absolute;
  background: transparent;
  align-items: center;
  display: flex;
  gap: 33px;

  .loader {
    opacity: 0.85;
    width: 48px;
    height: 48px;
    border: 5px solid ${({ theme }) => theme.palette.azure};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DummyTableHeader = styled('div')`
  height: 29px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.lightazure};
  border-bottom: 1px solid ${({ theme }) => theme.palette.white};
`;

const DummyTableRow = styled('div')`
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.lotion};
  border-bottom: 1px solid ${({ theme }) => theme.palette.mercury};
`;

const DummyPagginator = styled('div')`
  display: flex;
  width: 100%;
  height: 68px;
  background-color: ${({ theme }) => theme.palette.lotion};
  padding: 22px 14px;
  justify-content: space-between;
  & span {
    color: transparent;
    background-color: ${({ theme }) => theme.palette.brightgray};
  }
`;

function TaskListLoader({ loading, rows }) {
  return (
    <Loader>
      <DummyTableHeader />
      {Array.from(Array(rows <= 18 ? rows : 18).keys()).map((rowId) => (
        <DummyTableRow key={rowId} />
      ))}
      <DummyPagginator>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '26px',
            alignItems: 'center'
          }}
        >
          <div>
            <span>09</span>&nbsp;&nbsp;<span>Results</span>
          </div>
          <div>
            <span>Rows </span>
            <span>per</span>
            <span> page: </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            height: '15px',
            alignItems: 'center'
          }}
        >
          <span>&nbsp;{' < '}&nbsp;</span>
          <span>11</span>
          <span>11</span>
          <span>11</span>
          <span>&nbsp;{' > '}&nbsp;</span>
        </div>
      </DummyPagginator>
      <Spinner>
        <span className="loader" />
      </Spinner>
    </Loader>
  );
}

TaskListLoader.propTypes = {
  loading: PropTypes.bool,
  rows: PropTypes.number
};

TaskListLoader.defaultProps = {
  loading: false,
  rows: 9
};

export default TaskListLoader;
