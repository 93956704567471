import { css, Global, ThemeProvider } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import PropTypes from 'prop-types';

export const defaultTheme = {
  metaTitle: 'Neosoma',
  fontFamily: {
    display: 'Inter, system-ui',
    body: 'Inter, system-ui'
  },
  palette: {
    neosomaYellow: '#FECF02',
    neosomaOrange: '#FF9A00',
    neosomaRed: '#FE6600',
    neosomaPurple: '#201849',
    neosomaBeige: '#F8F1E7',
    white: '#FFFFFF',
    // ew
    lotion: '#FBFBFB',
    mercury: '#E5E5E5',
    antiflashwhite: '#F2F2F2',
    brightgray: '#ECECEC',
    platinum: '#E4E4E4',
    lightsilver: '#D6D6D6',
    athensgray: '#EBEEF3',
    geyser: '#DDE1E7',
    submarine: '#B2BBC2',
    mischka: '#CFD5DE',
    bombay: '#AFB2BB',
    dustygray: '#969696',
    oslogray: '#929598',
    shuttlegray: '#5F6571',
    dovegray: '#6B6B6B',
    tundora: '#424242',
    abbey: '#484B52',
    eclipse: '#383838',
    mineshaft: '#272727',
    ebonclay: '#252A35',
    black: '#000000',
    silverchalice: '#A8A8A8',
    emperor: '#535353',
    selago: '#F6F9FE',
    zircon: '#F3F8FF',
    catskillwhite: '#E6ECF4',
    cerulean: '#3067C1',
    azure: '#365C9A',
    lightazure: '#eceef3',
    allports: '#086FA9',
    atoll: '#0D547B',
    blackpearl: '#081B3A',
    pacific: '#0052CC',
    moonraker: '#C5D7F4',
    romantic: '#FFD9BF',
    tangerine: '#EF9205',
    yelloworange: '#FFB543',
    blaze: '#FE6600',
    honeysuckle: '#FAFC89',
    azalea: '#FAD9D7',
    sweetpink: '#FD9CA5',
    thunderbird: '#C61D12',
    gin: '#E1EDE2',
    malachite: '#10DA49',
    salem: '#198f51',
    sanfelix: '#08690C',
    mandalay: '#B97815',
    lapislazuli: '#2778A6',
    bluegray: '#64A6CB'
  },
  spacing: {
    0: '0rem',
    xs: '0.26rem',
    sm: '0.37rem',
    md: '0.75rem',
    lg: '1.5rem',
    xl: '3rem',
    '2xl': '6rem'
  }
};

defaultTheme.components = {
  body: {
    background: defaultTheme.palette.lightsilver
  },
  text: {
    primary: defaultTheme.palette.mineshaft,
    secondary: defaultTheme.palette.dovegray,
    size: {
      displayOne: '1.875rem', // 30px
      displayTwo: '1.25rem', // 20px
      h1: '1.125rem', // 18px
      h2: '1rem', // 16px
      h3: '0.875rem', // 14px
      p: '0.75rem', // 12px
      small: '0.625rem' // 10px
    },
    lineHeight: {
      displayOne: 1.44,
      displayTwo: 1.44,
      h1: 1.44,
      h2: 1.29,
      h3: 'normal',
      p: 1.63,
      small: 1.43
    },
    weight: {
      body: 400
    }
  },

  button: {
    text: {
      primary: defaultTheme.palette.white,
      secondary: defaultTheme.palette.white,
      primaryinverse: defaultTheme.palette.azure,
      secondaryinverse: defaultTheme.palette.tangerine,
      size: '0.75rem',
      weight: 700
    }
  },
  navigation: {
    background: defaultTheme.palette.blackpearl,
    linkcolor: defaultTheme.palette.white,
    activelinkcolor: defaultTheme.palette.neosomaOrange,
    hoverlinkcolor: defaultTheme.palette.neosomaOrange
  },
  app: {
    mainColor: defaultTheme.palette.neosomaOrange,
    name: 'Neosoma',
    path: '/',
    background: defaultTheme.palette.lightsilver
    // add logo from svg
    // logo
  },
  modal: { title: defaultTheme.palette.azure },
  select: {
    selectionColor: defaultTheme.palette.azure,
    focusColor: defaultTheme.palette.azure
  }
};

// Export theme provider.
export function Provider({ theme = defaultTheme, children }) {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={({
          fontFamily,
          palette,
          components: {
            text: { size, weight, lineHeight }
          }
        }) => css`
          ${emotionNormalize}
          *, *:before, *:after {
            box-sizing: border-box;
          }
          body {
            color: ${palette.mineshaft};
            font-family: ${fontFamily.body};
            font-size: ${size.p};
            line-height: ${lineHeight.p};
            font-weight: ${weight.body};
          }
          a {
            color: currentColor;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
          blockquote,
          dl,
          dd,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          hr,
          figure,
          ol,
          p,
          pre,
          ul {
            margin: 0;
            padding: 0;
          }
        `}
      />
      {children}
    </ThemeProvider>
  );
}

Provider.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  children: PropTypes.node
};

Provider.defaultProps = {
  theme: defaultTheme,
  children: null
};
