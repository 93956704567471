/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

// Constants.
export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  PRIMARY_INVERSE: 'primary-inverse',
  SECONDARY: 'secondary',
  SECONDARY_INVERSE: 'secondary-inverse',
  LINK: 'link'
};

// Styles.
const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  height: 30px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: ${({ theme }) => theme.components.button.text.size};
  font-weight: 400;
  line-height: 1.428;
  box-shadow: none;
  transition: 0.5s all;
  &:disabled,
  &[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PrimaryStyle = ({ palette, components }) => css`
  background-color: ${palette.azure};
  color: ${components.button.text.primary};
  &:hover {
    background-color: ${palette.cerulean};
  }
`;

const PrimaryInverseStyle = ({ palette, components }) => css`
  background: ${palette.white};
  color: ${components.button.text.primaryinverse};
  border: 1px solid ${palette.azure};
`;

const SecondaryStyle = ({ palette, components }) => css`
  background: ${palette.tangerine};
  color: ${components.button.text.secondary};
  &:hover {
    background: ${palette.yelloworange};
  }
`;

const SecondaryInverseStyle = ({ palette, components }) => css`
  color: ${palette.tangerine};
  background-color: ${palette.white};
  border: 1px solid ${palette.tangerine};

  &:focus {
    outline: 1px solid ${palette.yelloworange};
    border: 0;
  }
`;

const LinkStyle = ({ palette, components }) => css`
  position: relative;
  text-decoration: none;
  color: ${components.button.text.primaryinverse};
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 2px;
  max-height: fit-content;
  height: calc(100% - 20px);
  margin-left: -2px;
  // This is so the underline works with icons, Not sure it will hold up with mulitple lines of text
  &:hover:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    border-bottom: 1px solid ${components.button.text.primaryinverse};
  }
`;

const StartIcon = styled.div`
  display: inline-flex;
  margin-right: 4px;
  margin-left: -4px;
`;

const EndIcon = styled.div`
  display: inline-flex;
  margin-left: 8px;
  margin-right: -4px;
`;

export const Button = forwardRef(
  ({ children, endIcon, startIcon, variant, ...props }, ref) => {
    let variantStyle;
    switch (variant) {
      case BUTTON_VARIANTS.PRIMARY:
        variantStyle = PrimaryStyle;
        break;
      case BUTTON_VARIANTS.PRIMARY_INVERSE:
        variantStyle = PrimaryInverseStyle;
        break;
      case BUTTON_VARIANTS.SECONDARY:
        variantStyle = SecondaryStyle;
        break;
      case BUTTON_VARIANTS.SECONDARY_INVERSE:
        variantStyle = SecondaryInverseStyle;
        break;
      case BUTTON_VARIANTS.LINK:
        variantStyle = LinkStyle;
        break;
      default:
        variantStyle = PrimaryStyle;
    }
    return (
      <StyledButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        css={variantStyle}
        ref={ref}
      >
        {startIcon && <StartIcon>{startIcon}</StartIcon>}
        {children}
        {endIcon && <EndIcon>{endIcon}</EndIcon>}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  children: PropTypes.node
};

Button.defaultProps = {
  variant: BUTTON_VARIANTS.PRIMARY,
  startIcon: null,
  endIcon: null,
  children: null
};
