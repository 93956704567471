function getAllUserSettings() {
  if (!localStorage.getItem('userSettings')) {
    localStorage.setItem('userSettings', JSON.stringify({}));
    return {};
  }
  const userSettings = JSON.parse(localStorage.getItem('userSettings') || '{}');
  return userSettings;
}

function getUserSettings(userId) {
  if (!userId) return {};
  if (!localStorage.getItem('userSettings')) {
    const newUserSettings = { [userId]: {} };
    localStorage.setItem('userSettings', JSON.stringify(newUserSettings));
    return newUserSettings[userId];
  }
  const userSettings = JSON.parse(localStorage.getItem('userSettings') || '{}');
  if (!userSettings[userId]) {
    localStorage.setItem(
      'userSettings',
      JSON.stringify({ ...userSettings, [userId]: {} })
    );
  }
  return userSettings[userId] || {};
}

function getSetting(userId, key) {
  if (!userId) return null;
  const userSettings = getUserSettings(userId);
  return userSettings[key];
}

function setSetting(userId, key, value) {
  if (!userId) return;
  const userSettings = getAllUserSettings();
  localStorage.setItem(
    'userSettings',
    JSON.stringify({
      ...userSettings,
      [userId]: { ...userSettings[userId], [key]: value }
    })
  );
}

export { getUserSettings, getSetting, setSetting };
