import { useEffect } from 'react';

function addAlpha(color, opacityPer) {
  // coerce values so ti is between 0 and 1.
  const opacity = Math.round(Math.min(Math.max(opacityPer || 1, 0), 1) * 255);
  return color + opacity.toString(16).toUpperCase();
}

function useDetectOutsideClick(ref, func) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, func]);
}

export { addAlpha, useDetectOutsideClick };
