import { useRef } from 'react';
import { useRecoilState } from 'recoil';

import { patientListCacheState } from '@Utils/atoms';
import { CLEAR_ALL_CACHE } from './helpers';

const CACHE_MISS = false;

function createEmptyPage(numberOfItems, filler) {
  return Array.from({ length: numberOfItems }).map((i) => filler);
}

const emptyCache = { cache: {}, totalItems: {} };

function anyItemsExistInDifferentIndex(oldPage = [], newPage = []) {
  let errorFoundDurringReduce = false;
  function indexReducerFnc(obj, item, idx) {
    if (item === undefined || item === null) return obj;
    if (typeof obj[item.id] === 'number') {
      // in this case patient exist in more than one index, cache is invalid.
      errorFoundDurringReduce = true;
    }
    return { ...obj, [item.id]: idx };
  }
  const oldIndexes = oldPage.reduce(indexReducerFnc, {});
  const newIndexes = newPage.reduce(indexReducerFnc, {});

  if (errorFoundDurringReduce) return true;

  if (Object.keys(oldIndexes).length >= Object.keys(newIndexes).length) {
    return Object.keys(oldIndexes).some((patientId) => {
      const thisIndex = oldIndexes[patientId];
      if (typeof newIndexes[patientId] === 'number') {
        return thisIndex !== newIndexes[patientId];
      }
      return false;
    });
  }
  return Object.keys(newIndexes).some((patientId) => {
    const thisIndex = newIndexes[patientId];
    if (typeof oldIndexes[patientId] === 'number') {
      return thisIndex !== oldIndexes[patientId];
    }
    return false;
  });
}

// !Important!
// For this to work correctly the component that is using the cache must have an useEffect
// that watches the pagginationItems passed in and calls the updatePageInfo function when there is a changes.

function useCacheManager({ ...pagginationItems }) {
  const [{ cache, totalItems }, setCache] = useRecoilState(
    patientListCacheState
  );
  const prevPaggination = useRef({ ...pagginationItems });

  function updateAdjacentCaches(cacheKey, newData) {
    if (cacheKey === undefined || newData === undefined) return cache;
    const thisCache = cache;
    const adjacentCacheKeys = Object.keys(cache).reduce((obj, key) => {
      if (key !== cacheKey) {
        return { ...obj, [key]: cache[key] };
      }
      return obj;
    }, {});

    const allAdjacentIds = {}; // example output: {'id1', ['cacheKey1', 'cacheKey3'], id2: [cacheKey2]...}
    // build a lookup map of patientId's as keys and array index for value.
    const cacheItemKeyMap = Object.keys(adjacentCacheKeys).reduce(
      // example output:  {cacheKey1: {id1: indexNumberInCache, id2: indexNumberInCache ...}...}
      (o, k) => ({
        ...o,
        [k]: thisCache[k].reduce(
          // loop each item in cache this step should save looping these items with every item in the new data
          (obj, chi, idx) => {
            if (chi === null || chi === undefined) return obj;
            allAdjacentIds[chi.id] = allAdjacentIds[chi.id]
              ? [...allAdjacentIds[chi.id], k]
              : [k];
            return { ...obj, [chi.id]: idx };
          },
          {}
        )
      }),
      {}
    );

    // Loop updated patients and apply fresh record to adjacent caches.
    newData.forEach((record) => {
      if (!record || record.id === undefined) return;
      const thisPatientId = record.id;

      if (allAdjacentIds[thisPatientId]) {
        // allAdjacentIds[record.id] is an array of adjacent caches where this patient is also in.
        allAdjacentIds[thisPatientId].forEach((adjCacheKey) => {
          const thisCachePatientIndexLocation =
            cacheItemKeyMap[adjCacheKey][thisPatientId];
          // update previous cache with new record.
          const updatedRecord = [...adjacentCacheKeys[adjCacheKey]];
          updatedRecord[thisCachePatientIndexLocation] = record;
          adjacentCacheKeys[adjCacheKey] = updatedRecord;
        });
      }
    });

    // update cache
    const updatedCaches = { ...cache };
    Object.keys(adjacentCacheKeys).forEach((key) => {
      updatedCaches[key] = adjacentCacheKeys[key];
    });
    return updatedCaches;
  }

  function clearCache(cacheKey, newData) {
    // [newData] can be used to conditionaly clear the cache
    // usecase: newData is sent to setter we want to clear the cache if no data is given so that the app may refetch data due to the cache being empty.
    // app logic would be if cache is empty fetch fresh data. So if data is fetched and none is found we will want however the cache previusly had
    // data we would want to check again when the cache is checked, additional we could intentionlay use this to clear the cache so fresh data will be requested.
    let cacheWasCleared = false;
    if (cacheKey && newData === undefined) {
      // Clear cache.
      const clearedCache = { ...cache };
      if (clearedCache && clearedCache[cacheKey] !== undefined) {
        delete clearedCache[cacheKey];
      }

      // Clear total items
      const clearedTotalItems = { ...totalItems };
      delete clearedTotalItems[cacheKey];
      setCache({ cache: clearedCache, totalItems: clearedTotalItems });
      cacheWasCleared = true;
    }
    return cacheWasCleared;
  }

  function setIsDirty(cacheKey) {
    if (cacheKey === CLEAR_ALL_CACHE) {
      setCache(emptyCache);
      return;
    }
    // Allows component to clear cache.
    clearCache(cacheKey);
  }

  function getter(cacheKey) {
    if (cache[cacheKey] === undefined) return CACHE_MISS;
    const { currentPage, rowsPerPage } = prevPaggination.current;
    const thisCache = [...cache[cacheKey]];
    const startPosition = currentPage * rowsPerPage;

    // Check if cache has data it should be at least one page in size.
    if (thisCache.length < rowsPerPage) return CACHE_MISS;

    // Cache should be fully populated with undfined up to total number of items so cache if invalid if this not true.
    if (startPosition >= thisCache.length - 1) return CACHE_MISS;

    const cachePage = thisCache.slice(
      startPosition,
      startPosition + rowsPerPage
    );

    // Check if any items in the cached page is undefinded need more data if true. Can happen if user changed rows per page to larger number.
    if (cachePage.some((i) => i === undefined)) return CACHE_MISS;
    const records = cachePage.filter((i) => i !== null); // remove null items for use in ui. null is used when the setter adds a page where the return has less items than rows per page(end of list)

    const metadata = {
      total_count: totalItems[cacheKey],
      skip: currentPage * rowsPerPage,
      limit: rowsPerPage
    };
    return {
      metadata,
      records,
      currentPage,
      rowsPerPage,
      selectedTab: cacheKey
    };
  }

  function setter(cacheKey, newData, newTotalItems) {
    let updatedTotalItems = { ...totalItems };
    // Update cache
    // cacheKey should be the selectedTab
    // * look at new list and update patient in other caches so latest data is there
    // * newData === undefined clears cache for given cacheKey
    if (cacheKey === undefined) return;
    if (clearCache(cacheKey, newData)) return; // Hope this makes sense. 🤷🏻‍♂️

    const prevTotalItems = updatedTotalItems[cacheKey];
    const { selectedTab, currentPage, rowsPerPage } = prevPaggination.current;

    if (selectedTab !== cacheKey) {
      // eslint-disable-next-line no-console
      console.error(
        `Selected tab(${selectedTab}) from paggination data does not match new data cacheKey(${cacheKey}). This indicates there is an issue with the code.`
      );
      // maybe you need to add/check the use effect that trackes tab, pageNumber, and itemsPerPage changes.
    }

    // +===============================================+
    // Check totals in cache vs new totals from backend.
    if (
      typeof newTotalItems === 'number' && // newTotalItems of 0 would be false. newTotalItems is optional(undefined) if not provided old total is used. 🤷🏻‍♂️
      prevTotalItems !== newTotalItems
    ) {
      updatedTotalItems = { ...updatedTotalItems, [cacheKey]: newTotalItems };
      if (prevTotalItems !== undefined) {
        // in the case that the total items changed cache is out of sync so we clear previous data.
        clearCache(cacheKey);
      }
    }

    // +==============================================+
    // Cache is empty and we know total number of items
    if (
      cache[cacheKey] === undefined &&
      typeof updatedTotalItems[cacheKey] === 'number'
    ) {
      const newPage = createEmptyPage(rowsPerPage, null);
      newPage.splice(0, newData.length, ...newData);

      const emptyArrayOfTotalLength = Array.from({
        length: updatedTotalItems[cacheKey]
      });

      const startPosition = currentPage * rowsPerPage;
      emptyArrayOfTotalLength.splice(startPosition, newPage.length, ...newPage);

      const updatedCache = updateAdjacentCaches(cacheKey, newData);
      setCache({
        cache: {
          ...updatedCache,
          [cacheKey]: emptyArrayOfTotalLength
        },
        totalItems: updatedTotalItems
      });
      return;
    }

    // +==============================================+
    // Cache has data and we know number of items
    if (cache[cacheKey] && typeof updatedTotalItems[cacheKey] === 'number') {
      const newPage = createEmptyPage(rowsPerPage, null);
      newPage.splice(0, newData.length, ...newData);

      const startPosition = currentPage * rowsPerPage;
      const newCache = [...cache[cacheKey]];
      newCache.splice(startPosition, newPage.length, ...newPage);

      // Check if patient already exists in the cache in a different location, if so cache is now invalid.
      if (anyItemsExistInDifferentIndex(cache[cacheKey], newCache)) {
        clearCache(cacheKey);
        return;
      }

      updateAdjacentCaches(cacheKey, newData);
      setCache({
        cache: { ...cache, [cacheKey]: newCache },
        totalItems: updatedTotalItems
      });
      return;
    }
    if (typeof newTotalItems !== 'number') {
      // +=====================================================+
      // Adhoc logic, in the case that total items is undefined.
      // ADD if needed as it gets very complicated and should not happen.
      // eslint-disable-next-line no-console
      console.error(
        'Data Not added to cache as newTotalItems is most likely unknown',
        newTotalItems
      );
      setCache({ cache, totalItems: updatedTotalItems });
      return;
    }
    setCache({ cache, totalItems: updatedTotalItems });
  }

  function updatePageInfo({ selectedTab, currentPage, rowsPerPage }) {
    // !Important!
    // For caching to work correctly the component that is using the cache must have an useEffect
    // that watches the paggination items passed in and calls the updatePageInfo function when there is a changes.
    if (prevPaggination.selectedTab !== selectedTab) {
      // setIsDirty(selectedTab);
    }
    prevPaggination.current = { selectedTab, currentPage, rowsPerPage };
  }

  return [getter, setter, updatePageInfo, setIsDirty];
}

export function useClearCache() {
  const [cache, updateCache] = useRecoilState(patientListCacheState);
  return (cacheKey) => {
    if (cacheKey === CLEAR_ALL_CACHE) {
      updateCache(emptyCache);
      return;
    }
    const newCache = { ...cache };
    if (cache?.cache[cacheKey]) {
      delete newCache.cache[cacheKey];
    }
    updateCache(newCache);
  };
}

export default useCacheManager;
