import { useState } from 'react';
import styled from '@emotion/styled';
import parse from 'html-react-parser';
import { MdOutlineSpeakerNotes } from 'react-icons/md';

import { Modal, Button } from '@Components/NeosomaUI';
import releaseNotes from '../../assets/release-notes.html';

const NotesContainer = styled.div`
  display: flex;
  height: fit-content;
  max-height: calc(100vh - 174px);
  overflow-y: auto;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.antiflashwhite};
  & body header h1 {
    color: transparent;
    font-size: 2px;
  }
  & body main {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding-top: 26px;
    padding-bottom: 26px;
    & section {
      border: 1px solid ${({ theme }) => theme.palette.platinum};
      border-radius: 3px;
      background-color: ${({ theme }) => theme.palette.white};
      display: flex;
      padding: 10px;
      flex-direction: column;
      gap: 6px;

      & h2 {
        color: ${({ theme }) => theme.palette.atoll};
        font-size: 16px;
        font-weight: 600;
      }
      & h3 {
        font-size: 14px;
        font-weight: 600;
      }
      & p {
        font-size: 14px;
        font-weight: 400;
      }
      & ul {
        padding-left: 12px;
        list-style-type: none;
      }
    }
  }
`;

const options = {
  replace(domNode) {
    if (domNode.name === 'link') return <link />;
    return domNode;
  }
};

function ReleaseNotes() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Modal
        title="Neosoma Release Notes"
        isShowing={showModal}
        hideFooter
        hide={() => setShowModal(false)}
        style={{ width: '750px', marginTop: '78px' }}
      >
        <NotesContainer>{parse(releaseNotes, options)}</NotesContainer>
      </Modal>
      <Button
        variant="primary-inverse"
        type="button"
        onClick={() => setShowModal(true)}
        style={{ width: '100%', marginTop: '0' }}
      >
        <MdOutlineSpeakerNotes size="20px" />
        &nbsp;&nbsp;View Release Notes
      </Button>
    </div>
  );
}

export default ReleaseNotes;
