import styled from '@emotion/styled';

import { Flex } from '@Components/NeosomaUI';

export const Container = styled(Flex)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.lightsilver};
  background-color: ${({ theme }) => theme.palette.lotion};
  border-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  & .sr-only {
    color: transparent;
  }

  & .sr-only-no-DOM {
    position: absolute;
    color: transparent;
  }

  & table {
    border-collapse: collapse;
  }

  & tbody {
    padding: 0 10px;
    background: ${({ theme }) => theme.palette.lotion};
  }

  & td,
  & th {
    padding: 5px 8px;
    text-align: start;
  }

  & thead {
    height: 32px;
    color: ${({ theme }) => theme.palette.azure};
    background-color: ${({ theme }) => theme.palette.lightazure};
    border-bottom: 1px solid ${({ theme }) => theme.palette.lotion};
  }

  & thead tr {
    background-color: ${({ theme }) => theme.palette.athensgrey};
  }

  & td[data-test~='name'],
  & td[data-test~='date'] {
    color: ${({ theme }) => theme.palette.mineshaft};
    font-weight: 700;
  }
`;

export const StyledTr = styled('tr')`
  background: ${({ theme, selected }) =>
    selected ? theme.palette.antiflashwhite : theme.palette.lotion};
  border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};

  &:hover {
    position: relative;
    box-shadow: 0 3px 4px #00000013;
  }
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  & div:not([pinned='true']).show-on-hover > * {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  }

  &:hover div:not([pinned='true']).show-on-hover > * {
    visibility: visible;
  }

  &:hover div:not([pinned='true']).show-on-hover > * {
    visibility: visible;
  }

  &:hover .remove-on-hover {
    display: none;
  }

  & .remove-on-hover {
    display: inherit;
  }

  &:hover .add-on-hover {
    display: inherit;
  }

  & .add-on-hover {
    display: none;
  }

  & .add-on-hover-keep-on-selected {
    display: ${({ selected }) => (selected ? 'inherit' : 'none')};
  }

  &:hover .add-on-hover-keep-on-selected {
    display: inherit;
  }
`;

export const HeaderThing = styled('div')`
  flex-grow: 0;
  display: flex;
  background-color: ${({ theme }) => theme.palette.lotion};
  justify-content: space-between;
  padding: 7px;
  padding-top: 14px;
  padding-bottom: 14px;
  & img {
    height: 32px;
  }
`;

export const HideWhileLoading = styled('div')`
  position: relative;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  .loading {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 4;
    backdrop-filter: blur(0.75px);
  }
`;

export const ListContainer = styled('div')`
  position: relative;
  min-height: 423px;
  padding: 0 7px;
  & table {
    width: 100%;
  }
`;

export const PageContainer = styled('div')`
  padding: 0 7px;
`;

export const PinButton = styled.button`
  height: 30px;
  width: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50px;
  background-color: transparent;

  & > div {
    width: 18px;
    height: 18px;
  }

  & svg {
    fill: ${({ theme, pinned }) =>
      pinned ? theme.palette.dovegray : theme.palette.white};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.platinum};
    & svg {
      fill: ${({ theme, pinned, canEditPatient }) => {
        if (canEditPatient) {
          return pinned ? theme.palette.white : theme.palette.dovegray;
        }

        return pinned ? theme.palette.dovegray : theme.palette.white;
      }};
    }
  }
  z-index: 1;
`;
export const ReviewedButton = styled.button`
  height: 30px;
  width: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50px;
  background-color: transparent;

  & > div {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.platinum};
  }
  z-index: 1;
  &:not(:hover) .reviewed-text {
    display: inline;
  }
  &:hover .reviewed-text {
    display: none;
  }
`;

export const WarningIcon = styled('div')`
  content: '!';
  position: absolute;
  font-weight: 600;
  border: 1px solid #d59624;
  height: 14px;
  width: 13px;
  border-radius: 3px;
  text-align: center;
  background-color: rgb(255 202 60);
  font-size: 10px;
  line-height: 13px;
  cursor: default;
  user-select: none;
  z-index: 1;
`;

const selectCellWidth = '20px';

export const SelectCell = styled('td')`
  width: ${selectCellWidth};
`;

export const SelectHeaderCell = styled('th')`
  width: ${selectCellWidth};
`;

export const DateCell = styled('td')`
  width: 90px;
`;

export const ToolTipContainer = styled('div')`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.tundora};
  padding: 0 6px;
  border-radius: 4px;
  font-size: 10px;
`;
