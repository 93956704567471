/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import styled from '@emotion/styled';
import getPatientList from '@Utils/api/getPatientList';
import { useRecoilState } from 'recoil';

import { Flex } from '@Components/NeosomaUI';
import { currentPatientListState, selectedPatientState } from '@Utils/atoms';
import { PATIENT_LIST_TABS } from '@Utils/defaultConfig';
import useCacheManager from './cacheHelpers';
import SideBar from './Sidebar';
import TaskList from './TaskList';
import ImageViewer from './ImageViewer';
import {
  getCurrentTab,
  getCurrentPage,
  getCurrentRowsPerPage,
  DEFAULT_TAB
} from './helpers';

const Container = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.palette.lightsilver};
`;

function TaskView() {
  const [patientList, setPatientList] = useRecoilState(currentPatientListState);
  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const [selectedTab, setSelelectedTab] = useState(getCurrentTab(patientList));
  const [currentPage, setCurrentPage] = useState(getCurrentPage(patientList));
  const [rowsPerPage, setRowsPerPage] = useState(
    getCurrentRowsPerPage(patientList)
  );

  const [getListCache, setListCache, updatePageInfo, setTabDirty] =
    useCacheManager({
      selectedTab: patientList
        ? patientList.selectedTab
        : PATIENT_LIST_TABS[DEFAULT_TAB].id,
      currentPage: getCurrentPage(patientList),
      rowsPerPage: getCurrentRowsPerPage(patientList)
    });

  const patientListRefresh = () => {
    setTabDirty('..!CLEAR_!FULL_!CACHE..');
    taskLoader.mutate({
      page: currentPage,
      count: rowsPerPage,
      filter: PATIENT_LIST_TABS[selectedTab].filterId
    });
  };

  const taskLoader = useMutation(
    ({ page, count, filter }) => getPatientList(page * count, count, filter),
    {
      onSuccess: (data) => {
        setPatientList({
          ...patientList,
          ...data,
          selectedTab
        });
        // update cache.
        setListCache(
          selectedTab,
          data?.records,
          data?.metadata?.total_count || 0
        );
        const haveSelection = selectedPatient && selectedPatient.currentPatient;
        const selectedPatientInNewData = !!(
          haveSelection &&
          (data?.records || [])
            ?.map((r) => r.id)
            ?.includes(selectedPatient.currentPatient)
        );

        // Select the patient if they are in the new list, if not select first patient.
        // IF no patient is already selected
        //   OR
        //   IF a patient is already selected
        //   AND the patient selected is NOT in the new list of patients
        // AND the new list of patients is not empty
        // SELECT first patient in new list.
        if (
          (!haveSelection || (haveSelection && !selectedPatientInNewData)) &&
          data?.records?.length
        ) {
          const previousSelection =
            selectedPatient.history &&
            selectedPatient.history[selectedTab] &&
            (data?.records || [])
              ?.map((r) => r.id)
              ?.includes(selectedPatient.history[selectedTab]);

          const thisSeletion = previousSelection
            ? data.records.find((i) => i.id === previousSelection)
            : data.records[0];
          setSelectedPatient({
            ...selectedPatient,
            currentPatient: thisSeletion?.id,
            [thisSeletion?.id]: thisSeletion,
            history: {
              ...selectedPatient.history,
              [selectedTab]: thisSeletion?.id
            }
          });
        } else if ((data?.records || []).length === 0) {
          setSelectedPatient({
            ...selectedPatient,
            currentPatient: null,
            history: {
              ...selectedPatient.history,
              [selectedTab]: undefined
            }
          });
        }
      }
    }
  );

  useEffect(() => {
    updatePageInfo({ currentPage, rowsPerPage, selectedTab });

    const cachedData = getListCache(selectedTab);
    if (!cachedData) {
      // Need to fetch new data
      taskLoader.mutate({
        page: currentPage,
        count: rowsPerPage,
        filter: PATIENT_LIST_TABS[selectedTab].filterId
      });

      setSelectedPatient({
        ...selectedPatient,
        refreshPatientList: patientListRefresh // hook that others can use the force list refresh
      });

      // Update History
      setPatientList({
        ...patientList,
        history: {
          ...patientList.history,
          currentPage: {
            ...(patientList.history || {}).currentPage,
            [selectedTab]: currentPage
          },
          rowsPerPage: {
            ...(patientList.history || {}).rowsPerPage,
            [selectedTab]: rowsPerPage
          }
        }
      });
    } else {
      // Data is in cache
      setPatientList({
        ...patientList,
        ...cachedData,
        selectedTab,
        history: {
          ...patientList.history,
          currentPage: {
            ...(patientList.history || {}).currentPage,
            [selectedTab]: currentPage
          },
          rowsPerPage: {
            ...(patientList.history || {}).rowsPerPage,
            [selectedTab]: rowsPerPage
          }
        }
      });

      const selectedPatientFromHistory = selectedPatient.history
        ? (cachedData?.records || []).find(
            (i) => i.id === selectedPatient.history[selectedTab]
          )
        : undefined;
      const firstPatient = selectedPatientFromHistory || cachedData.records[0];
      const selectedPatientInList = cachedData.records
        .map((i) => i.id)
        .includes(selectedPatient.currentPatient || '');
      if (
        firstPatient?.id !== selectedPatient.currentPatient &&
        !selectedPatientInList
      ) {
        setSelectedPatient({
          currentPatient: firstPatient?.id,
          [firstPatient?.id]: firstPatient,
          refreshPatientList: patientListRefresh // hook that others can use the force list refresh
        });
      } else {
        setSelectedPatient({
          ...selectedPatient,
          refreshPatientList: patientListRefresh // hook that others can use the force list refresh
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage, selectedTab]);

  const handleRefresh = () => {
    patientListRefresh();
  };

  const handleTabSelect = (tabId) => {
    if (selectedTab !== tabId) {
      setSelelectedTab(tabId);
      setPatientList({
        ...patientList,
        history: { ...(patientList.history || {}), selectedTab: tabId }
      });
      setCurrentPage(getCurrentPage(patientList, tabId));
    }
  };

  return (
    <Container data-test="flex" grow="1">
      <SideBar
        {...{
          data: PATIENT_LIST_TABS,
          activeTab: selectedTab,
          updateSelection: handleTabSelect,
          isLoading: taskLoader.isLoading
        }}
      />
      <TaskList
        doRefresh={handleRefresh}
        data={patientList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isLoading={taskLoader.isLoading}
        filterType={PATIENT_LIST_TABS[selectedTab].filterId}
      />
      <ImageViewer />
    </Container>
  );
}

export default TaskView;
