/* eslint-disable camelcase */
import { promiseGetRecoil } from 'recoil-outside';

import { userInfoState } from '@Utils/atoms';
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';

const { PUT } = doFetch.methods;

const changePassword = async ({ oldpass, newpass }) => {
  // !*! FYI !*!
  // In the case of a forced password change userInfo will only contain the user_id, username, and apiToken.
  // if more data is needed update the setUserInfo in PasswordReseter.
  const userInfo = await promiseGetRecoil(userInfoState);
  const { id: user_id, username, apiToken } = userInfo || {};
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const hash = async (string) => {
    const utf8 = new TextEncoder().encode(string);
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');

      return hashHex;
    });
  };

  const oldPassHashed = await hash(
    `${oldpass}${username.toLowerCase()}salt.neosomainc.com`
  );

  const newPassHashed = await hash(
    `${newpass}${username.toLowerCase()}salt.neosomainc.com`
  );

  return doFetch(`${uri}/user?client_id=${hospitalId}`, {
    method: PUT,
    body: JSON.stringify({
      user_id,
      old_password: oldPassHashed,
      new_password: newPassHashed
    }),
    headers: {
      Authorization: `Basic ${apiToken}`,
      'Content-Type': 'application/json'
    },
    returnErrorResponse: true,
    doNotRedirectOnError: true
  });
};
export default changePassword;
