/* eslint-disable camelcase */
export const ROWS = {
  enhancing: 'Enhancing',
  flair: 'FLAIR',
  necrosis: 'Necrosis / Cavity',
  whole: 'Whole Tumor *'
};

export const dateFormatter = (date) => {
  if (date === 0) {
    return '';
  }

  return new Date(date)
    .toUTCString()
    .substring(5, 16)
    .split(' ')
    .reverse()
    .join('-');
};

export const getDelta = (lastVal, firstVal) =>
  Math.min(999.99, ((lastVal - firstVal) / firstVal) * 100);

export function sanitizeValue(value) {
  if (value === 0) {
    return 0;
  }
  if (value === null || value === undefined || value === '' || isNaN(value)) {
    return '--';
  }

  if (parseFloat(value) !== 0 && value.toFixed(2) === '0.00') {
    return '< 0.01';
  }
  return value.toFixed(2);
}

export const sortNewToOld = (a, b, type = 'newToOld') => {
  const dateA = a.date;
  const dateB = b.date;

  if (dateA < dateB) {
    return type === 'newToOld' ? 1 : -1;
  }
  if (dateA > dateB) {
    return type === 'newToOld' ? -1 : 1;
  }
  return 0;
};

export const sortOldToNew = (a, b) => sortNewToOld(a, b, 'oldToNew');

export function calculateVolumes(data, selectedStudies, patientData) {
  if (!data || data.length === 0) {
    return [];
  }

  const sortedDate = [...data].sort(sortNewToOld);

  const isLatestStudy = sortedDate[0].id === selectedStudies.current;

  const selectedIsPrior =
    sortedDate.length > 1 // if there is only one study, it is the current and prior study
      ? sortedDate[1].id === selectedStudies.prior
      : sortedDate[0].id === selectedStudies.prior;

  let volumes = [];
  const current = data.find((rec) => rec.id === selectedStudies.current);
  const prior = data.find((rec) => rec.id === selectedStudies.prior);

  // Check if current and prior are the same if not add prior to volumes
  if (current.id !== prior.id) {
    volumes = [
      { type: `${isLatestStudy ? 'Current' : 'Custom'}`, ...current },
      {
        type: `${selectedIsPrior ? 'Prior' : 'Selected'}`,
        ...prior
      }
    ];
  } else {
    // If prior and Current are the same only add current to volumes
    volumes = [{ type: `${isLatestStudy ? 'Current' : 'Custom'}`, ...current }];
  }

  const { baseline_study_id, nadir_study_id } = patientData;

  const hasBaseline = baseline_study_id !== null;
  const hasNadir = nadir_study_id !== null;
  const baselineAndNadirAreTheSame =
    (baseline_study_id || 'Baseline') === (nadir_study_id || 'Nadir');

  if (hasBaseline && !baselineAndNadirAreTheSame) {
    // Find baseline in available studies, may not be in the list due to status of 10
    const baseline = data.find((rec) => rec.id === baseline_study_id);
    if (baseline && baseline.date <= current.date) {
      const inList = volumes.map((rec) => rec.id).indexOf(baseline_study_id);
      if (inList !== -1) {
        volumes[inList] = {
          type: `${volumes[inList].type} (Baseline)`,
          ...baseline
        };
      } else {
        volumes.push({ type: 'Baseline', ...baseline });
      }
    }
  }

  if (hasNadir && !baselineAndNadirAreTheSame) {
    // Find nadir in available studies, may not be in the list due to status of 10
    const nadir = data.find((rec) => rec.id === nadir_study_id);

    if (nadir && nadir.date <= current.date) {
      const inList = volumes.map((rec) => rec.id).indexOf(nadir_study_id);
      if (inList !== -1) {
        volumes[inList] = {
          type: `${volumes[inList].type} (Nadir)`,
          ...nadir
        };
      } else {
        volumes.push({ type: 'Nadir', ...nadir });
      }
    }
  }

  if (baselineAndNadirAreTheSame) {
    // If baseline and nadir are the same, add baseline volumes only
    const baseline = data.find((rec) => rec.id === baseline_study_id);
    if (baseline) {
      const inList = volumes.map((rec) => rec.id).indexOf(baseline_study_id);
      if (inList !== -1) {
        volumes[inList] = {
          type: `${volumes[inList].type} (Baseline, Nadir)`,
          ...baseline
        };
      } else {
        volumes.push({ type: 'Baseline (Nadir)', ...baseline });
      }
    }
  }
  // Sort oldest to newest.
  volumes = volumes.sort((a, b) => {
    const dateA = a.date;
    const dateB = b.date;

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
  return volumes;
}

export function calculatePercentChanges(volumes) {
  if (!volumes || volumes.length === 0) {
    return [];
  }
  if (volumes.length === 1) {
    return [
      {
        ...volumes[0],
        enhancing: '',
        flair: '',
        necrosis: '',
        whole: ''
      }
    ];
  }

  const current = volumes.find(
    (rec) => rec.type.includes('Current') || rec.type.includes('Custom')
  );
  const compares = volumes.filter(
    (rec) => !(rec.type.includes('Current') || rec.type.includes('Custom'))
  );

  return compares
    .map((compare) => {
      const percentChanges = {};
      Object.keys(current).forEach((key) => {
        if (key === 'type' || key === 'date' || key === 'id') {
          percentChanges[key] = compare[key];
          return;
        }

        let changDirection = current[key] > compare[key] ? '' : '-';
        let calculation = Math.abs(
          getDelta(current[key], compare[key])
        ).toFixed(2);

        if (isNaN(calculation)) {
          changDirection = '';
          calculation = isNaN(calculation) ? '' : calculation;
        }
        calculation = calculation === '999.99' ? '> 999.99' : calculation;

        percentChanges[key] = `${changDirection}${calculation}${
          calculation === '' ? '' : '%'
        }`;
      });
      return percentChanges;
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
}
