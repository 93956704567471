/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { keyframes } from '@emotion/react';
import { cx, css } from '@emotion/css';
import styled from '@emotion/styled';
import { func } from 'prop-types';
import { fullSpinnerState } from '@Utils/atoms';
import { useRecoilValue } from 'recoil';
// eslint-disable-next-line import/no-unresolved
import screenLoaderImg from 'assets/Screen-loader.svg';

const fadeOutTime = '500';

const breatheAnimation = keyframes`
 0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% { opacity: 1;}
  100% {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1; 
  }
  100% {
    opacity: 0;
  }
`;

const styles = css`
  position: absolute;
  color: white;
  height: 100%;
  width: 100%;
  z-index: 100;

  &:before {
    display: block;
    width: 100%;
    height: 100%;
    content: 'Loading...';
    color: transparent;
    background-image: url(${screenLoaderImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26%;
    animation-name: ${breatheAnimation};
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
`;

const fadeOutStyle = css`
  opacity: 0;
  animation-iteration-count: 1;
  animation-name: ${fadeOutAnimation};
  animation-duration: ${fadeOutTime}ms;
`;

const Container = styled.div`
  background-color: ${({ theme: { palette } }) => palette.blackpearl};
`;

function FullSpinner({ overideFunc, ...props }) {
  const [fadeOut, setFadeOut] = useState(false);
  const [showLoader, setShowLoader] = useState(overideFunc(false));
  const isLoading = useRecoilValue(fullSpinnerState);

  useEffect(() => {
    if (isLoading) {
      setShowLoader(overideFunc(true));
    } else if (!isLoading && showLoader) {
      setFadeOut(true);
      setTimeout(() => {
        setShowLoader(false);
        setFadeOut(false);
      }, fadeOutTime);
    }
  }, [isLoading, overideFunc, showLoader]);

  return (
    showLoader && (
      <Container
        className={cx(styles, { [fadeOutStyle]: fadeOut })}
        {...props}
      />
    )
  );
}

FullSpinner.defaultProps = {
  overideFunc: (currentState) => currentState
};

FullSpinner.propTypes = {
  overideFunc: func
};

export default FullSpinner;
