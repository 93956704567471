/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';
import { bringSanityToMimeType } from '@Utils/generalConstants';

const { POST } = doFetch.methods;

const postAttachment = async (
  patientId,
  s3_file_name,
  document_name,
  document_type,
  mime_type,
  pinned = 0
) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(
    `${uri}/upload?client_id=${hospitalId}&patient_id=${patientId}&s3_file_name=${s3_file_name}`,
    {
      method: POST,
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        patient_id: patientId,
        s3_file_name,
        document_name,
        document_type,
        mime_type: bringSanityToMimeType(mime_type),
        pinned
      }),
      doNotRedirectOnError: true
    }
  );
};
export default postAttachment;
