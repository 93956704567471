import { PATIENT_LIST_TABS } from '@Utils/defaultConfig';
import { DEFAULT_ITEMS_PER_PAGE } from './Paginator';

export const DEFAULT_TAB = 0;
export const DEFAULT_PAGE = 0;
export const CLEAR_ALL_CACHE = '..!CLEAR_!FULL_!CACHE..';

export function getItemFromHistory(
  patientList,
  historyKey,
  defaultValueWhenMissing,
  nextSelectedTab
) {
  const defaultTab = PATIENT_LIST_TABS[DEFAULT_TAB].id;
  if (
    !patientList ||
    patientList.history === undefined ||
    Object.keys(patientList.history).length === 0
  ) {
    return defaultValueWhenMissing;
  }

  let selectedTab = nextSelectedTab;
  if (!(typeof selectedTab === 'string')) {
    selectedTab = patientList.selectedTab
      ? patientList.selectedTab
      : defaultTab;
  }

  return typeof (patientList.history[historyKey] || {})[selectedTab] ===
    'number'
    ? patientList.history[historyKey][selectedTab]
    : defaultValueWhenMissing;
}

export function getCurrentPage(patientList, selectedTab) {
  return getItemFromHistory(
    patientList,
    'currentPage',
    DEFAULT_PAGE,
    selectedTab
  );
}

export function getCurrentTab(patientList) {
  const defaultTab = PATIENT_LIST_TABS[DEFAULT_TAB].id;
  if (
    !patientList ||
    patientList.history === undefined ||
    Object.keys(patientList.history).length === 0
  ) {
    return PATIENT_LIST_TABS[DEFAULT_TAB].id;
  }
  return patientList.history.selectedTab
    ? patientList.history.selectedTab
    : defaultTab;
}

export function getCurrentRowsPerPage(patientList, selectedTab) {
  return getItemFromHistory(
    patientList,
    'rowsPerPage',
    DEFAULT_ITEMS_PER_PAGE,
    selectedTab
  );
}
