/* eslint-disable camelcase */
import doFetch from '@Utils/api/doFetch';
import { ENV } from '@Utils/envTools';
import { SORT_TYPE_DISPLAY_TEXT, SORT_TYPE } from '@Utils/generalConstants';
import { getApiToken, getOAuthToken } from '@Utils/auth/userInfoUtils';

const { GET } = doFetch.methods;
const {
  OVERDUE_SCHEDULED_SCAN_1,
  OVERDUE_IMAGE_OR_OVERDUE_SCAN_2,
  NEW_PATIENT_NO_SCANS_3,
  LAST_STUDY_WAS_REVIEWED_4,
  NEW_PATIENT_PENDING_SCANS_5
} = SORT_TYPE;

const TRUE = 1;

const getPatientList = async (skip = 0, limit = 20, filter = undefined) => {
  const uri = ENV.NEO_API;
  const hospitalId = ENV.CLIENT_ID;
  const oauth = await getOAuthToken();

  const encodeFiler = (obj) => encodeURI(JSON.stringify(obj));
  const transformData = (data) => {
    if (data && Array.isArray(data)) {
      return data.map(
        ({
          id,
          first_name,
          last_name,
          dob,
          sex,
          diagnosis,
          sort_date: date,
          sort_type,
          patient_iuid,
          ...rest
        }) => ({
          id,
          first_name,
          last_name,
          dob,
          sex,
          diagnosis,
          date,
          patient_iuid,
          event: SORT_TYPE_DISPLAY_TEXT[sort_type],
          sort_type,
          ...rest
        })
      );
    }
    return data;
  };

  // filterDefinitionList:
  // there is limited filtering right now these 3 columns
  // sort_type
  // pinned
  // next_scan_date

  const filterDefinitionList = {
    unreviewed: encodeFiler({
      sort_type: [
        OVERDUE_SCHEDULED_SCAN_1,
        OVERDUE_IMAGE_OR_OVERDUE_SCAN_2,
        NEW_PATIENT_NO_SCANS_3,
        NEW_PATIENT_PENDING_SCANS_5
      ]
    }),
    reviewed: encodeFiler({ sort_type: LAST_STUDY_WAS_REVIEWED_4 }),
    pinned: encodeFiler({ pinned: TRUE })
  };

  const filters = filterDefinitionList[filter];
  const authorization = oauth
    ? `Bearer ${oauth}`
    : `Basic ${await getApiToken()}`;

  return doFetch(
    `${uri}/patient?client_id=${hospitalId}&limit=${limit}&skip=${skip}${
      filters ? `&filter=${filters}` : ''
    }`,
    {
      method: GET,
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json'
      },
      returnHeaders: true
    }
  )
    .then(({ data, resHeaders }) => {
      if (
        Object.prototype.hasOwnProperty.call(data, 'status') &&
        Object.prototype.hasOwnProperty.call(data.status, 'type') &&
        data.status.type === 'error'
      ) {
        return undefined;
      }
      const total_count = parseInt(resHeaders['x-record-count'], 10);

      return {
        metadata: {
          total_count,
          skip,
          limit
        },
        records: transformData(data)
      };
    })
    .catch((error) => {
      // TODO: handle errors log on backend, handle in dofetch script?
    });
};
export default getPatientList;
