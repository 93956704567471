import { atom, selector } from 'recoil';

const defaultState = { loginMessage: '' };

const userInfoState = atom({
  key: 'userInfoState',
  default:
    localStorage.getItem('user') !== 'undefined' &&
    localStorage.getItem('user') !== null
      ? { ...JSON.parse(localStorage.getItem('user')), loginMessage: '' }
      : defaultState
});

const userInfo = selector({
  key: 'userInfo',
  get: ({ get }) => get(userInfoState),
  set: ({ set }, newValue) => {
    set(userInfoState, newValue);
    localStorage.setItem('user', JSON.stringify(newValue || {}));
  }
});

userInfoState.defaultState = defaultState;

export default userInfo;
